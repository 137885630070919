import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DataTitle } from 'src/common';
import { formatCurrency, formatNumber } from 'src/utils/misc';
import { useFormikContext } from 'formik';
import { ModifiersTreat, TreatReduceConsequenceType, TreatReduceFrequencyType } from 'src/api/types';
import { calcModifierReduction } from '../Modifiers/util';

const Div = styled.div`
  border-radius: 6px;
  min-width: 410px;
  position: relative;

  .box {
    background-color: ${({ theme }) => theme.colors.white};
    padding: 9px 14px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-height: calc(100% - 25px);
    transform: translateY(-2px);
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__row {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
`;

const Cell = styled.div<{ $isHeader?: boolean; $bBottom?: boolean; $bLeft?: boolean }>`
  font-size: 14px;
  font-weight: ${({ $isHeader }) => ($isHeader ? 400 : 500)};
  color: ${({ $isHeader, theme }) => ($isHeader ? theme.colors.cflowerBlue : theme.colors.prussianBlue)};
  border-bottom: ${({ $bBottom, theme }) => ($bBottom ? `1px solid ${theme.colors.stroke}` : 'none')};
  border-left: ${({ $bLeft, theme }) => ($bLeft ? `1px solid ${theme.colors.stroke}` : 'none')};
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  align-self: center;
  height: 100%;
  display: flex;
  align-items: center;

  * {
    padding: 1.5px 6px;
  }
`;

interface ImpactProps {
  isHidden?: boolean;
  startingValues: {
    frequency_times: number;
    lower: number;
    upper: number;
  };
  baseValues: {
    frequency_times: number;
    lower: number;
    upper: number;
  };
}

const impactResultPlaceholder = {
  frequency: 0,
  consequence: {
    lower: 0,
    upper: 0,
  },
};

export const Impact: React.FC<ImpactProps> = ({ startingValues, baseValues, isHidden }) => {
  const { values } = useFormikContext<ModifiersTreat<TreatReduceFrequencyType | TreatReduceConsequenceType>>();
  const [modifierImpact, setModifierImpact] = useState(impactResultPlaceholder);
  const [cumulativeImpact, setCumulativeImpact] = useState(impactResultPlaceholder);

  const tratFrequencyModifier = values.treat.find((el) => el.type === 'TREAT_REDUCE_FREQUENCY');
  const treatConsequenceModifier = values.treat.find((el) => el.type === 'TREAT_REDUCE_CONSEQUENCE');

  useEffect(() => {
    if (tratFrequencyModifier) {
      const isPercentage = tratFrequencyModifier.data.isPercentage;

      // MODIFIER IMPACT
      setModifierImpact((s) => ({
        ...s,
        frequency: calcModifierReduction(
          baseValues?.frequency_times,
          tratFrequencyModifier.data.value || 0,
          isPercentage,
        ),
      }));

      // CUMULATIVE IMPACT
      setCumulativeImpact((s) => ({
        ...s,
        frequency: calcModifierReduction(
          startingValues?.frequency_times,
          tratFrequencyModifier.data.value || 0,
          isPercentage,
        ),
      }));
    }

    if (treatConsequenceModifier) {
      const isPercentage = treatConsequenceModifier.data.isPercentage;

      // MODIFIER IMPACT
      setModifierImpact((s) => ({
        ...s,
        consequence: {
          lower: calcModifierReduction(baseValues.lower, treatConsequenceModifier.data.lower || 0, isPercentage),
          upper: calcModifierReduction(baseValues.upper, treatConsequenceModifier.data.upper || 0, isPercentage),
        },
      }));

      // CUMULATIVE IMPACT
      setCumulativeImpact((s) => ({
        ...s,
        consequence: {
          lower: calcModifierReduction(startingValues.lower, treatConsequenceModifier.data.lower || 0, isPercentage),
          upper: calcModifierReduction(startingValues.upper, treatConsequenceModifier.data.upper || 0, isPercentage),
        },
      }));
    }
  }, [tratFrequencyModifier, treatConsequenceModifier, startingValues, baseValues]);

  return (
    <Div>
      {!isHidden && (
        <>
          {(treatConsequenceModifier || tratFrequencyModifier) && (
            <>
              <DataTitle>IMPACT</DataTitle>
              <div className="box">
                <div>
                  {/* Consequence */}
                  {treatConsequenceModifier && (
                    <div>
                      <div className="box__row">
                        <div />
                        <Cell className="box__row">
                          <Cell $isHeader $bBottom $bLeft>
                            <span>Lower</span>
                          </Cell>
                          <Cell $isHeader $bBottom $bLeft>
                            <span>Upper</span>
                          </Cell>
                        </Cell>
                      </div>
                      <div className="box__row">
                        <Cell $isHeader>
                          <span>Base Consequence</span>
                        </Cell>
                        <div className="box__row">
                          <Cell $bLeft>
                            <span>{formatCurrency(baseValues.lower)}</span>
                          </Cell>
                          <Cell $bLeft>
                            <span>{formatCurrency(baseValues.upper)}</span>
                          </Cell>
                        </div>
                      </div>
                      <div className="box__row">
                        <Cell $isHeader>
                          <span>Modifier Impact</span>
                        </Cell>
                        <div className="box__row">
                          <Cell $bLeft>
                            <span>{formatCurrency(modifierImpact.consequence.lower || 0)}</span>
                          </Cell>
                          <Cell $bLeft>
                            <span>{formatCurrency(modifierImpact.consequence.upper || 0)}</span>
                          </Cell>
                        </div>
                      </div>
                      <div className="box__row">
                        <Cell $isHeader>
                          <span>Cumulative Impact</span>
                        </Cell>
                        <div className="box__row">
                          <Cell $bLeft>
                            <span>{formatCurrency(cumulativeImpact.consequence.lower || 0)}</span>
                          </Cell>
                          <Cell $bLeft>
                            <span>{formatCurrency(cumulativeImpact.consequence.upper || 0)}</span>
                          </Cell>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Frequency */}
                  {tratFrequencyModifier && (
                    <div>
                      <div className="box__row">
                        <div />
                        <Cell>
                          <Cell $isHeader $bBottom $bLeft>
                            <span>Expected Chance</span>
                          </Cell>
                        </Cell>
                      </div>
                      <div className="box__row">
                        <Cell $isHeader>
                          <span>Base Frequency</span>
                        </Cell>
                        <Cell $bLeft>
                          <span>{baseValues.frequency_times}%</span>
                        </Cell>
                      </div>
                      <div className="box__row">
                        <Cell $isHeader>
                          <span>Modifier Impact</span>
                        </Cell>
                        <Cell $bLeft>
                          <span>{formatNumber(modifierImpact.frequency)}%</span>
                        </Cell>
                      </div>
                      <div className="box__row">
                        <Cell $isHeader>
                          <span>Cumulative Impact</span>
                        </Cell>
                        <Cell $bLeft>
                          <span>{formatNumber(cumulativeImpact.frequency)}%</span>
                        </Cell>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Div>
  );
};
