import React, { useState } from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import styled from 'styled-components';
import { ButtonSkeleton, PageCard, PageContainer, Poppins, Settings, Spacer, TableSkeleton } from 'src/common';
import Button from 'src/components/form/Button';
import { Incident, QKeys } from 'src/api/types';
import { ScenarioSelect } from 'src/components/select';
import { queryCache, useMutation, useQuery } from 'react-query';
import { deleteIncident, getIncidents, linkIncident } from 'src/api/incidents';
import { getAssessments } from 'src/api/assessment';
import { ConfirmDeleteModal, IncidentModal } from 'src/components/modals';
import GenericTable, { genEmtyRowTextComponent, GTRowEmpty } from 'src/components/GenericTable';
import { TableExpandContent } from './comps';
import { useIncidentsColumns } from './useIncidentsColumns';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { TableIds } from 'src/api/types/misc';
import CommonMenu from 'src/components/CommonMenu';
import { useAuth } from 'src/state/auth';
import { downloadCsv } from './util';
import { AddIncidentModal } from 'src/components/modals/AddIncidentModal/AddIncidentModal';
import Audit from 'src/components/Audit';
import { auditEvent } from 'src/api/other';
import { AuditSection } from './sections';

const Div = styled(PageContainer)`
  .panel {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 10px;

    &__btns {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      grid-gap: 10px;
      flex-wrap: wrap;
      align-self: flex-end;
      justify-self: flex-end;
      margin-left: auto;
    }
  }

  .error {
    color: ${({ theme }) => theme.colors.error};
    font-size: 14px;
  }
  .success {
    color: ${({ theme }) => theme.colors.success};
    font-size: 14px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 180px;
    .panel {
      &__btns {
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }
`;

const IncidentsPage = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<Incident | boolean>(false);
  // const [isModalOpen, setIsModalOpen] = useState<Incident | boolean>(false);
  const [addMode, setAddMode] = useState(false);
  const [scenarioId, setScenarioId] = useState('');
  const [status, setStatus] = useState<{ isErr: boolean; text: string | null }>({ isErr: false, text: null });
  const [isLinking, setIsLinking] = useState(false);
  const [anchorEl, setAnchorEl] = useState<React.MouseEvent['currentTarget'] | null>(null);
  const { userPermissions, userFeatures } = useAuth();
  useGaEventPageView('Cydea | Security Incidents');

  useQuery(QKeys.Assessments, getAssessments);
  const { data = [], isLoading } = useQuery(QKeys.Incidents, getIncidents);
  const [remove] = useMutation(deleteIncident, {
    onMutate: () => {
      setStatus({ isErr: false, text: '' });
    },
    onSuccess: () => {
      queryCache.invalidateQueries();
      setStatus({ isErr: false, text: '' });
    },
    onError: () => {
      setStatus({ isErr: true, text: 'Error deleting incident' });
    },
  });

  const {
    columns,
    GTColumnSelectAnchorExported,
    setDeleteModalId,
    deleteModalId,
    editData,
    setEditData,
    checked,
    resetChecked,
  } = useIncidentsColumns({
    data,
    isCheckerEnabled: addMode,
    tdm: { onEditClick: true, onDeleteClick: true },
  });

  const handleLink = async () => {
    setIsLinking(true);
    setStatus({ isErr: false, text: '' });
    const requests = checked.map((id) => linkIncident({ incidentId: id, scenarios: [scenarioId] }));

    await Promise.all(requests)
      .then(() => {
        setStatus({ isErr: false, text: 'Incidents linked successfully' });
        resetChecked();
        setScenarioId('');
        setAddMode(false);
        queryCache.invalidateQueries();
      })
      .catch(() => {
        setStatus({ isErr: true, text: 'Error linking incidents' });
      })
      .finally(() => setIsLinking(false));
  };

  const onMenuClick = (e: React.MouseEvent<HTMLLIElement>, cb: () => void) => {
    e.stopPropagation();
    handleClose();
    cb();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Meta title={`Cydea | Security Incidents`} feedbackScript />
      {/* {(isModalOpen || editData) && (
        <IncidentModal
          initialData={editData}
          onClose={() => {
            setIsModalOpen(false);
            setEditData(null);
          }}
        />
      )} */}
      {editData && <IncidentModal initialData={editData} onClose={() => setEditData(null)} />}
      {isCreateModalOpen && <AddIncidentModal onClose={() => setIsCreateModalOpen(false)} />}
      {deleteModalId && (
        <ConfirmDeleteModal
          onClose={() => setDeleteModalId(null)}
          onConfirm={async () => {
            await remove(deleteModalId);
            setDeleteModalId(null);
          }}
        />
      )}
      <Div>
        <div className="panel">
          {addMode ? (
            <>
              <Poppins px={22} color="cflowerBlue" css="justify-self: flex-start;margin-right: auto;">
                Select scenario and incidents
              </Poppins>
              <div className="panel__btns">
                <ScenarioSelect value={scenarioId} onChange={(val) => setScenarioId(val.value)} />
                <Button
                  onClick={() => {
                    setAddMode(false);
                    resetChecked();
                    setScenarioId('');
                    mpEvent(MPEvents.ButtonClick, {
                      button: 'Cancel',
                      tags: ['INCIDENT'],
                    });
                  }}
                  secondary
                  disabled={isLinking}
                  $constWidth={150}
                >
                  CANCEL
                </Button>
                <Button
                  primary
                  onClick={() => {
                    handleLink();
                    mpEvent(MPEvents.ButtonClick, {
                      button: 'Link',
                      tags: ['INCIDENT'],
                    });
                  }}
                  disabled={!checked.length || !scenarioId || isLinking}
                  data-cy="link-btn"
                  $constWidth={150}
                >
                  LINK
                </Button>
              </div>
            </>
          ) : (
            <>
              <Poppins px={32} weight={600}>
                Security Incidents
              </Poppins>
              <div className="panel">
                {!isLoading ? (
                  <>
                    <Button
                      onClick={() => {
                        setAddMode(true);
                        mpEvent(MPEvents.ButtonClick, {
                          button: 'Link to scenario',
                          tags: ['INCIDENT', 'SCENARIO'],
                        });
                      }}
                      primary
                      data-cy="link-scenario-btn"
                    >
                      LINK TO SCENARIO
                    </Button>
                    <Button
                      onClick={() => {
                        setIsCreateModalOpen(true);
                        // setIsModalOpen(true);
                        mpEvent(MPEvents.ButtonClick, {
                          button: 'Add incident',
                          tags: ['INCIDENT', 'SCENARIO'],
                        });
                      }}
                      primary
                      data-cy="add-incident-btn"
                    >
                      + ADD INCIDENT
                    </Button>

                    <>
                      <Settings
                        active={!!anchorEl}
                        onClick={(e) => {
                          e.stopPropagation();
                          setAnchorEl(e.currentTarget);
                        }}
                        data-cy="common-menu"
                        variant="button"
                      />
                      <CommonMenu
                        onCsvClick={(e) =>
                          onMenuClick(e, () => {
                            downloadCsv(data);
                            auditEvent({
                              action: 'ExportCsv',
                              resource: 'incident',
                              incident_id: data.map((el) => el.id),
                            }).then(() => queryCache.invalidateQueries());
                          })
                        }
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      />
                    </>
                  </>
                ) : (
                  <>
                    <ButtonSkeleton />
                    <ButtonSkeleton />
                  </>
                )}
              </div>
            </>
          )}
        </div>
        {status.text && (
          <>
            <Spacer $px={15} />
            <div className={status.isErr ? 'error' : 'success'}>{status.text}</div>
          </>
        )}
        <Spacer $px={30} />
        {!isLoading ? (
          <>
            <GenericTable
              tableId={TableIds.incidents}
              data={data}
              columns={columns}
              GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
              expandContent={(inc) => (inc.scenarios.length ? <TableExpandContent data={inc.scenarios} /> : null)}
              searchable={['refId', 'name', 'type', 'date']}
              emptyRow={
                <GTRowEmpty
                  text={genEmtyRowTextComponent({
                    onClick: () => setIsCreateModalOpen(true),
                    endText: 'to add incidents.',
                  })}
                />
              }
            />
            {userFeatures.app.audit.report && (
              <>
                <Spacer $px={30} />
                <PageCard
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                >
                  <AuditSection />
                </PageCard>
              </>
            )}
          </>
        ) : (
          <TableSkeleton />
        )}
      </Div>
    </>
  );
};

export default IncidentsPage;
