import React, { useState } from 'react';
import { Spacer } from 'src/common';
import { Guard } from '.';
import WorkspaceVariablesForm from 'src/components/formComposed/WorkspaceVariablesForm';
import { Scope } from 'src/components/formComposed/WorkspaceVariablesForm/types';
import styled from 'styled-components';
import { Modal } from './comps';

export const StyledModal = styled(Modal)`
  width: 1100px;
  min-height: 200px;
  padding: 0;
  overflow-y: auto;
  max-height: 100%;
  overflow-x: auto;
`;

interface IVariablesWorkspaceModalProps {
  onClose: () => void;
  scope: Scope;
  readOnly?: boolean;
}

export const VariablesWorkspaceModal: React.FC<IVariablesWorkspaceModalProps> = ({ onClose, scope, readOnly }) => {
  const [isCloseRequested, setIsCloseRequested] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={onClose}
      isOpen={isCloseRequested}
      isDirty={isDirty}
      modalComponent={
        <StyledModal onRequestClose={() => setIsCloseRequested(true)} isOpen center={false}>
          <Spacer $px={40} />
          <WorkspaceVariablesForm
            onClose={onClose}
            scope={scope}
            onDirtyChange={setIsDirty}
            readOnly={readOnly}
            isModal
          />
          <Spacer $px={54} />
        </StyledModal>
      }
    />
  );
};
