import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Control, PlanScenarios } from 'src/api/types';
import { TableIds } from 'src/api/types/misc';
import { LinkSvg } from 'src/assets/dynamicSvg';
import { NoData, Poppins } from 'src/common';
import ExpandSection from 'src/components/ExpandSection';
import Button from 'src/components/form/Button';
import GenericTable from 'src/components/GenericTable';
import { LinkScenariosToControlModal } from 'src/components/modals';
import { Modifier } from 'src/components/Modifier';
import { useStateSelector } from 'src/redux';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import styled from 'styled-components';
import { useColumns } from './useColumns';
import { getPercentage } from 'src/utils/misc';
import { checkIsModifierStrategy } from 'src/components/Modifier/util';
import { convertToModifiers } from 'src/components/ProjectedRisk/util';

const EndDiv = styled.div`
  .link-icon {
    margin-right: 10px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .link-icon {
      margin-right: 6px;
    }
  }
`;

interface LinkedScenariosSectionProps {
  control: Control;
  controlScenarios: PlanScenarios[];
  isLoading: boolean;
  error: unknown;
  version?: string;
}

export const LinkedScenariosSection: React.FC<LinkedScenariosSectionProps> = ({
  control,
  controlScenarios,
  isLoading,
  error,
  version,
}) => {
  const { controlId } = useParams<{ controlId: string }>();
  const [isOpen, setIsOpen] = useState(false);
  const [isLinkScenarioModalOpen, setIsLinkScenarioModalOpen] = useState(false);
  const colors = useStateSelector(({ theme }) => theme.colors);

  const { columns, GTColumnSelectAnchorExported } = useColumns({
    data: controlScenarios,
    controlId: controlId as string,
  });

  return (
    <>
      {isLinkScenarioModalOpen && (
        <LinkScenariosToControlModal
          controlId={controlId as string}
          isModifierStrategyType={checkIsModifierStrategy(control.strategy)}
          onClose={() => setIsLinkScenarioModalOpen(false)}
          subTitle={{
            title: 'CONTROL',
            value: control?.name || '',
          }}
        />
      )}
      <ExpandSection
        title="LINKED SCENARIOS"
        sectionId="control_linked-scenarios"
        onChange={setIsOpen}
        endComponent={
          isOpen && !version ? (
            <EndDiv>
              <Button
                primary
                onClick={() => {
                  setIsLinkScenarioModalOpen(true);
                  mpEvent(MPEvents.ButtonClick, {
                    button: 'Link scenarios',
                    tags: ['RMP', 'SCENARIO'],
                  });
                }}
                data-cy="link-scenarion-btn"
              >
                <LinkSvg className="link-icon" color={colors.btnWhite} />
                LINK SCENARIOS
              </Button>
            </EndDiv>
          ) : undefined
        }
      >
        {!isLoading && (
          <>
            {controlScenarios.length ? (
              <GenericTable
                tableId={TableIds.controlScenarios}
                data={controlScenarios}
                columns={columns}
                GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
                searchable={['name', 'source', 'event', 'consequence', 'expected_loss']}
                expandContent={(el) => {
                  // console.log({
                  //   starting: {
                  //     starting_frequency: el.starting_frequency,
                  //     starting_lower: el.starting_lower,
                  //     starting_upper: el.starting_upper,
                  //   },
                  //   scenario: {
                  //     frequency_times: el.scenario_data.frequency_times,
                  //     frequency_years: el.scenario_data.frequency_years,
                  //   },
                  // });
                  return (
                    <Modifier
                      startingValues={{
                        frequency_times: el.starting_frequency * 100,
                        lower: el.starting_lower,
                        upper: el.starting_upper,
                      }}
                      control={el.control_data}
                      recommendedModifiers={convertToModifiers(el.control_data.recommendedModifiers)}
                      projectedScenario={{
                        ...el.scenario_data,
                        frequency_times: getPercentage(
                          el.scenario_data.frequency_times,
                          el.scenario_data.frequency_years,
                        ),
                      }}
                      disabled={!!version}
                      withCopy
                    />
                  );
                }}
              />
            ) : (
              <>
                {error ? (
                  <Poppins color="error" px={14}>
                    {/* @ts-ignore */}
                    {error.message || 'Something went wrong'}
                  </Poppins>
                ) : (
                  <NoData title="No scenarios linked" />
                )}
              </>
            )}
          </>
        )}
      </ExpandSection>
    </>
  );
};
