import { ColumnDef, Table, sortingFns } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { ControlLibraryShort, QKeys } from 'src/api/types';
import { GTCheckBox, GTColumnSelectAnchor, GTExpander, GTTdm } from 'src/components/GenericTable';
import { filter } from 'lodash';
import { GTInternalIds } from '../types';
import { queryCache, useMutation, useQuery } from 'react-query';
import { addControllsFromLibrary, getControls } from 'src/api/controls';
import { Link } from 'react-router-dom';
import { gTCheckHandler } from '../util';
import { MPEvents } from 'src/utils/mixpanel/types';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { TableIds } from 'src/api/types/misc';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TextOverflowTooltip } from 'src/common';

type UseColumns = (a: {
  data?: ControlLibraryShort[];
  frameworklibraryId?: string;
  isCheckerEnabled?: boolean;
  opensSameTab?: boolean;
  tdm?: {
    onAddToRMPClick?: boolean;
  };
  tableId: TableIds;
}) => {
  columns: ColumnDef<ControlLibraryShort>[];
  checked: string[];
  setChecked: (a: string[]) => void;
  GTColumnSelectAnchorExported: JSX.Element;
};

const defaultColumns = ['frameworkShortname', 'controlLibraryId', 'name', 'FrameworkAuthor'];

export const useControlLibraryColumns: UseColumns = ({
  data = [],
  frameworklibraryId,
  isCheckerEnabled,
  tdm,
  opensSameTab,
  tableId,
}) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(`${tableId}-columns`, defaultColumns);
  const [checked, setChecked] = useState<string[]>([]);

  const { data: rmpControls = [] } = useQuery(QKeys.Controls, getControls);

  const [addToRMP] = useMutation(addControllsFromLibrary, {
    onSuccess: () => {
      queryCache.invalidateQueries(QKeys.Controls);
      queryCache.invalidateQueries([QKeys.ControlLibraries, frameworklibraryId]);
    },
    onError: (err: any) => {
      console.log(err);
    },
  });

  const onAddToRMPClick = (id: string) => {
    addToRMP({
      controlLibraryIds: [{ id }],
    });
  };

  const columns = useMemo(() => {
    let internalIds = [GTInternalIds.expander, GTInternalIds.tdm];

    if (isCheckerEnabled) {
      internalIds.push(GTInternalIds.checker);
    } else {
      internalIds.filter((el) => el !== GTInternalIds.checker);
      if (checked.length > 0) setChecked([]);
    }

    const list: ColumnDef<ControlLibraryShort>[] = [
      {
        id: GTInternalIds.checker,
        header: ({ table }) => {
          const rows = table.getRowModel().rows;
          const headChecked =
            rows
              .map((row) => row.original.ux_id)
              .every((id) => rmpControls.some((item) => item.controlLibrary_Id === id)) && !!rows.length;

          const { isAllChecked, checkAllHandler } = gTCheckHandler(
            {
              checked,
              selector: 'ux_id',
              table,
            },
            setChecked,
          );

          return (
            <GTCheckBox isChecked={isAllChecked || headChecked} disabled={headChecked} onClick={checkAllHandler} />
          );
        },
        cell: ({ row }) => {
          const id = row.original.ux_id;
          const isInRmp = rmpControls.some((item) => item.controlLibrary_Id === row.original.ux_id);
          return (
            <GTCheckBox
              isChecked={checked.includes(id) || isInRmp}
              disabled={isInRmp}
              onClick={() => {
                if (checked.includes(id)) {
                  setChecked(checked.filter((el) => el !== id));
                } else {
                  setChecked([...checked, id]);
                }
              }}
            />
          );
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: GTInternalIds.expander,
        header: () => null,
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <GTExpander onClick={row.getToggleExpandedHandler()} isExpanded={row.getIsExpanded()} />
          ) : null;
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: 'frameworkShortname',
        header: 'Framework',
        accessorFn: (row) => row.frameworkShortname || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },
      {
        id: 'controlLibraryId',
        header: 'Framework ID',
        accessorFn: (row) => row.controlLibraryId || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => row.name || null,
        cell: (info) => (
          <TextOverflowTooltip>
            <Link
              className="table-link"
              to={`/control-frameworks/all-controls/${info.row.original.ux_id}`}
              target={opensSameTab ? '' : '_blank'}
              onClick={(e) => {
                e.stopPropagation();
                mpEvent(MPEvents.Link, {
                  source: {
                    value: ['Framework'],
                  },
                  destination: {
                    value: ['Framework Controls all'],
                    params: {
                      id: info.row.original.ux_id,
                    },
                  },
                  tags: ['FRAMEWORK'],
                });
              }}
            >
              {info.getValue() as string}
            </Link>
          </TextOverflowTooltip>
        ),
        maxSize: 300,
        // size: 300,
        sortingFn: sortingFns.text,
      },
      {
        id: 'FrameworkAuthor',
        header: 'Author',
        accessorFn: (row) => row.FrameworkAuthor || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },

      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: ({ row }) => {
          const isInRmp = rmpControls.some((item) => item.controlLibrary_Id === row.original.ux_id);
          return (
            <div css="display: flex;grid-gap: 8px;">
              <GTTdm
                onAddToRMPClick={
                  !isInRmp && tdm?.onAddToRMPClick ? () => onAddToRMPClick(row.original.ux_id) : undefined
                }
              />
            </div>
          );
        },
        size: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns, rmpControls, isCheckerEnabled, checked, tdm]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'frameworkShortname', title: 'Framework' },
        { key: 'controlLibraryId', title: 'Framework ID' },
        { key: 'name', title: 'Name' },
        { key: 'FrameworkAuthor', title: 'Author' },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return { columns, checked, setChecked, GTColumnSelectAnchorExported };
};
