import React, { useState } from 'react';
import { TableIds } from 'src/api/types/misc';
import Audit from 'src/components/Audit';
import ExpandSection from 'src/components/ExpandSection';
import { UserCtxOption } from 'src/components/select';

interface AuditSectionProps {
  userId: number;
  userCtx?: UserCtxOption;
}

export const AuditSection: React.FC<AuditSectionProps> = ({ userId, userCtx }) => {
  const [isOpen, setIsOpen] = useState(false);

  const title = userCtx ? `Audit log for ${userCtx.label}` : 'AUDIT';

  return (
    <ExpandSection title={title} defaultOpen={false} onChange={setIsOpen} sectionId="user_audit">
      {isOpen && (
        <div>
          <Audit
            noHide
            topSpacer={0}
            maxHeight={800}
            tableId={TableIds.auditUser}
            rootPath={'users'}
            initQuery={{
              user_id: userId,
              workspace_id: userCtx?.type === 'W' ? userCtx?.value : undefined,
              organisation_id: userCtx?.type === 'O' ? userCtx?.value : undefined,
            }}
            customLabel={
              userCtx
                ? {
                    open: `Hide audit log for ${userCtx.label}${
                      userCtx.type === 'O' ? ' [Organisation]' : '[Workspace]'
                    }`,
                    close: `Show audit log for ${userCtx.label}${
                      userCtx.type === 'O' ? ' [Organisation]' : '[Workspace]'
                    }`,
                  }
                : undefined
            }
          />
        </div>
      )}
    </ExpandSection>
  );
};
