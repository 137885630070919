import React, { useState } from 'react';
import { Incident } from 'src/api/types';
import { TableIds } from 'src/api/types/misc';
import { LinkSvg } from 'src/assets/dynamicSvg';
import { NoData } from 'src/common';
import ExpandSection from 'src/components/ExpandSection';
import Button from 'src/components/form/Button';
import GenericTable from 'src/components/GenericTable';
import { useStateSelector } from 'src/redux';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import styled from 'styled-components';
import { useScenarioColumns } from './useScenarioColumns';
import { useParams } from 'react-router-dom';
import { queryCache } from 'react-query';
import { LinkScenariosToIncidentModal } from 'src/components/modals';

const EndDiv = styled.div`
  .link-icon {
    margin-right: 10px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .link-icon {
      margin-right: 6px;
    }
  }
`;

interface LinkedScenariosSectionProps {
  incident: Incident;
  version?: string;
}

export const LinkedScenariosSection: React.FC<LinkedScenariosSectionProps> = ({ incident, version }) => {
  const { incidentId } = useParams<{ incidentId: string }>();
  const [isOpen, setIsOpen] = useState(false);
  const [isLinkScenarioModalOpen, setIsLinkScenarioModalOpen] = useState(false);
  const colors = useStateSelector(({ theme }) => theme.colors);

  const { columns, GTColumnSelectAnchorExported } = useScenarioColumns({
    data: incident?.scenarios || [],
    incidentId: incidentId as string,
  });

  return (
    <>
      {isLinkScenarioModalOpen && (
        <LinkScenariosToIncidentModal
          incidentId={incidentId as string}
          onClose={() => setIsLinkScenarioModalOpen(false)}
          onRefetch={() => queryCache.invalidateQueries()}
          subTitle={{
            title: 'INCIDENT',
            value: incident?.name || '',
          }}
        />
      )}
      <ExpandSection
        title="LINKED SCENARIOS"
        sectionId="incident_linked-scenarios"
        onChange={setIsOpen}
        endComponent={
          isOpen &&
          !version && (
            <EndDiv>
              <Button
                primary
                onClick={() => {
                  setIsLinkScenarioModalOpen(true);
                  mpEvent(MPEvents.ButtonClick, {
                    button: 'Link scenarios',
                    tags: ['INCIDENT', 'SCENARIO'],
                  });
                }}
                data-cy="link-scenarion-btn"
              >
                <LinkSvg className="link-icon" color={colors.btnWhite} />
                LINK SCENARIOS
              </Button>
            </EndDiv>
          )
        }
      >
        {incident.scenarios.length ? (
          <>
            <GenericTable
              tableId={TableIds.incidentSecnarios}
              data={incident.scenarios}
              columns={columns}
              GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
              searchable
            />
          </>
        ) : (
          <NoData title="No scenarios linked" />
        )}
      </ExpandSection>
    </>
  );
};
