import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { getAssessment } from 'src/api/assessment';
import { ProjectionVariant, QKeys } from 'src/api/types';
import Meta from 'src/components/Meta';
import { PageContainer, PageChartSkeleton, Spacer, PageCard, VariantValues, Divider } from 'src/common';
import { useGaEventPageView } from '../../utils/gaEvent';
import { Head } from './comps';
import useVersion from 'src/hooks/useVersion';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import { useAuth } from 'src/state/auth';
import _ from 'lodash';
import { AuditSection, ExceedanceSection, RiskRegisterSection } from './sections';

const Card = styled(PageCard)`
  padding-bottom: 0;
`;

const AssessmentPage = () => {
  const { assessmentId } = useParams<{ assessmentId: string }>();
  const [isLogarithmic, setIsLogarithmic] = useState(true);
  const { version } = useVersion();
  const { userFeatures } = useAuth();
  const { setLocationPaths } = useActionsApp();

  const { data, isLoading: isLoadingLive } = useQuery(
    [QKeys.Assessment, { assessmentId, version, variant: ProjectionVariant.live }],
    getAssessment,
  );
  const { data: dataBase, isLoading: isLoadingBase } = useQuery(
    [QKeys.Assessment, { assessmentId, version, variant: ProjectionVariant.base }],
    getAssessment,
  );
  const { data: dataComplete, isLoading: isLoadingComplete } = useQuery(
    [QKeys.Assessment, { assessmentId, version, variant: ProjectionVariant.complete }],
    getAssessment,
  );

  const isLoading = isLoadingLive || isLoadingBase || isLoadingComplete;

  const showAllProjected = !!data?.scenarios?.flatMap((scenario) =>
    scenario.implementationPlans
      ?.filter((plan) => plan.control && plan.control.status !== 'Abandoned')
      ?.flatMap((plan) => plan.modifiers)
      ?.filter((modifier) => modifier.type !== 'NOTE_TYPE'),
  )?.length;

  useEffect(() => {
    if (data) {
      setLocationPaths({ assessment: { id: data.id, name: data.name } });
    }
    return () => {
      setLocationPaths({ assessment: undefined });
    };
  }, [data]);

  useGaEventPageView('Cydea | Assessment');

  if (isLoading)
    return (
      <PageContainer>
        <PageChartSkeleton />
      </PageContainer>
    );

  return (
    <>
      {data && (
        <>
          <Meta title={`Cydea | ${data?.name}`} feedbackScript />
          <PageContainer>
            <Card>
              <Head
                data={data}
                dataBase={dataBase}
                dataProjected={dataComplete}
                isLogarithmic={isLogarithmic}
                expectedLossComponent={
                  <VariantValues
                    flat
                    value={
                      showAllProjected
                        ? {
                            base: dataBase?.expected_loss,
                            live: data.expected_loss,
                            cpmplete: dataComplete?.expected_loss,
                          }
                        : data.expected_loss
                    }
                    tooltipText={{
                      base: 'Expected Loss without control modifiers applied',
                      live: 'Expected Loss with control modifiers from live controls applied',
                      cpmplete: 'Expected Loss with control modifiers from all controls applied',
                    }}
                  />
                }
              />
              <Spacer $px={20} />
              <Divider />
              <ExceedanceSection
                data={data}
                dataBase={dataBase}
                dataComplete={dataComplete}
                version={version}
                showAllProjected={showAllProjected}
                isLogarithmic={isLogarithmic}
                setIsLogarithmic={setIsLogarithmic}
              />
              <Divider />
              <RiskRegisterSection
                version={version}
                showAllProjected={showAllProjected}
                data={data}
                dataBase={dataBase}
                dataComplete={dataComplete}
              />
              {userFeatures.app.audit.report && (
                <>
                  <Divider />
                  <AuditSection version={version} />
                </>
              )}
            </Card>
          </PageContainer>
        </>
      )}
    </>
  );
};

export default AssessmentPage;
