import React, { Fragment } from 'react';
import styled from 'styled-components';
import { GradientTextAction, Poppins } from 'src/common';

const Tr = styled.tr<{ $itemHeight: number }>`
  font-size: 14px;
  font-weight: 500;
  height: ${({ $itemHeight }) => $itemHeight}px !important;
  border-top: 1px solid ${({ theme }) => theme.colors.stroke};
`;

interface GTRowEmptyProps {
  itemHeight?: number;
  text?: string | JSX.Element;
}

export const GTRowEmpty = ({ itemHeight = 77, text = 'No activity recorded yet' }: GTRowEmptyProps) => {
  const isJsx = typeof text === 'object';
  return (
    <Fragment>
      <Tr $itemHeight={itemHeight}>
        <td />
        <td colSpan={999}>
          {isJsx ? (
            text
          ) : (
            <Poppins px={14} weight={500} color="cflowerBlue">
              {text}
            </Poppins>
          )}
        </td>
      </Tr>
    </Fragment>
  );
};

type GenEmtyRowTextComponent = (params: { onClick?: () => void; startText?: string; endText?: string }) => JSX.Element;

export const genEmtyRowTextComponent: GenEmtyRowTextComponent = ({
  onClick,
  startText = 'No activity recorded yet. Click',
  endText = 'to add controls.',
}) => {
  return (
    <Poppins px={14} weight={500} color="cflowerBlue">
      {startText}{' '}
      {onClick && (
        <>
          <GradientTextAction $underline px={14} weight={500} onClick={onClick}>
            here
          </GradientTextAction>{' '}
        </>
      )}
      {endText}
    </Poppins>
  );
};
