import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { Control } from 'src/api/types';
import { GTColumnSelectAnchor, GTExpander } from 'src/components/GenericTable';
import { filter } from 'lodash';
import _ from 'lodash';
import { GTInternalIds } from 'src/components/GenericTable/types';
import { formatNumber } from 'src/utils/misc';
import { Link, useNavigate } from 'react-router-dom';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TableIds } from 'src/api/types/misc';
import { TextOverflowTooltip } from 'src/common';

type useColumns = (a: { data?: Control[] }) => {
  columns: ColumnDef<Control>[];
  GTColumnSelectAnchorExported: JSX.Element;
};

const defaultColumns = ['strategy', 'name', 'owner', 'frameworkLibrary_shortname', 'controlId', 'status'];

export const useColumns: useColumns = ({ data = [] }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(
    `${TableIds.rmpMultiSelectModal}-columns`,
    defaultColumns,
  );
  const navigate = useNavigate();

  const columns = useMemo(() => {
    let internalIds = [GTInternalIds.expander, GTInternalIds.tdm];

    const list: ColumnDef<Control>[] = [
      {
        id: GTInternalIds.expander,
        header: () => null,
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <GTExpander onClick={row.getToggleExpandedHandler()} isExpanded={row.getIsExpanded()} />
          ) : null;
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: 'strategy',
        header: 'Strategy',
        accessorFn: (row) => row.strategy || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => row.name || null,
        cell: (info) => (
          <TextOverflowTooltip>
            <Link
              className="table-link"
              to={`/risk-management/control/${info.row.original.id}`}
              target="_blank"
              onClick={(e) => {
                e.stopPropagation();
                mpEvent(MPEvents.Link, {
                  source: { value: ['Modal:UpdateControls'] },
                  destination: { value: ['Control'], params: { id: info.row.original.id } },
                  tags: ['RMP'],
                });
              }}
            >
              {info.getValue() as string}
            </Link>
          </TextOverflowTooltip>
        ),
        sortingFn: sortingFns.text,
        maxSize: 300,
      },
      {
        id: 'owner',
        header: 'Owner',
        accessorFn: (row) => row.owner?.text || null,
        cell: (info) => info.getValue(),

        maxSize: 500,
        sortingFn: sortingFns.text,
      },
      {
        id: 'frameworkLibrary_shortname',
        header: 'Framework',
        accessorFn: (row) => row.frameworkLibrary_shortname || null,
        cell: (info) => (
          <span
            className="table-link"
            onClick={() => navigate(`/control-frameworks/${info.row.original.frameworkLibrary_ux_id}`)}
          >
            {info.getValue() as string}
          </span>
        ),
        sortingFn: sortingFns.text,
      },
      {
        id: 'controlId',
        header: 'Framework ID',
        accessorFn: (row) => row.controlId || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },
      {
        id: 'annual_cost',
        header: 'Annual Cost',
        accessorFn: (row) => row.annual_cost || null,
        cell: ({ row }) => formatNumber(row.original.annual_cost, { symbol: true }),
        sortingFn: sortingFns.alphanumeric,
      },
      {
        id: 'status',
        header: 'Status',
        accessorFn: (row) => row.status || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },

      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: () => null,
        size: 1,
        maxSize: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'strategy', title: 'Strategy' },
        { key: 'name', title: 'Name' },
        { key: 'owner', title: 'Owner' },
        { key: 'frameworkLibrary_shortname', title: 'Framework' },
        { key: 'controlId', title: 'Framework ID' },
        { key: 'annual_cost', title: 'Annual Cost' },
        { key: 'status', title: 'Status' },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return { columns, GTColumnSelectAnchorExported };
};
