import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { AiSvg } from 'src/assets/dynamicSvg';
import styled, { css } from 'styled-components';
import { Tooltip } from 'src/common';
import { without } from 'lodash';
import { useSuggestions } from 'src/hooks/useSuggestions';
import { MPEvents } from 'src/utils/mixpanel/types';
import { mpEvent } from 'src/utils/mixpanel';

const suggestionHideString = ReactDOMServer.renderToStaticMarkup(
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <span style={{ textAlign: 'center' }}>Hide suggested next steps</span>
    <span style={{ textAlign: 'center' }}>for this scenario</span>
  </div>,
);
const suggestionShowString = ReactDOMServer.renderToStaticMarkup(
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <span style={{ textAlign: 'center' }}>Show suggested next steps</span>
    <span style={{ textAlign: 'center' }}>for this scenario</span>
  </div>,
);

const Div = styled.div`
  cursor: pointer;
`;

interface AISuggestionsHandleProps {
  suggesterKeys: string[];
}

export const AISuggestionsHandle: React.FC<AISuggestionsHandleProps> = ({ suggesterKeys }) => {
  const { isActive, saveKeys, hiddenSuggestionKeys } = useSuggestions({ keys: suggesterKeys });

  return (
    <Div
      onClick={() => {
        if (isActive) {
          saveKeys(without(hiddenSuggestionKeys, ...suggesterKeys));
        } else {
          saveKeys([...hiddenSuggestionKeys, ...suggesterKeys]);
        }
        mpEvent(MPEvents.ButtonClick, {
          button: 'Toggle suggestions',
          tags: ['SCENARIO', 'AI'],
        });
      }}
    >
      <Tooltip place="left" dataHtml={isActive ? suggestionShowString : suggestionHideString} dataId={`aish`}>
        <AiSvg isActive={!isActive} />
      </Tooltip>
    </Div>
  );
};
