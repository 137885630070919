import React from 'react';
import styled from 'styled-components';
import { StyledAvatar } from './StyledAvatar';
import { getProfileCredentials } from 'src/utils/auth';

const AvatarWrapper = styled.div<{ $cursorPointer?: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  grid-gap: 10px;
  max-width: 100%;
  &:hover {
    cursor: ${({ $cursorPointer }) => ($cursorPointer ? 'pointer' : 'default')};
    .table-link {
      color: ${({ theme }) => theme.colors.gp1};
    }
  }
`;

interface TableUserItemProps {
  onClick?: () => void;
  profilePhotoPath?: string | null;
  name: string;
}

export const TableUserItem: React.FC<TableUserItemProps> = ({ onClick, profilePhotoPath, name }) => {
  return (
    <AvatarWrapper onClick={onClick} $cursorPointer={!!onClick}>
      <StyledAvatar src={profilePhotoPath || ''}>{getProfileCredentials(name)}</StyledAvatar>
      <span className={`elipsis ${!!onClick ? 'table-link' : ''}`}>{name}</span>
    </AvatarWrapper>
  );
};
