import React from 'react';
import styled from 'styled-components';
import { Modifiers, ModifiersTreat, TreatReduceConsequenceType, TreatReduceFrequencyType } from 'src/api/types';
import Action from './Action';
import { GradientText } from 'src/common';
import { FieldArray, useFormikContext } from 'formik';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { AiSvg } from 'src/assets/dynamicSvg';
import { useAuth } from 'src/state/auth';

const ActionText = styled(GradientText)`
  text-transform: none !important;
  cursor: pointer;
  margin-bottom: 15px;
  display: inline-block;
  position: relative;

  .suggest-modifier-icon {
    width: 12px;
    position: absolute;
    left: -16px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Div = styled.div`
  display: grid;
  grid-gap: 15px;
  button[type='submit'] {
    width: 120px;
  }
`;

const options: { label: string; value: 'TREAT_REDUCE_CONSEQUENCE' | 'TREAT_REDUCE_FREQUENCY' }[] = [
  {
    label: 'Reduce Consequence',
    value: 'TREAT_REDUCE_CONSEQUENCE',
  },
  {
    label: 'Reduce Frequency',
    value: 'TREAT_REDUCE_FREQUENCY',
  },
];

const getInitialData = (option: string) => {
  switch (option) {
    case 'TREAT_REDUCE_CONSEQUENCE':
      return { lower: 0, upper: 0, isPercentage: false };
    case 'TREAT_REDUCE_FREQUENCY':
      return { value: 0, isPercentage: false };
    default:
      return {};
  }
};

interface TreatModifierProps {
  disabled?: boolean;
  noResult?: boolean;
  recommendedModifiers?: Modifiers | null;
}

const TreatModifier: React.FC<TreatModifierProps> = ({ disabled, noResult, recommendedModifiers }) => {
  const { values, setFieldValue } =
    useFormikContext<ModifiersTreat<TreatReduceFrequencyType | TreatReduceConsequenceType>>();
  const { userFeatures } = useAuth();

  const handleAddModifier = (type: 'TREAT_REDUCE_CONSEQUENCE' | 'TREAT_REDUCE_FREQUENCY') => {
    const newModifier = { type, data: getInitialData(type) };
    const updatedTreat = [...values.treat, newModifier];
    setFieldValue('treat', updatedTreat);
  };

  const existingTypes = values.treat.map((mod) => mod.type);
  const availableOptions = options.filter((opt) => !existingTypes.includes(opt.value));

  return (
    <Div>
      <FieldArray name="treat">
        {({ form, ...helpers }) => (
          <>
            {values.treat.map((el, idx) => (
              <Action
                key={el.type + idx}
                idx={idx}
                options={options}
                onRemove={() => helpers.remove(idx)}
                disabled={disabled}
                noResult={noResult}
              />
            ))}
            <div style={{ marginLeft: values.treat.length > 0 ? '30px' : '0' }}>
              {!disabled &&
                availableOptions.length > 0 &&
                (availableOptions.length === 1 ? (
                  <ActionText
                    weight={500}
                    px={14}
                    onClick={() => {
                      handleAddModifier(availableOptions[0].value);
                      mpEvent(MPEvents.ButtonClick, {
                        button: 'Modifier:Add',
                        tags: ['MODIFIERS'],
                      });
                    }}
                  >
                    + Add {availableOptions[0].label}
                  </ActionText>
                ) : (
                  values.treat.length === 0 && (
                    <>
                      <ActionText
                        weight={500}
                        px={14}
                        onClick={() => {
                          helpers.push({ type: '', data: {} });
                          mpEvent(MPEvents.ButtonClick, {
                            button: 'Modifier:Add',
                            tags: ['MODIFIERS'],
                          });
                        }}
                      >
                        + Add Modifier
                      </ActionText>
                      {userFeatures.app.ai.suggestModifiers && (
                        <ActionText
                          weight={500}
                          px={14}
                          style={{ marginLeft: '32px', display: 'inline-flex', alignItems: 'center' }}
                          onClick={() => {
                            setFieldValue('treat', recommendedModifiers?.treat);
                            mpEvent(MPEvents.ButtonClick, {
                              button: 'Modifier:Suggest',
                              tags: ['MODIFIERS', 'AI'],
                            });
                          }}
                        >
                          <AiSvg className="suggest-modifier-icon" />
                          Suggest Modifier
                        </ActionText>
                      )}
                    </>
                  )
                ))}
            </div>
          </>
        )}
      </FieldArray>
    </Div>
  );
};

export default TreatModifier;
