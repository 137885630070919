import { motion } from 'framer-motion';
import React from 'react';

interface ExpandedIndicatorProps {
  isOpen: boolean;
}

export const ExpandedIndicator: React.FC<ExpandedIndicatorProps> = ({ isOpen }) => {
  return (
    <motion.svg
      style={{ rotateZ: isOpen ? 0 : 180 }}
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="6"
      viewBox="0 0 11 6"
      fill="none"
    >
      <path
        d="M9.88629 5.93359H0.659141C0.480523 5.93359 0.326108 5.86838 0.195643 5.73806C0.0651783 5.60759 0 5.45318 0 5.27467C0 5.09616 0.0651783 4.94164 0.195643 4.81124L4.80923 0.197722C4.93984 0.0672574 5.09426 0.00193596 5.27273 0.00193596C5.45121 0.00193596 5.60577 0.0672574 5.73612 0.197722L10.3497 4.81128C10.48 4.94164 10.5455 5.09616 10.5455 5.2747C10.5455 5.45318 10.4801 5.60759 10.3497 5.73809C10.2194 5.86852 10.0648 5.93359 9.88629 5.93359Z"
        fill="#9EA5B7"
      />
    </motion.svg>
  );
};
