import { Image, Link, Text } from '@react-pdf/renderer';
import React from 'react';
import { getExternalPath } from '../util';
import { extLinkImg } from '../imgData';
import { ColorSet } from 'src/redux/reducers/theme';

interface TitleLinkProps {
  title: string;
  extLink: string;
  intLinkId?: string;
  colors: ColorSet;
}

export const TitleLink: React.FC<TitleLinkProps> = ({ title, intLinkId = '', extLink = '', colors }) => {
  return (
    <Link
      src={getExternalPath(extLink)}
      style={{ alignItems: 'flex-end', flexDirection: 'row', textDecoration: 'none' }}
    >
      <Text style={{ fontSize: 18, fontWeight: 600, color: colors.prussianBlue }} id={intLinkId.toLowerCase()}>
        {title}
      </Text>
      <Image src={extLinkImg} style={{ marginLeft: 4, marginBottom: 14, width: 8, height: 8 }} />
    </Link>
  );
};
