import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { ColorSet } from 'src/redux/reducers/theme';

interface Props {
  colors: ColorSet;
}

export const LegendExceedance: React.FC<Props> = ({ colors }) => {
  return (
    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginRight: 16,
        }}
      >
        <View
          style={{
            height: 2.5,
            borderRadius: 2,
            width: 27,
            backgroundColor: colors.prussianBlue,
            marginRight: 6,
          }}
        ></View>
        <Text style={{ fontSize: 8, fontWeight: 500, color: colors.prussianBlue }}>Unmodified Risk</Text>
      </View>

      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginRight: 16,
        }}
      >
        <View
          style={{
            height: 2.5,
            borderRadius: 2,
            width: 27,
            backgroundColor: colors.gp1,
            marginRight: 6,
          }}
        ></View>
        <Text style={{ fontSize: 8, fontWeight: 500, color: colors.prussianBlue }}>Live Risk</Text>
      </View>

      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            height: 2.5,
            borderRadius: 2,
            width: 27,
            backgroundColor: colors.brightBlue,
            marginRight: 6,
          }}
        ></View>
        <Text style={{ fontSize: 8, fontWeight: 500, color: colors.prussianBlue }}>Projected Risk</Text>
      </View>
    </View>
  );
};

export const LegendTolerance: React.FC<Props> = ({ colors }) => {
  return (
    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            height: 2.5,
            borderRadius: 2,
            width: 8,
            backgroundColor: colors.cflowerBlue,
            marginRight: 2,
          }}
        ></View>
        <View
          style={{
            height: 2.5,
            borderRadius: 2,
            width: 8,
            backgroundColor: colors.cflowerBlue,
            marginRight: 2,
          }}
        ></View>
        <View
          style={{
            height: 2.5,
            borderRadius: 2,
            width: 8,
            backgroundColor: colors.cflowerBlue,
            marginRight: 6,
          }}
        ></View>

        <Text style={{ fontSize: 8, fontWeight: 500, color: colors.prussianBlue }}>Risk Tolerance</Text>
      </View>
    </View>
  );
};
