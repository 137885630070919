import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Popper from '@mui/material/Popper';
import { useAuth } from 'src/state/auth';
import { useAuth0 } from '@auth0/auth0-react';
import { makeLogout, getProfileCredentials } from 'src/utils/auth';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import { StyledAvatar, Poppins, TextOverflowTooltip } from 'src/common';
import { UserModal } from 'src/components/modals';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { useStateSelector } from 'src/redux';
import useActionsTheme from 'src/redux/actionHooks/useActionsTheme';

const StyledPopper = styled(Popper)`
  z-index: 2000;
  margin-top: 16px;
`;

const UserMenuContainer = styled.div`
  box-shadow: 0px 0px 37px ${({ theme }) => theme.colors.shadow};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  width: 420px;
  padding: 20px;
  .menus {
    margin-top: 20px;
    display: grid;
    grid-gap: 8px;

    .beamer-new {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      top: -7px;
      right: -20px;
      background: ${({ theme }) => theme.colors.gradient};
      color: ${({ theme }) => theme.colors.white};
      border-radius: 50%;
      width: 18px;
      height: 18px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .menus {
      margin-top: 10px;
      grid-gap: 4px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 360px;
    .menus {
      grid-gap: 2px;
    }
  }
`;

const UserEmail = styled(Poppins)`
  font-weight: 500;
  letter-spacing: 0.4375px;
  max-width: calc(100% - 0px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.cflowerBlue};
`;

const MenuItem = styled.div`
  cursor: pointer;
  font-weight: 500;
`;

interface UserMenuProps {
  anchorEl: any;
  setAnchorEl: (value: null) => void;
}

export const UserMenu: React.FC<UserMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const scheme = useStateSelector(({ theme }) => theme.scheme);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const { user } = useAuth();
  const { logout: logoutAuth0 } = useAuth0();
  const [notificationNumber, setNotificationNumber] = useState(0);
  const { setScheme } = useActionsTheme();

  const ref = useRef(null);
  const open = Boolean(anchorEl);

  useOnClickOutside(ref, () => setAnchorEl(null));

  useEffect(() => {
    let interval = setInterval(() => {
      // @ts-ignore
      const num: number = window.Beamer?.notificationNumber || 0;
      setNotificationNumber(num);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const fullLogout = () => {
    makeLogout();
    logoutAuth0({ logoutParams: { returnTo: `${window.location.protocol}//${window.location.host}/auth` } });
  };

  return (
    <>
      {profileModalOpen && <UserModal onClose={() => setProfileModalOpen(false)} />}
      <StyledPopper
        ref={ref}
        id="mouse-over-popover"
        disablePortal={false}
        open={open}
        anchorEl={anchorEl}
        modifiers={[
          {
            name: 'flip',
            enabled: true,
          },
          {
            name: 'preventOverflow',
            options: {
              boundariesElement: 'scrollParent',
            },
          },
        ]}
        data-cy="user-menu"
      >
        <UserMenuContainer>
          <div css="display: flex;align-items: center;">
            <StyledAvatar $medium src={user?.profile_photo_path as string | undefined} alt="User Image">
              {getProfileCredentials(user?.name)}
            </StyledAvatar>
            <div css="margin-left: 12px;display: flex;flex-direction: column;justify-content: center;width: 100%; max-width: calc(100% - 30px);">
              <Poppins color="prussianBlue" weight={600} css="margin-right: 16px;" data-cy="user-name">
                {user?.name}
              </Poppins>
              <TextOverflowTooltip tooltipText={user?.email || ''}>
                <UserEmail px={14} data-cy="user-email">
                  {user?.email}
                </UserEmail>
              </TextOverflowTooltip>
            </div>
          </div>
          <div className="menus">
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setProfileModalOpen(true);
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Edit profile',
                  tags: ['USER'],
                });
              }}
              data-cy="edit-profile-button"
            >
              <Poppins color="cflowerBlue">Edit profile</Poppins>
            </MenuItem>
            <MenuItem
              // id="beamerButton"
              onClick={() => {
                // @ts-ignore
                window.Beamer?.show();
                setAnchorEl(null);
                mpEvent(MPEvents.ButtonClick, {
                  button: "What's new",
                });
              }}
            >
              <Poppins color="cflowerBlue">
                What’s new {notificationNumber > 0 && <div className="beamer-new">{notificationNumber}</div>}
              </Poppins>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setScheme(scheme === 'light' ? 'dark' : 'light');
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Switch theme',
                  value: scheme === 'light' ? 'dark' : 'light',
                });
              }}
              data-cy="theme-button"
            >
              <Poppins color="cflowerBlue">Switch to {scheme === 'light' ? 'dark' : 'light'} mode</Poppins>
            </MenuItem>
            <MenuItem onClick={() => fullLogout()} data-cy="logout-button">
              <Poppins color="cflowerBlue">Logout</Poppins>
            </MenuItem>
          </div>
        </UserMenuContainer>
      </StyledPopper>
    </>
  );
};
