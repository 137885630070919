import React from 'react';
import { AiSvg } from 'src/assets/dynamicSvg';
import { Poppins } from 'src/common';
import CheckBox from 'src/components/form/CheckBox';
import styled from 'styled-components';

export const Wrap = styled.div<{ $disabled: boolean }>`
  margin-top: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: -10px !important;
  cursor: pointer;
  user-select: none;
  width: fit-content;
  justify-self: flex-start;
  align-self: flex-start;

  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
`;

interface SuggestModifiersCheckerProps {
  isDisabledModSuggest: boolean;
  addRecommendedModifiers: boolean;
  setAddRecommendedModifiers: (value: boolean) => void;
}

export const SuggestModifiersChecker: React.FC<SuggestModifiersCheckerProps> = ({
  isDisabledModSuggest,
  addRecommendedModifiers,
  setAddRecommendedModifiers,
}) => {
  return (
    <Wrap
      onClick={isDisabledModSuggest ? undefined : () => setAddRecommendedModifiers(!addRecommendedModifiers)}
      $disabled={isDisabledModSuggest}
    >
      <CheckBox isChecked={addRecommendedModifiers} disabled={isDisabledModSuggest} />
      <AiSvg isActive={!isDisabledModSuggest} />
      <Poppins px={14} weight={500}>
        Do you want to apply the suggested modifiers?
      </Poppins>
    </Wrap>
  );
};
