import React from 'react';
import { useStateSelector } from 'src/redux';
import styled, { css } from 'styled-components';
import { QKeys, VoteValue } from 'src/api/types';
import { useMutation } from 'react-query';
import { queryCache } from 'react-query';
import { scoreControlVote } from 'src/api/controllibraries';

const Div = styled.div<{ $isDisabled: boolean }>`
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  grid-gap: 8px;
  cursor: default;

  .rt-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 16px;
    }
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.6;
    `}
`;

interface RatingProps {
  value: VoteValue;
  controlId: string;
}

export const Rating: React.FC<RatingProps> = ({ value, controlId }) => {
  const color = useStateSelector(({ theme }) => theme.colors.cflowerBlue);

  const [score, { isLoading }] = useMutation(scoreControlVote, {
    onSuccess: () => queryCache.invalidateQueries(QKeys.RankedControls),
  });

  const handleChange = (value: VoteValue) => {
    score({
      control_id: controlId,
      score: value,
    });
  };

  const up = (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 8V20H0V8H4ZM8 20C7.46957 20 6.96086 19.7893 6.58579 19.4142C6.21071 19.0391 6 18.5304 6 18V8C6 7.45 6.22 6.95 6.59 6.59L13.17 0L14.23 1.06C14.5 1.33 14.67 1.7 14.67 2.11L14.64 2.43L13.69 7H20C20.5304 7 21.0391 7.21071 21.4142 7.58579C21.7893 7.96086 22 8.46957 22 9V11C22 11.26 21.95 11.5 21.86 11.73L18.84 18.78C18.54 19.5 17.83 20 17 20H8ZM8 18H17.03L20 11V9H11.21L12.34 3.68L8 8.03V18Z"
        fill={color}
      />
    </svg>
  );

  const upFilled = (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 9C22 8.46957 21.7893 7.96086 21.4142 7.58579C21.0391 7.21071 20.5304 7 20 7H13.68L14.64 2.43C14.66 2.33 14.67 2.22 14.67 2.11C14.67 1.7 14.5 1.32 14.23 1.05L13.17 0L6.59 6.58C6.22 6.95 6 7.45 6 8V18C6 18.5304 6.21071 19.0391 6.58579 19.4142C6.96086 19.7893 7.46957 20 8 20H17C17.83 20 18.54 19.5 18.84 18.78L21.86 11.73C21.95 11.5 22 11.26 22 11V9ZM0 20H4V8H0V20Z"
        fill={color}
      />
    </svg>
  );

  const downFilled = (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 12H22V0H18M14 0H5C4.17 0 3.46 0.5 3.16 1.22L0.14 8.27C0.05 8.5 0 8.74 0 9V11C0 11.5304 0.210714 12.0391 0.585786 12.4142C0.960859 12.7893 1.46957 13 2 13H8.31L7.36 17.57C7.34 17.67 7.33 17.77 7.33 17.88C7.33 18.3 7.5 18.67 7.77 18.94L8.83 20L15.41 13.41C15.78 13.05 16 12.55 16 12V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0Z"
        fill={color}
      />
    </svg>
  );

  const down = (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 12V0H22V12H18ZM14 0C14.5304 0 15.0391 0.210714 15.4142 0.585786C15.7893 0.960859 16 1.46957 16 2V12C16 12.55 15.78 13.05 15.41 13.41L8.83 20L7.77 18.94C7.5 18.67 7.33 18.3 7.33 17.88L7.36 17.57L8.31 13H2C1.46957 13 0.960859 12.7893 0.585786 12.4142C0.210714 12.0391 0 11.5304 0 11V9C0 8.74 0.05 8.5 0.14 8.27L3.16 1.22C3.46 0.5 4.17 0 5 0H14ZM14 2H4.97L2 9V11H10.78L9.65 16.32L14 11.97V2Z"
        fill={color}
      />
    </svg>
  );

  return (
    <Div $isDisabled={isLoading}>
      <div className="rt-btn" onClick={() => handleChange(value === 1 ? null : 1)}>
        {value === 1 ? upFilled : up}
      </div>
      <div className="rt-btn" onClick={() => handleChange(value === -1 ? null : -1)}>
        {value === -1 ? downFilled : down}
      </div>
    </Div>
  );
};
