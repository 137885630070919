import React from 'react';
import { StyledAvatar, TextOverflowTooltip } from 'src/common';
import { getProfileCredentials } from 'src/utils/auth';
import styled from 'styled-components';

const Div = styled.div`
  display: inline-flex;
  align-items: center;
  /* justify-content: center; */
  grid-gap: 10px;

  span {
    font-weight: 500;
  }
`;

interface OrgAvatarProps {
  name: string;
  imgUrl?: string | null;
  gradientBlue?: boolean;
}

export const OrgAvatar: React.FC<OrgAvatarProps> = ({ name, imgUrl, gradientBlue }) => {
  return (
    <Div>
      <StyledAvatar $small src={imgUrl || ''} $gradientBlue={gradientBlue}>
        {getProfileCredentials(name)}
      </StyledAvatar>
      <TextOverflowTooltip>
        <span>{name}</span>
      </TextOverflowTooltip>
    </Div>
  );
};
