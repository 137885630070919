import { AxiosArgs, Modifiers, PlanScenarios, ThenArgs } from 'src/api/types';
import axios, { AxiosResponse } from 'axios';
import { encodeBody, encodeQueryParams } from 'src/api/client';

type CreatePlan = (data: {
  scenarioIds: { id: string }[];
  controlIds: { id: string }[];
  addRecommendedModifiers?: boolean;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const createPlan: CreatePlan = (data) => {
  const path = `/api/public/api/plans/createplan`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type GetControlScenarios = (
  controlId: string,
  query?: { version?: string },
) => Promise<AxiosArgs<ThenArgs<AxiosResponse<PlanScenarios[]>>>>;

export const getControlScenarios: GetControlScenarios = (controlId, query = {}) => {
  const path = `/api/public/api/plans/${controlId}${encodeQueryParams(query)}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type GetScenarioControls = (
  scenarioId: string,
  query?: { version?: string },
) => Promise<AxiosArgs<ThenArgs<AxiosResponse<PlanScenarios[]>>>>;

export const getScenarioControls: GetScenarioControls = (scenarioId, query = {}) => {
  const path = `/api/public/api/plans/scenario/${scenarioId}${encodeQueryParams(query)}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type unlinkScenarioFromControl = (planId: string) => Promise<AxiosArgs<ThenArgs<AxiosResponse<PlanScenarios[]>>>>;

export const unlinkScenarioFromControl: unlinkScenarioFromControl = (planId) => {
  const path = `/api/public/api/plans/${planId}`;
  return axios({
    method: 'DELETE',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type UpdateModifiers = (a: {
  scenario_id: string;
  control_id: string;
  modifiers: Modifiers;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const updateModifiers: UpdateModifiers = (data) => {
  const path = `/api/public/api/modifiers`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type DeleteModifiers = (a: {
  scenario_id: string;
  control_id: string;
  modifiers: { id: string }[];
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const deleteModifiers: DeleteModifiers = (data) => {
  const path = `/api/public/api/modifiers`;
  return axios({
    method: 'DELETE',
    url: path,
    data: encodeBody(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------
