import React from 'react';
import styled from 'styled-components';
import { Tooltip as ExternalTooltip, PositionStrategy } from 'react-tooltip';
import { Info } from 'src/assets/dynamicSvg';

const Div = styled.div`
  svg {
    outline: none;
  }

  .react-tooltip {
    color: ${({ theme }) => theme.colors.white} !important;
    background-color: ${({ theme }) => theme.colors.prussianBlue} !important;
  }
`;

interface TooltipProps {
  dataId?: string;
  dataHtml: string;
  children?: React.ReactNode;
  place?: 'top' | 'bottom' | 'left' | 'right';
  maxWidth?: number;
  positionStrategy?: PositionStrategy;
  noIcon?: boolean;
  delayShow?: number;
}

export const Tooltip: React.FC<TooltipProps> = ({
  dataId = 't-tip',
  dataHtml,
  children,
  place = 'left',
  maxWidth = 500,
  positionStrategy = 'absolute',
  noIcon = false,
  delayShow,
}) => {
  return (
    <Div>
      <ExternalTooltip
        id={dataId}
        place={place}
        positionStrategy={positionStrategy}
        style={{
          zIndex: 10,
          maxWidth,
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
          wordWrap: 'break-word',
          borderRadius: '6px',
          whiteSpace: 'pre-wrap',
        }}
        delayShow={delayShow}
        css={`
          * {
            word-break: break-word;
            overflow-wrap: break-word;
            word-wrap: break-word;
          }
        `}
        opacity={1}
      />
      {children ? (
        <div data-tooltip-id={dataId} data-tooltip-html={dataHtml} className="tooltip-children">
          {children}
        </div>
      ) : (
        <>{noIcon ? null : <Info data-tooltip-id={dataId} data-tooltip-html={dataHtml} />}</>
      )}
    </Div>
  );
};
