import React, { useState } from 'react';
import styled from 'styled-components';
import CommonMenu from 'src/components/CommonMenu';
import { deleteFrameworkLibrary } from 'src/api/frameworklibraries';
import { useNavigate } from 'react-router';
import { queryCache, useMutation } from 'react-query';
import { Poppins, Spacer, TextOverflowTooltip } from 'src/common';
import { ConfirmDeleteModal, FrameworkLibraryModal } from 'src/components/modals';
import { Framework, QKeys } from 'src/api/types';
import { ThreeDots } from 'src/components/CommonMenu/comps';
import { Tile, TileDescription, TileDescriptionContainer, TileTitle } from 'src/components/Tile';
import { Link } from 'react-router-dom';
import { MPEvents } from 'src/utils/mixpanel/types';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { ScenarioSvg } from 'src/assets/dynamicSvg';

const BottomContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface FrameworkLibraryCardProps {
  framework: Framework;
}

export const FrameworkLibraryCard: React.FC<FrameworkLibraryCardProps> = ({ framework }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<React.MouseEvent['currentTarget'] | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isFrameworkLibModal, setIsFrameworkLibModal] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [remove] = useMutation(deleteFrameworkLibrary, {
    onSuccess: () => {
      queryCache.invalidateQueries(QKeys.Frameworks).then(() => {
        handleClose();
      });
    },
  });

  const handleOnViewClick = () => {
    navigate(`/control-frameworks/${framework.ux_id}`);
  };

  const handleOnDeleteClick = () => {
    remove({ id: framework.ux_id }).then(() => {
      setIsDeleteModalOpen(false);
    });
  };

  const openDeleteModal = () => {
    handleClose();
    setIsDeleteModalOpen(true);
  };

  const onMenuClick = (e: React.MouseEvent<HTMLLIElement>, cb: () => void) => {
    e.stopPropagation();
    handleClose();
    cb();
  };

  return (
    <>
      {!!isFrameworkLibModal && (
        <FrameworkLibraryModal
          frameworkData={framework}
          onClose={() => {
            setIsFrameworkLibModal(false);
          }}
        />
      )}
      {isDeleteModalOpen && (
        <ConfirmDeleteModal onConfirm={handleOnDeleteClick} onClose={() => setIsDeleteModalOpen(false)} />
      )}
      <CommonMenu
        onViewClick={(e) => onMenuClick(e, () => handleOnViewClick())}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      />

      <Tile
        className="hover-shadow"
        onClick={() => {
          if (!anchorEl) {
            navigate(`/control-frameworks/${framework.ux_id}`);
            mpEvent(MPEvents.Link, {
              source: {
                value: ['Frameworks'],
              },
              destination: { value: ['Framework'], params: { id: framework.ux_id } },
              tags: ['FRAMEWORK'],
            });
          }
        }}
        data-cy="framework-library-card"
      >
        <div css="display: flex;justify-content: space-between;align-items: center;">
          <TextOverflowTooltip tooltipText={framework.shortname || ''}>
            <Link
              to={`/control-frameworks/${framework.ux_id}`}
              onClick={(e) => {
                e.stopPropagation();
                mpEvent(MPEvents.Link, {
                  source: {
                    value: ['Frameworks'],
                  },
                  destination: { value: ['Framework'], params: { id: framework.ux_id } },
                  tags: ['FRAMEWORK'],
                });
              }}
            >
              <TileTitle>{framework.shortname}</TileTitle>
            </Link>
          </TextOverflowTooltip>

          <ThreeDots
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
          />
        </div>
        <Spacer $px={20} />
        <TileDescriptionContainer>
          <TileDescription>
            <Poppins px={14} color="cflowerBlue">
              {framework.name}
            </Poppins>
          </TileDescription>
        </TileDescriptionContainer>

        <BottomContainer>
          <div css="display: flex;align-items: center;flex: 1;">
            <ScenarioSvg />
            <div>
              <Poppins css="margin-left: 6px;" px={18} weight={500}>
                {framework.control_libraries_count}
              </Poppins>
              <Poppins css="margin-left: 4px;" px={14} color="cflowerBlue">
                {framework.control_libraries_count === 1 ? 'control' : 'controls'}
              </Poppins>
            </div>
          </div>
          <Poppins px={14}>{framework.author}</Poppins>
        </BottomContainer>
      </Tile>
    </>
  );
};
