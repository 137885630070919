const KEY = 'expand-sections';

interface SavedValues {
  [sectionId: string]: boolean;
}

export const saveSection = (params: { sectionId: string; isOpen: boolean }) => {
  const { sectionId, isOpen } = params;

  let values: SavedValues = {};

  try {
    const val = JSON.parse(window.localStorage.getItem(KEY) || '');

    if (!val || typeof val !== 'object') {
      throw new Error('Invalid value');
    }

    values = val;
  } catch (error) {}

  values[sectionId] = isOpen;
  window.localStorage.setItem(KEY, JSON.stringify(values));
};

export const loadSection = (sectionId: string): boolean | null => {
  let values: SavedValues = {};

  try {
    const val = JSON.parse(window.localStorage.getItem(KEY) || '');

    if (!val || typeof val !== 'object') {
      throw new Error('Invalid value');
    }

    values = val;
  } catch (error) {}

  if (values[sectionId] === undefined) return null;

  return !!values[sectionId];
};
