import {
  AxiosArgs,
  ThenArgs,
  ControlLibrary,
  ControlLibraryShort,
  ControlLibraryShortRanked,
  VoteValue,
} from 'src/api/types';
import axios, { AxiosResponse } from 'axios';
import { encodeBody } from 'src/api/client';

type CreateControlLibrary = (
  frameworklibraryId: string,
  parameters: {
    name: string;
    shortname: string;
    controlLibraryId: string;
    external_id: string;
    version: string;
    author: string;
    description: string;
    publish_date: string;
  },
) => Promise<AxiosArgs<ThenArgs<AxiosResponse<ControlLibrary>>>>;

export const createControlLibrary: CreateControlLibrary = (frameworklibraryId, parameters) => {
  const path = `/api/public/api/controllibraries/${frameworklibraryId}`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(parameters),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};

// ------------------------------------------------------------

type UpdateControlLibrary = (a: {
  ux_id: string | number;
  id: string | number;
  name: string;
  shortname: string;
  controlLibraryId: string;
  owner: string;
  strategy: string;
  description: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<ControlLibrary>>>>;

export const updateControlLibrary: UpdateControlLibrary = ({ ux_id, ...parameters }) => {
  const path = `/api/public/api/controllibraries/${ux_id}`;
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(parameters),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};

// ------------------------------------------------------------

type GetControlLibraries = (
  frameworkLibraryId: string,
) => Promise<AxiosArgs<ThenArgs<AxiosResponse<ControlLibraryShort[]>>>>;

export const getControlLibraries: GetControlLibraries = (frameworkLibraryId) => {
  const path = `/api/public/api/controllibraries/frameworklibrary/${frameworkLibraryId}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type GetControlLibrary = (controlLibraryId: string) => Promise<AxiosArgs<ThenArgs<AxiosResponse<ControlLibrary>>>>;

export const getControlLibrary: GetControlLibrary = (controlLibraryId) => {
  const path = `/api/public/api/controllibraries/${controlLibraryId}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type GetAllControlLibraries = () => Promise<AxiosArgs<ThenArgs<AxiosResponse<ControlLibraryShort[]>>>>;

export const getAllControlLibraries: GetAllControlLibraries = () => {
  const path = `/api/public/api/controllibraries-all`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type GetRankedControls = (
  scenarioId: string,
) => Promise<AxiosArgs<ThenArgs<AxiosResponse<ControlLibraryShortRanked[]>>>>;

export const getRankedControls: GetRankedControls = (scenarioId) => {
  const path = `/api/public/api/controllibraries/ranked-controls?scenarioId=${scenarioId}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type ScoreControlVote = (params: {
  control_id: string;
  score: VoteValue;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<ControlLibraryShortRanked[]>>>>;

export const scoreControlVote: ScoreControlVote = (params) => {
  const path = `/api/public/api/controllibraries/score-control`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(params),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------
