import React, { useState } from 'react';
import { Poppins, Spacer } from 'src/common';
import { ExpandedIndicator } from '../../ExpandedIndicator';
import { TimelineEvent } from 'src/api/types/dashboard';
import { NoAction } from 'src/pages/DashboardPage/Events';
import { parseEvents } from 'src/pages/DashboardPage/Events/util';
import { motion } from 'framer-motion';
import { getSectionOpenState, setSectionOpenState } from 'src/pages/DashboardPage/Filters/util';

interface SectionProps {
  title: string;
  ranges: TimelineEvent[];
  id: string;
}

export const Section: React.FC<SectionProps> = ({ ranges, title, id }) => {
  const [isOpen, setIsOpen] = useState(!getSectionOpenState({ id, storageKey: 'upcoming_actions' }));

  return (
    <>
      <div
        className="container___upcoming__head"
        onClick={() => {
          setSectionOpenState({ id, isOpen, storageKey: 'upcoming_actions' });
          setIsOpen(!isOpen);
        }}
      >
        <Poppins px={14} weight={500} color="cflowerBlue">
          {title}
        </Poppins>
        <ExpandedIndicator isOpen={isOpen} />
      </div>
      <motion.div
        initial={false}
        animate={{ height: isOpen ? 'unset' : 0 }}
        className="container___upcoming__collapse"
        transition={{ type: 'tween' }}
      >
        <Spacer $px={4} />
        <div className="container___upcoming__item">
          {ranges.length ? parseEvents({ events: ranges, shownDate: true }) : <NoAction />}
        </div>
        <Spacer $px={12} />
      </motion.div>
    </>
  );
};
