import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { ScenarioTemplate } from 'src/api/types';
import { GTCheckBox, GTColumnSelectAnchor, GTExpander } from 'src/components/GenericTable';
import { filter } from 'lodash';
import { GTInternalIds } from '../types';
import { gTCheckHandler } from '../util';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { Link } from 'react-router-dom';
import { TableIds } from 'src/api/types/misc';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { MPEvents } from 'src/utils/mixpanel/types';
import { TextOverflowTooltip } from 'src/common';

type UseColumns = (a: { data?: ScenarioTemplate[]; isCheckerEnabled?: boolean; tableId: TableIds }) => {
  columns: ColumnDef<ScenarioTemplate>[];
  checked: string[];
  setChecked: (a: string[]) => void;
  GTColumnSelectAnchorExported: JSX.Element;
};

const defaultColumns = ['author', 'name', 'description'];

export const useScenarioLibraryColumns: UseColumns = ({ data = [], isCheckerEnabled, tableId }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(`${tableId}-columns`, defaultColumns);
  const [checked, setChecked] = useState<string[]>([]);

  const columns = useMemo(() => {
    let internalIds = [GTInternalIds.expander, GTInternalIds.tdm];

    if (isCheckerEnabled) {
      internalIds.push(GTInternalIds.checker);
    } else {
      internalIds = internalIds.filter((el) => el !== GTInternalIds.checker);
      if (checked.length > 0) setChecked([]);
    }

    const list: ColumnDef<ScenarioTemplate>[] = [
      {
        id: GTInternalIds.checker,
        header: ({ table }) => {
          const { isAllChecked, checkAllHandler } = gTCheckHandler(
            {
              checked,
              selector: 'ux_id',
              table,
            },
            setChecked,
          );

          return <GTCheckBox isChecked={isAllChecked} onClick={checkAllHandler} />;
        },
        cell: ({ row }) => {
          const id = row.original.ux_id;
          return (
            <GTCheckBox
              isChecked={checked.includes(id)}
              onClick={() => {
                if (checked.includes(id)) {
                  setChecked(checked.filter((el) => el !== id));
                } else {
                  setChecked([...checked, id]);
                }
              }}
            />
          );
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: GTInternalIds.expander,
        header: () => null,
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <GTExpander onClick={row.getToggleExpandedHandler()} isExpanded={row.getIsExpanded()} />
          ) : null;
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: 'author',
        header: 'Author',
        accessorFn: (row) => row.author || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => row.name || null,
        cell: (info) => (
          <TextOverflowTooltip>
            <Link
              className="table-link"
              to={`/scenario-library/${info.row.original.ux_id}`}
              target="_blank"
              onClick={(e) => {
                e.stopPropagation();
                mpEvent(MPEvents.Link, {
                  source: {
                    value: ['Scenario Template'],
                  },
                  destination: {
                    value: ['Scenario Template Detail'],
                    params: {
                      id: info.row.original.ux_id,
                    },
                  },
                  tags: ['SCENARIO TEMPLATE'],
                });
              }}
            >
              {info.getValue() as string}
            </Link>
          </TextOverflowTooltip>
        ),
        sortingFn: sortingFns.text,
        maxSize: 300,
      },
      {
        id: 'description',
        header: 'Description',
        accessorFn: (row) => row.description || null,
        cell: (info) => <TextOverflowTooltip>{info.getValue() as string}</TextOverflowTooltip>,
        sortingFn: sortingFns.text,
        maxSize: 700,
      },
      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: () => null,
        size: 1,
        maxSize: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns, checked, isCheckerEnabled]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'author', title: 'Author' },
        { key: 'name', title: 'Name' },
        { key: 'description', title: 'Description' },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return { columns, checked, setChecked, GTColumnSelectAnchorExported };
};
