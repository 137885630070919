import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { numeralFormat, getCurrencySymbol } from 'src/utils/misc';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: 4,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dot: {
    width: 9,
    height: 9,
    borderRadius: '50%',
    marginRight: 4,
  },
  label: {
    fontSize: 13,
    fontWeight: 600,
  },
  currencySymbol: {
    fontSize: 10,
    fontWeight: 500,
  },
  currencyValue: {
    fontSize: 13,
    fontWeight: 600,
  },

  dotBase: {
    backgroundColor: '#022033',
  },
  dotLive: {
    backgroundColor: '#E62E78',
  },
  dotComplete: {
    backgroundColor: '#2BBDEF',
  },
});

interface VariantValuesPDFProps {
  value:
    | number
    | {
        base?: number;
        live?: number;
        complete?: number;
      };
  variant?: 'assessment' | 'scenario';
}

export const VariantValuesPDF: React.FC<VariantValuesPDFProps> = ({ value, variant = 'assessment' }) => {
  const isScenario = variant === 'scenario';

  const containerStyle = {
    ...styles.container,
  };

  const labelStyle = {
    ...styles.label,
    fontSize: isScenario ? 10 : 13,
    fontWeight: isScenario ? 500 : 600,
    color: isScenario ? '#022033' : undefined,
    whiteSpace: isScenario ? 'pre-wrap' : undefined,
  };

  const dotStyle = {
    ...styles.dot,
    width: isScenario ? 7 : 9,
    height: isScenario ? 7 : 9,
  };

  const renderCurrency = (amount: number) => {
    const symbol = getCurrencySymbol();
    const formattedNumber = numeralFormat(amount).replace(symbol, '').trim();
    const symbolStyle = {
      ...styles.currencySymbol,
      fontSize: isScenario ? 10 : 10,
      color: isScenario ? '#022033' : undefined,
      fontWeight: isScenario ? 500 : 400,
    };
    const valueStyle = {
      ...styles.currencyValue,
      fontSize: isScenario ? 10 : 13,
      color: isScenario ? '#022033' : undefined,
      fontWeight: isScenario ? 500 : 600,
    };
    return (
      <Text>
        <Text style={symbolStyle}>{symbol}</Text>
        <Text style={valueStyle}>{formattedNumber}</Text>
      </Text>
    );
  };

  if (!value) return null;

  if (typeof value === 'number') {
    return <Text style={labelStyle}>{numeralFormat(value)}</Text>;
  }

  return (
    <View style={containerStyle}>
      {value.base != null && (
        <View style={styles.item}>
          <View style={[dotStyle, styles.dotBase]} />
          {renderCurrency(value.base)}
        </View>
      )}
      {value.live != null && (
        <View style={styles.item}>
          <View style={[dotStyle, styles.dotLive]} />
          {renderCurrency(value.live)}
        </View>
      )}
      {value.complete != null && (
        <View style={styles.item}>
          <View style={[dotStyle, styles.dotComplete]} />
          {renderCurrency(value.complete)}
        </View>
      )}
    </View>
  );
};
