import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { getScenario } from 'src/api/scenario';
import { useParams } from 'react-router';
import { ProjectionVariant, QKeys } from 'src/api/types';
import Meta from 'src/components/Meta';
import { VariantValues, PageCard, PageChartSkeleton, PageContainer, Spacer, Divider } from 'src/common';
import { useGaEventPageView } from '../../utils/gaEvent';
import { Head } from './comps';
import useVersion from 'src/hooks/useVersion';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import { useAuth } from 'src/state/auth';
import {
  AuditSection,
  ExceedanceSection,
  IncidentsSection,
  RiskManagementSection,
  RiskStatementSection,
} from './sections';

const ChartBox = styled(PageCard)`
  padding-bottom: 0;
`;

const ScenarioPage = () => {
  const { scenarioId } = useParams<{ scenarioId: string; assessmentId: string }>();
  const [isLogarithmic, setIsLogarithmic] = useState(true);
  const { version } = useVersion();
  const { setLocationPaths } = useActionsApp();

  const { userFeatures } = useAuth();

  const { data: scenario, isLoading } = useQuery(
    [QKeys.Scenario, { scenarioId, version, variant: ProjectionVariant.live }],
    getScenario,
  );

  const { data: scenarioBase, isLoading: isLoadingScenarioBase } = useQuery(
    [QKeys.Scenario, { scenarioId, version, variant: ProjectionVariant.base }],
    getScenario,
  );

  const { data: scenarioComplete, isLoading: isLoadingScenarioComplete } = useQuery(
    [QKeys.Scenario, { scenarioId, version, variant: ProjectionVariant.complete }],
    getScenario,
  );

  useEffect(() => {
    if (scenario) {
      setLocationPaths({
        assessment: { id: scenario.assessment_id, name: scenario.assessment_name },
        scenario: { id: scenario.id, name: scenario.name },
      });
    }
    return () => {
      setLocationPaths({ assessment: undefined, scenario: undefined });
    };
  }, [scenario]);

  useGaEventPageView('Cydea | Scenario');

  const effectiveScenario =
    scenarioBase?.is_incomplete === 1 || scenarioBase?.is_incomplete === 3 ? scenarioBase : scenario;

  const lowerThanMinimum = JSON.parse(effectiveScenario?.lower_than_minimum || '[]');

  if (isLoading || isLoadingScenarioBase || isLoadingScenarioComplete)
    return (
      <PageContainer>
        <PageChartSkeleton />
      </PageContainer>
    );

  const showAllProjected = !!scenarioBase?.implementationPlans
    ?.filter((plan) => plan.control && plan.control.status !== 'Abandoned')
    ?.flatMap((plan) => plan.modifiers)
    ?.filter((modifier) => modifier.type !== 'NOTE_TYPE')?.length;

  return (
    <>
      {effectiveScenario && scenarioBase && (
        <>
          <Meta title={`Cydea | ${effectiveScenario?.name}`} feedbackScript />
          <PageContainer data-cy="scenario-page">
            <ChartBox>
              <Head
                scenario={effectiveScenario}
                isLogarithmic={isLogarithmic}
                expectedLossComponent={
                  <VariantValues
                    flat
                    value={
                      showAllProjected
                        ? {
                            base: scenarioBase.expected_loss,
                            live: scenario?.expected_loss,
                            cpmplete: scenarioComplete?.expected_loss,
                          }
                        : effectiveScenario.expected_loss
                    }
                    tooltipText={{
                      base: 'Expected Loss without control modifiers applied',
                      live: 'Expected Loss with control modifiers from live controls applied',
                      cpmplete: 'Expected Loss with control modifiers from all controls applied',
                    }}
                  />
                }
              />
              <Spacer $px={20} />
              <Divider />
              <ExceedanceSection
                isLogarithmic={isLogarithmic}
                showAllProjected={showAllProjected}
                setIsLogarithmic={setIsLogarithmic}
                lowerThanMinimum={lowerThanMinimum}
                effectiveScenario={effectiveScenario}
              />
              <Divider />
              <RiskStatementSection
                scenario={scenario}
                effectiveScenario={effectiveScenario}
                scenarioBase={scenarioBase}
                lowerThanMinimum={lowerThanMinimum}
                showAllProjected={showAllProjected}
                scenarioComplete={scenarioComplete}
              />
              <Divider />
              <RiskManagementSection effectiveScenario={effectiveScenario} scenarioBase={scenarioBase} />
              <Divider />
              <IncidentsSection scenario={scenarioBase} />
              {userFeatures.app.audit.report && (
                <>
                  <Divider />
                  <AuditSection version={version} />
                </>
              )}
            </ChartBox>
          </PageContainer>
        </>
      )}
    </>
  );
};

export default ScenarioPage;
