import React from 'react';
import { Poppins, Tooltip } from 'src/common';
import styled, { css } from 'styled-components';

const Div = styled.div`
  display: inline-block;
  width: 100%;
  margin-left: 6px;

  .grid {
    grid-gap: 10px;
    position: relative;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fill, minmax(1fr, auto));
    align-items: flex-start;
    justify-content: flex-start;
    &__item {
      position: relative;
      height: 100%;
    }
  }
`;

const Item = styled.div<{ $isActive: boolean; $isFirst: boolean; $small?: boolean }>`
  text-align: center;
  padding: 0 20px;
  padding-bottom: 10px;
  cursor: pointer;
  user-select: none;

  color: ${({ $isActive, theme }) => ($isActive ? theme.colors.prussianBlue : theme.colors.cflowerBlue)};

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0 10px;
    padding-bottom: 6px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0 2px;
    padding-bottom: 2px;
  }

  span {
    line-height: 1.5;
  }

  ${({ $small }) =>
    $small &&
    css`
      padding: 0 4px;
      padding-bottom: 6px;
    `};

  ${({ $isFirst }) =>
    $isFirst &&
    css`
      padding-left: 0;
    `};
`;

const Marker = styled.div<{ $isActive: boolean }>`
  height: 4px;
  position: absolute;
  width: 100%;
  background: ${({ theme }) => theme.colors.gradient};
  transition: transform 0.3s ease-in-out;
  border-radius: 3px;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
`;

interface TabsMenuProps {
  tabs: { label: string | React.ReactNode; value: any; dataCy?: string; tooltip?: string }[];
  setActiveTab: (tab: any) => void;
  activeTab: any;
  small?: boolean;
}

const TabsMenu: React.FC<TabsMenuProps> = ({ activeTab, setActiveTab, tabs, small }) => {
  return (
    <Div>
      <div className="grid">
        {tabs.map((el, idx) => {
          const isActive = activeTab === el.value;
          return (
            <Tooltip dataId="lol" dataHtml={el.tooltip || ''} place="top">
              <div className="grid__item" key={el.value} data-cy={el.dataCy}>
                <Item $isActive={isActive} $isFirst={idx === 0} onClick={() => setActiveTab(el.value)} $small={small}>
                  <Poppins px={18} weight={600} color={isActive ? 'prussianBlue' : 'cflowerBlue'}>
                    {el.label}
                  </Poppins>
                </Item>
                <Marker $isActive={isActive} />
              </div>
            </Tooltip>
          );
        })}
      </div>
    </Div>
  );
};

export default TabsMenu;
