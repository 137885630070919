import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import useLocalStorage from './useLocalStorage';
import { SUGGESTER_STORAGE_KEY } from 'src/components/Suggester/util';
import { useStateSelector } from 'src/redux';
import { useEffect } from 'react';

type UseSuggestionsProps = (props?: { keys?: string[]; init?: boolean }) => {
  isActive: boolean;
  saveKeys: (keys: string[]) => void;
  hiddenSuggestionKeys: string[];
};

export const useSuggestions: UseSuggestionsProps = (props) => {
  const { keys = [], init = false } = props || {};

  const [savedIds, setSavedIds] = useLocalStorage<string[]>(SUGGESTER_STORAGE_KEY, []);
  const hiddenSuggestionKeys = useStateSelector(({ app }) => app.hiddenSuggestionKeys);
  const { setHiddenSuggestionKeys } = useActionsApp();
  const isActive = keys.some((key) => hiddenSuggestionKeys.includes(key));

  useEffect(() => {
    if (init) {
      setHiddenSuggestionKeys(savedIds);
    }
  }, [init]);

  const saveKeys = (keys: string[]) => {
    setSavedIds(keys);
    setHiddenSuggestionKeys(keys);
  };

  return { isActive, saveKeys, hiddenSuggestionKeys };
};
