import React from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import { PageContainer, Poppins, Spacer } from 'src/common';
import Activity from './comps/Activity';
import styled from 'styled-components';
import UpcomingActions from './comps/UpcomingActions';

const Div = styled(PageContainer)`
  height: 100%;
  display: flex;
  flex-direction: column;
  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    flex: 1;
  }
  ${({ theme }) => theme.breakpoints.down('lg')} {
    .cards {
      grid-template-columns: 1fr;
    }
  }
`;

const DashboardPage = () => {
  useGaEventPageView('Cydea | Timeline');

  return (
    <>
      <Meta title={`Cydea | Timeline`} feedbackScript />
      <Div>
        <Poppins px={32} weight={600}>
          Timeline
        </Poppins>
        <Spacer $px={30} />
        <div className="cards">
          <Activity />
          <UpcomingActions />
        </div>
      </Div>
    </>
  );
};

export default DashboardPage;
