import React from 'react';
import { Assessment } from 'src/api/types';
import { DataTitle, Poppins, Spacer } from 'src/common';
import styled from 'styled-components';
import GenericTable from 'src/components/GenericTable';
import { useColumns } from './useColumns';
import { ScenarioTableExpandContent } from './ScenarioTableExpandContent';
import { TableIds } from 'src/api/types/misc';

const Div = styled.div`
  .tf-head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
  }

  .switch-container {
    display: flex;
    justify-content: center;
  }
`;

interface TableFormProps {
  assessment: Assessment;
}

export const TableForm: React.FC<TableFormProps> = ({ assessment }) => {
  const { columns, GTColumnSelectAnchorExported } = useColumns({
    data: assessment.scenarios || [],
  });

  return (
    <Div>
      <Spacer $px={10} />
      <DataTitle $noMargin>SCENARIOS</DataTitle>
      <Poppins px={14}>
        You can modify the controls applied and see how it affects your risk. Changes made to modifiers can be applied
        to the live scenario, this will not update the status of the controls.
      </Poppins>
      <Spacer $px={20} />
      <GenericTable
        tableId={TableIds.prAssessmentScenarios}
        data={assessment.scenarios}
        columns={columns}
        GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
        expandContent={(scen) => {
          const data =
            scen.implementationPlans
              ?.filter((el) => el.control?.name)
              .map((el) => {
                return { control: el.control, modifiers: el.modifiers };
              }) || [];

          if (!data.length) return null;
          return <ScenarioTableExpandContent data={data} scenario={scen} />;
        }}
        searchable={['name', 'source', 'event', 'consequence', 'expected_loss', 'updated_at']}
        initialSort={[{ id: 'updated_at', desc: true }]}
      />
    </Div>
  );
};
