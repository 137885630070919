import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TableIds } from 'src/api/types/misc';
import Audit from 'src/components/Audit';
import ExpandSection from 'src/components/ExpandSection';

interface AuditSectionProps {
  version?: string;
}

export const AuditSection: React.FC<AuditSectionProps> = ({ version }) => {
  const { controlId } = useParams<{ controlId: string }>();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ExpandSection title="AUDIT" defaultOpen={false} onChange={setIsOpen} sectionId="control_audit">
      {isOpen && (
        <div
          style={{
            maxHeight: '800px',
            position: 'relative',
          }}
        >
          <Audit
            rootPath="controls"
            initQuery={{
              control_id: controlId,
              version,
            }}
            tableId={TableIds.auditControl}
            noHide
            topSpacer={0}
            maxHeight={800}
          />
        </div>
      )}
    </ExpandSection>
  );
};
