import React from 'react';
import { DataTitle } from 'src/common';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { ModifiersTransfer, ProjectedScenario, TransferLimitType } from 'src/api/types';
import { SCurrencyInput, Symbol } from '../../../../comps';
import _ from 'lodash';
import { calcConsequencesLimit } from '../../../util';
import { getCurrencySymbol } from 'src/utils/misc';

const Div = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1.6fr;
  position: relative;

  .g-fix {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .rc--by {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }
  .rc--close {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
  }
  .rc--stats {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;

    span {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.cflowerBlue};
    }

    &__value {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.prussianBlue} !important;
    }
  }

  .tooltip {
    position: absolute;
    right: -10px;
    .slot__content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

interface LimitProps {
  projectedScenario: ProjectedScenario;
  idx: number;
  disabled?: boolean;
  noResult?: boolean;
}

export const Limit: React.FC<LimitProps> = ({ idx, projectedScenario, disabled, noResult }) => {
  const { values, setFieldValue, handleBlur, errors, touched } =
    useFormikContext<ModifiersTransfer<TransferLimitType>>();

  const isErrExcess = _.get(errors, `transfer[${idx}].data.excess`);
  const isErrTpc = _.get(errors, `transfer[${idx}].data.tpc`);
  const isTouchedExcess = _.get(touched, `transfer[${idx}].data.excess`);
  const isTouchedTpc = _.get(touched, `transfer[${idx}].data.tpc`);

  const calculatedConsequences = calcConsequencesLimit({
    lowerConsequence: projectedScenario.lower,
    upperConsequence: projectedScenario.upper,
    excess: values.transfer[idx].data.excess,
    tpc: values.transfer[idx].data.tpc,
  });

  return (
    <Div>
      <div className="g-fix">
        <div className="slot">
          <div className="slot__top">
            <DataTitle $noMargin>EXCESS</DataTitle>
          </div>
          <div className="slot__content">
            <SCurrencyInput
              id={`transfer[${idx}].data.excess`}
              InputProps={{ startAdornment: <Symbol>{getCurrencySymbol()}</Symbol> }}
              value={values.transfer[idx].data.excess}
              onValueChange={({ floatValue }) => setFieldValue(`transfer[${idx}].data.excess`, floatValue)}
              onBlur={handleBlur}
              error={Boolean(!noResult && isErrExcess && isTouchedExcess)}
              helperText={!noResult && isTouchedExcess && isErrExcess}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="slot">
          <div className="slot__top">
            <DataTitle $noMargin>TOTAL POLICY COVER</DataTitle>
          </div>
          <div className="slot__content">
            <SCurrencyInput
              id={`transfer[${idx}].data.tpc`}
              InputProps={{ startAdornment: <Symbol>{getCurrencySymbol()}</Symbol> }}
              value={values.transfer[idx].data.tpc}
              onValueChange={({ floatValue }) => setFieldValue(`transfer[${idx}].data.tpc`, floatValue)}
              onBlur={handleBlur}
              error={Boolean(!noResult && isErrTpc && isTouchedTpc)}
              helperText={!noResult && isTouchedTpc && isErrTpc}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </Div>
  );
};
