import React, { useState } from 'react';
import { deleteScenario } from 'src/api/scenario';
import {
  DataTitle,
  DataValue,
  NoData,
  Poppins,
  Settings,
  Spacer,
  TitleWithBack,
  Tooltip,
  IncompleteStateMarker,
  getIncompleteState,
} from 'src/common';
import CommonMenu from 'src/components/CommonMenu';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useScenario } from 'src/state/scenario';
import { queryCache, useMutation } from 'react-query';
import { QKeys, Scenario } from 'src/api/types';
import useVersion from 'src/hooks/useVersion';
import {
  ConfirmDeleteModal,
  LinkControlsToScenarioModal,
  PdfExportModalScenario,
  RequestAssistanceModal,
  ScenarioModal,
  VariablesWorkspaceModal,
} from 'src/components/modals';
import ProjectedRisk from 'src/components/ProjectedRisk';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { MPEvents } from 'src/utils/mixpanel/types';
import { TLLink } from 'src/pages/DashboardPage/comps';
import { formatDate } from 'src/utils/misc';
import { AISuggestionsHandle } from './AISuggestionsHandle';
import { useAuth } from 'src/state/auth';
import { AiSuggest } from './AiSuggest';
import { useStateSelector } from 'src/redux';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';

const Div = styled.div`
  display: flex;
  justify-content: space-between;

  .head-end {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;

    &__controls {
      display: flex;
      grid-gap: 20px;
      gap: 20px;
      align-items: center;
    }

    &__loss {
      display: flex;
      align-items: center;
      position: relative;
      grid-gap: 8px;
      gap: 8px;
    }
  }
`;

const Theader = styled.div`
  .h-title-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 10px;
    gap: 10px;
  }

  .meta {
    display: grid;
    grid-template-columns: auto auto auto 1fr;
    grid-gap: 20px;
    &__loss {
      justify-content: flex-end;
    }
  }
  .sub-header {
    display: flex;
    align-items: center;
    grid-gap: 8px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .meta {
      grid-gap: 4px;
      grid-row-gap: 5px;
      &__loss {
        grid-row: 2;
        justify-content: flex-start;
      }
    }
  }
`;

interface HeadProps {
  isLogarithmic: boolean;
  scenario: Scenario;
  expectedLossComponent: React.ReactNode;
}

export const Head: React.FC<HeadProps> = ({ isLogarithmic, scenario, expectedLossComponent }) => {
  const { scenarioId, assessmentId } = useParams<{ scenarioId: string; assessmentId: string }>();
  const isLinkControlsToScenarioModalOpen = useStateSelector(({ app }) => app.modals.isLinkControlsToScenarioModalOpen);
  const { isOpen, openModal, closeModal } = useScenario();
  const [anchorEl, setAnchorEl] = useState<React.MouseEvent['currentTarget'] | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRequestAssistanceModalOpen, setIsRequestAssistanceModalOpen] = useState(false);
  const [isVariablesModalOpen, setIsVariablesModalOpen] = useState(false);
  const [isExportPdfModalOpen, setIsExportPdfModalOpen] = useState(false);
  const { version, VHeader } = useVersion();
  const { userFeatures } = useAuth();

  const { setLinkControlsToScenarioModal } = useActionsApp();

  const hasControls = scenario.implementationPlans?.length > 0;

  const navigate = useNavigate();

  const [remove] = useMutation(deleteScenario, {
    onSuccess: () => {
      mpEvent(MPEvents.ScenarioDelete, {
        id: scenario.id,
        tags: ['SCENARIO'],
      });
      queryCache.invalidateQueries([QKeys.Assessment, { assessmentId }]);
      handleClose();
    },
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMenuClick = (e: React.MouseEvent<HTMLLIElement>, cb: () => void) => {
    e.stopPropagation();
    handleClose();
    cb();
  };

  const handleOnDeleteClick = () => {
    remove({ id: scenarioId as string }).then(() => navigate(`/risk-assessments/${assessmentId}`));
  };

  const incompleteState = getIncompleteState(scenario);

  const projectedRiskTooltipText =
    scenario.legacy_version || version
      ? 'Sandbox unavailable for this version.'
      : hasControls && incompleteState !== 'TECH'
        ? ''
        : incompleteState !== 'TECH'
          ? 'Linked controls are required for sandbox.'
          : 'Please adjust the scenario to contain valid calculation data.';

  return (
    <>
      {isLinkControlsToScenarioModalOpen && (
        <LinkControlsToScenarioModal
          onClose={() => setLinkControlsToScenarioModal(null)}
          scenarioId={scenario.id}
          initialActiveTab={
            typeof isLinkControlsToScenarioModalOpen === 'object'
              ? isLinkControlsToScenarioModalOpen.activeTab
              : undefined
          }
          subTitle={{
            title: 'SCENARIO',
            value: scenario.name,
          }}
        />
      )}
      {isDeleteModalOpen && (
        <ConfirmDeleteModal onConfirm={handleOnDeleteClick} onClose={() => setIsDeleteModalOpen(false)} />
      )}
      {isOpen && <ScenarioModal onClose={closeModal} />}
      {isRequestAssistanceModalOpen && (
        <RequestAssistanceModal
          onClose={() => setIsRequestAssistanceModalOpen(false)}
          assessmentId={assessmentId as string}
          scenarioId={scenarioId}
        />
      )}
      {isVariablesModalOpen && (
        <VariablesWorkspaceModal
          onClose={() => setIsVariablesModalOpen(false)}
          scope={{ type: 'assessment', id: scenario.assessment_id }}
          readOnly={!!version}
        />
      )}
      {isExportPdfModalOpen && (
        <PdfExportModalScenario
          scenario={scenario}
          onClose={() => setIsExportPdfModalOpen(false)}
          isLogarithmic={isLogarithmic}
        />
      )}
      <Div>
        <TitleWithBack gradient title="SCENARIO" />
        <CommonMenu
          configuredList={[
            !version && {
              type: 'EDIT',
              exe: (e) => onMenuClick(e, () => openModal(scenarioId)),
            },
            !version && {
              type: 'DELETE',
              exe: (e) => onMenuClick(e, () => setIsDeleteModalOpen(true)),
            },
            !version && {
              type: 'VARIABLES',
              exe: (e) => onMenuClick(e, () => setIsVariablesModalOpen(true)),
              label: 'Assessment Variables',
            },
            !version && {
              type: 'PDF',
              exe: (e) => onMenuClick(e, () => setIsExportPdfModalOpen(true)),
            },
            {
              type: 'ASSISTANCE',
              exe: (e) => onMenuClick(e, () => setIsRequestAssistanceModalOpen(true)),
            },
          ]}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        />
        <div className="head-end">
          <div className="head-end__controls">
            <Tooltip
              dataId={`op-${scenario.id}`}
              dataHtml={projectedRiskTooltipText}
              place="top"
              data-tooltip-id={`op-${scenario.id}`}
            >
              <ProjectedRisk
                disabled={!hasControls || incompleteState === 'TECH' || scenario.legacy_version || !!version}
                data={{ scenarioId: scenarioId as string }}
              />
            </Tooltip>
            {userFeatures.app.ai.suggestControls && <AISuggestionsHandle suggesterKeys={[scenario.id]} />}
            <Settings
              active={!!anchorEl}
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
              }}
            />
          </div>
        </div>
      </Div>
      <Spacer $px={30} />
      <Theader>
        <div className="h-title-container">
          <Poppins px={32} weight={600} data-cy="scenario-name">
            {scenario.name}
          </Poppins>

          <div>
            <IncompleteStateMarker scenario={scenario} place="right" />
          </div>
        </div>
        {userFeatures.app.ai.suggestControls && <AiSuggest scenario={scenario} wrapperStyle={{ marginTop: 12 }} />}
        {VHeader && (
          <>
            <Spacer $px={5} />
            {VHeader}
          </>
        )}
        <Spacer $px={30} />
        <div className="meta">
          <div className="sub-header">
            <DataTitle $noMargin>RISK OWNER:</DataTitle>
            {scenario.risk_owner?.text ? (
              <DataValue data-cy="scenario-riskowner">{scenario.risk_owner.text}</DataValue>
            ) : (
              <NoData />
            )}
          </div>

          <div className="sub-header">
            <DataTitle $noMargin>UPDATED:</DataTitle>
            <DataValue>
              <TLLink date={scenario.updated_at}>{formatDate(scenario.updated_at, { formatType: 'datetime' })}</TLLink>
            </DataValue>
          </div>

          <div className="sub-header">
            <DataTitle $noMargin>EDITED:</DataTitle>
            <DataValue>
              <TLLink date={scenario.edited_at}>{formatDate(scenario.edited_at, { formatType: 'datetime' })}</TLLink>
            </DataValue>
          </div>

          <div className="sub-header meta__loss">
            <DataTitle $noMargin>EXPECTED LOSS:</DataTitle>
            {expectedLossComponent}
          </div>
        </div>
      </Theader>
    </>
  );
};
