import React, { memo, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { QKeys } from 'src/api/types';
import { useColumns } from './useColumns';
import GenericTable, {
  genEmtyRowTextComponent,
  GTRowEmpty,
  TableSheetControlLibrary,
  TableSheetRmp,
} from 'src/components/GenericTable';
import { Poppins, Spacer, TableSkeleton } from 'src/common';
import { isEqual } from 'lodash';
import Button from 'src/components/form/Button';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { TableIds } from 'src/api/types/misc';
import { AddRmpModal } from '../../../AddRmpModal';
import CheckBox from 'src/components/form/CheckBox';
import styled from 'styled-components';
import { getRankedControls } from 'src/api/controllibraries';
import { getControls } from 'src/api/controls';
import { getScenarioControls } from 'src/api/plan';

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: -20px;

  .btn {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    cursor: pointer;
    user-select: none;
  }
`;

interface FromSuggestedProps {
  setChecked: (a: string[]) => void;
  isLoading: boolean;
  onSubmit: () => void;
  scenarioId: string;
}

export const FromSuggested: React.FC<FromSuggestedProps> = memo(({ setChecked, isLoading, onSubmit, scenarioId }) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [hideInvalidSuggestions, setHideInvalidSuggestions] = useState(false);

  const { data: controls = [], isLoading: isControlsLoading } = useQuery(QKeys.RankedControls, () =>
    getRankedControls(scenarioId),
  );
  const { data: rmpControls = [] } = useQuery(QKeys.Controls, getControls);

  const { data: scenarioControls = [] } = useQuery(QKeys.ScenarioControls, () => getScenarioControls(scenarioId));

  const filteredControls = useMemo(() => {
    return controls.filter((el) => {
      if (hideInvalidSuggestions) {
        return el.user_vote !== -1;
      }
      return true;
    });
  }, [hideInvalidSuggestions, controls]);

  const { columns, GTColumnSelectAnchorExported, checked } = useColumns({
    data: controls,
    rmpControls,
    scenarioControls,
  });

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  return (
    <>
      {isAddModalOpen && <AddRmpModal onClose={() => setIsAddModalOpen(false)} />}
      {!isControlsLoading ? (
        <>
          <CheckboxContainer onClick={() => setHideInvalidSuggestions(!hideInvalidSuggestions)}>
            <div className="btn">
              <Poppins color="cflowerBlue" px={14} weight={500}>
                Hide suggestions marked as bad
              </Poppins>
              <CheckBox small isChecked={hideInvalidSuggestions} />
            </div>
          </CheckboxContainer>
          <Spacer $px={10} />
          <GenericTable
            tableId={TableIds.scenarioLinkSuggestedControl}
            data={filteredControls}
            columns={columns}
            GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
            searchable
            expandContent={(data) => {
              // @ts-ignore
              const isRmp = !!data.id;
              // @ts-ignore
              return isRmp ? <TableSheetRmp data={data} /> : <TableSheetControlLibrary data={data} />;
            }}
            itemHeight={50}
            rowDisabled={(row) => {
              const isLinked = scenarioControls.some(
                // @ts-ignore
                (el) => el.control_data.id === row.id || el.control_data.controlLibrary_Id === row.controlLibrary_Id,
              );
              return isLinked;
            }}
            emptyRow={
              <GTRowEmpty
                itemHeight={50}
                text={genEmtyRowTextComponent({
                  startText: 'No suggestions found',
                  endText: '',
                })}
              />
            }
          />

          <Spacer $px={30} />
          <Button
            disabled={isLoading || !checked.length}
            onClick={() => {
              onSubmit();
              mpEvent(MPEvents.ButtonClick, {
                button: 'Add',
                modal: 'Link controls to scenario modal',
                tags: ['SCENARIO', 'RMP', 'AI'],
              });
            }}
            primary
            css="width: 100%;"
            data-cy="submit-btn"
          >
            + ADD
          </Button>
        </>
      ) : (
        <TableSkeleton />
      )}
    </>
  );
}, isEqual);
