import React from 'react';
import { DataTitle } from 'src/common';
import { GenericSelect } from 'src/components/select/GenericSelect';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { Modifiers, ProjectedScenario } from 'src/api/types';
import { Limit } from './comps';
import { CloseIcon } from 'src/components/Modifier/comps';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const Div = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 280px 1fr;

  .slot {
    &__top {
      margin-bottom: 4px;
      height: 20px;
      user-select: none;
    }
    &__content {
      position: relative;
      min-height: 52px;
      display: grid;
      &--action {
        grid-template-columns: repeat(auto-fit, minmax(30px, auto));
        align-items: center;
      }
    }
  }
`;

interface ActionProps {
  idx: number;
  projectedScenario: ProjectedScenario;
  onRemove: () => void;
  options: { label: string; value: string }[];
  disabled?: boolean;
  noResult?: boolean;
}

const Action: React.FC<ActionProps> = ({ idx, projectedScenario, onRemove, options, disabled, noResult }) => {
  const { values, setFieldValue } = useFormikContext<Modifiers>();

  return (
    <Div>
      <div className="slot">
        <div className="slot__top">
          <DataTitle
            $noMargin
            css={`
              margin-left: ${!disabled && !noResult ? 30 : 0}px;
            `}
          >
            ACTION
          </DataTitle>
        </div>
        <div className="slot__content slot__content--action">
          {!disabled && (
            <CloseIcon
              onClick={() => {
                onRemove();
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Modifier:Remove',
                  tags: ['MODIFIERS'],
                });
              }}
            />
          )}
          <GenericSelect
            white
            options={options}
            onChange={(o) => {
              setFieldValue(`transfer[${idx}].type`, o.value);
              setFieldValue(`transfer[${idx}].data`, { excess: 0, tpc: 0 });
            }}
            value={values.transfer[idx].type}
            disabled={disabled}
          />
        </div>
      </div>
      {values.transfer[idx].type === 'TRANSFER_LIMIT' && (
        <Limit idx={idx} projectedScenario={projectedScenario} disabled={disabled} noResult={noResult} />
      )}
    </Div>
  );
};

export default Action;
