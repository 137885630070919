import React, { useState } from 'react';
import { OrganisationWorkspace } from 'src/api/types';
import { GradientText, Poppins, Spacer } from 'src/common';
import { TabWrap } from 'src/components/TabsMenu';
import WorkspaceVariablesForm from 'src/components/formComposed/WorkspaceVariablesForm';
import CheckBox from 'src/components/form/CheckBox';
import styled from 'styled-components';

const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  .check {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    cursor: pointer;
    user-select: none;
  }
`;

interface WorkspaceVariablesProps {
  workspace: OrganisationWorkspace;
}

export const WorkspaceVariables: React.FC<WorkspaceVariablesProps> = () => {
  const [hideEmptyVariables, setHideEmptyVariables] = useState(true);

  return (
    <TabWrap>
      <Div>
        <GradientText>Variables</GradientText>
        <div
          className="check"
          onClick={() => setHideEmptyVariables(!hideEmptyVariables)}
          data-cy="hide-empty-variables"
        >
          <Poppins color="cflowerBlue" px={14} weight={500}>
            Hide empty variables
          </Poppins>
          <CheckBox small isChecked={hideEmptyVariables} />
        </div>
      </Div>
      <Spacer $px={32} />
      <WorkspaceVariablesForm
        onClose={() => {}}
        scope={{ type: 'workspace' }}
        hideEmptyVariablesControlled={hideEmptyVariables}
      />
    </TabWrap>
  );
};
