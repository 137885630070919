import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { Scenario, riskConsequencesNames, riskEventsNames, riskSourcesNames } from 'src/api/types';
import { GTColumnSelectAnchor, GTTdm } from 'src/components/GenericTable';
import { formatDate, numeralFormat } from 'src/utils/misc';
import { filter } from 'lodash';
import { Link } from 'react-router-dom';
import { GTInternalIds } from 'src/components/GenericTable/types';
import { queryCache, useMutation } from 'react-query';
import { unlinkIncident } from 'src/api/incidents';
import { IncompleteStateMarker, TextOverflowTooltip } from 'src/common';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { MPEvents } from 'src/utils/mixpanel/types';
import useVersion from 'src/hooks/useVersion';
import { encodeQueryParams } from 'src/api/client';
import { TableIds } from 'src/api/types/misc';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TLLink } from '../../../DashboardPage/comps';

type UseColumns = (a: { data?: Scenario[]; incidentId: string }) => {
  columns: ColumnDef<Scenario>[];
  GTColumnSelectAnchorExported: JSX.Element;
};

const defaultColumns = ['name', 'source', 'event', 'consequence', 'expected_loss', 'updated_at'];
export const useScenarioColumns: UseColumns = ({ data = [], incidentId }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(
    `${TableIds.incidentSecnarios}-columns`,
    defaultColumns,
  );
  const { version, assessmentId } = useVersion();

  const [unlink] = useMutation(unlinkIncident, {
    onSuccess: () => {
      queryCache.invalidateQueries();
    },
  });

  const columns = useMemo(() => {
    const internalIds = [GTInternalIds.tdm];

    const list: ColumnDef<Scenario>[] = [
      {
        id: 'assessment_name',
        header: 'Assessment',
        accessorFn: (row) => row.assessment_name,
        cell: (info) => info.getValue(),
        sortUndefined: 'last',
        sortingFn: sortingFns.text,
        maxSize: 200,
      },
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => row.name,
        cell: ({ row }) => (
          <TextOverflowTooltip>
            <Link
              className="table-link"
              to={`/risk-assessments/${row.original.assessment_id}/scenario/${row.original.id}${
                version ? encodeQueryParams({ version, assessmentId }) : ''
              }`}
              onClick={(e) => {
                e.stopPropagation();
                mpEvent(MPEvents.Link, {
                  source: {
                    value: ['Incident'],
                    params: {
                      id: incidentId,
                    },
                  },
                  destination: { value: ['Scenario'], params: { id: row.original.id } },
                  tags: ['SCENARIO', 'INCIDENT'],
                });
              }}
            >
              {row.original.name}
            </Link>
          </TextOverflowTooltip>
        ),
        sortUndefined: 'last',
        sortingFn: sortingFns.text,
        maxSize: 300,
      },
      {
        id: 'source',
        header: 'Source',
        accessorFn: (row) => riskSourcesNames[row.source] || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'event',
        header: 'Event',
        accessorFn: (row) => riskEventsNames[row.event] || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'consequence',
        header: 'Consequence',
        accessorFn: (row) => riskConsequencesNames[row.consequence] || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'risk_owner',
        header: 'Risk Owner',
        accessorFn: (row) => row.risk_owner?.text || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },
      {
        id: 'expected_loss',
        header: 'Expected Loss',
        accessorFn: (row) => row.expected_loss || null,
        cell: (info) => numeralFormat(info.getValue() as number),
      },
      {
        id: '_control_count',
        header: 'Linked Controls',
        accessorFn: (row) => row.implementationPlans?.length || 0,
        cell: (info) => info.getValue(),
      },
      {
        id: 'updated_at',
        header: 'Last Updated',
        accessorFn: (row) => row.updated_at || null,
        cell: ({ row }) => {
          const date = row.original.updated_at;
          if (!date) return null;
          return <TLLink date={date}>{formatDate(date, { formatType: 'datetime' })}</TLLink>;
        },
      },
      {
        id: 'created_at',
        header: 'Created Date',
        accessorFn: (row) => row.created_at || null,
        cell: ({ row }) => {
          const date = row.original.created_at;
          if (!date) return null;
          return <TLLink date={date}>{formatDate(date, { formatType: 'datetime' })}</TLLink>;
        },
      },
      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: ({ row }) => {
          const onUnlinkScenarioClick = !version
            ? () =>
                unlink({
                  incidentId,
                  scenarioId: row.original.id,
                })
            : undefined;
          return (
            <div css="display: flex;grid-gap: 8px">
              <GTTdm
                extraComponentLeft={<IncompleteStateMarker scenario={row.original} />}
                onUnlinkScenarioClick={onUnlinkScenarioClick}
              />
            </div>
          );
        },
        size: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns, version, assessmentId]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'assessment_name', title: 'Assessment' },
        { key: 'name', title: 'Name' },
        { key: 'source', title: 'Source' },
        { key: 'event', title: 'Event' },
        { key: 'consequence', title: 'Consequence' },
        {
          key: 'risk_owner',
          title: 'Risk Owner',
        },
        {
          key: 'expected_loss',
          title: 'Expected Loss',
        },
        {
          key: '_control_count',
          title: 'Linked Controls',
        },
        {
          key: 'updated_at',
          title: 'Last Updated',
        },
        {
          key: 'created_at',
          title: 'Created Date',
        },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return { columns, GTColumnSelectAnchorExported };
};
