import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React from 'react';
import { Link } from 'react-router-dom';
import { Scenario, riskConsequencesNames, riskEventsNames, riskSourcesNames } from 'src/api/types';
import { GTInternalIds } from 'src/components/GenericTable/types';
import { formatDate, numeralFormat } from 'src/utils/misc';
import GenericTable, { GTColumnSelectAnchor, GTTdm } from 'src/components/GenericTable';
import { filter } from 'lodash';
import { IncompleteStateMarker, TextOverflowTooltip } from 'src/common';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TableIds } from 'src/api/types/misc';
import { TLLink } from 'src/pages/DashboardPage/comps';

interface ControlTableExpandContentProps {
  data: Scenario[];
}

const defaultColumns = ['name', 'source', 'event', 'consequence', 'expected_loss', 'updated_at', 'edited_at'];
const internalIds = [GTInternalIds.expander, GTInternalIds.tdm];

export const ControlTableExpandContent: React.FC<ControlTableExpandContentProps> = ({ data }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(`${TableIds.rmpScenarios}-columns`, defaultColumns);

  const columns: ColumnDef<Scenario>[] = [
    {
      id: 'assessment_name',
      header: 'Assessment',
      accessorFn: (row) => row.assessment_name,
      cell: (info) => info.getValue(),
      sortUndefined: 'last',
      sortingFn: sortingFns.text,
      maxSize: 300,
    },
    {
      id: 'ux_id',
      header: 'Id',
      accessorFn: (row) => row.id || null,
      cell: (info) => info.getValue(),
    },
    {
      id: 'name',
      header: 'Scenario',
      accessorFn: (row) => row.name,
      cell: (info) => (
        <TextOverflowTooltip>
          <Link
            className="table-link"
            to={`/risk-assessments/${info.row.original.assessment_id}/scenario/${info.row.original.id}`}
            onClick={(e) => e.stopPropagation()}
          >
            {info.getValue() as string}
          </Link>
        </TextOverflowTooltip>
      ),
      sortUndefined: 'last',
      sortingFn: sortingFns.text,
      maxSize: 300,
    },
    {
      id: 'source',
      header: 'Source',
      accessorFn: (row) => riskSourcesNames[row.source] || null,
      cell: (info) => info.getValue(),
    },
    {
      id: 'event',
      header: 'Event',
      accessorFn: (row) => riskEventsNames[row.event] || null,
      cell: (info) => info.getValue(),
    },
    {
      id: 'consequence',
      header: 'Consequence',
      accessorFn: (row) => riskConsequencesNames[row.consequence] || null,
      cell: (info) => info.getValue(),
    },
    {
      id: 'risk_owner',
      header: 'Risk Owner',
      accessorFn: (row) => row.risk_owner?.text || null,
      cell: (info) => info.getValue(),
      sortingFn: sortingFns.text,
    },
    {
      id: 'expected_loss',
      header: 'Expected Loss',
      accessorFn: (row) => row.expected_loss || null,
      cell: (info) => numeralFormat(info.getValue() as number),
    },
    {
      id: '_control_count',
      header: 'Linked Controls',
      accessorFn: (row) => row.implementationPlans?.length || null,
      cell: (info) => info.getValue(),
    },
    {
      id: 'updated_at',
      header: 'Last Updated',
      accessorFn: (row) => row.updated_at || null,
      cell: ({ row }) => {
        const date = row.original.updated_at;
        if (!date) return null;
        return <TLLink date={date}>{formatDate(date, { formatType: 'datetime' })}</TLLink>;
      },
    },
    {
      id: 'edited_at',
      header: 'Last Edited Date',
      accessorFn: (row) => row.edited_at || null,
      cell: ({ row }) => (
        <TLLink date={row.original.edited_at}>{formatDate(row.original.edited_at, { formatType: 'datetime' })}</TLLink>
      ),
    },
    {
      id: 'created_at',
      header: 'Created Date',
      accessorFn: (row) => row.created_at || null,
      cell: ({ row }) => {
        const date = row.original.created_at;
        if (!date) return null;
        return <TLLink date={date}>{formatDate(date, { formatType: 'datetime' })}</TLLink>;
      },
    },
    {
      id: GTInternalIds.tdm,
      header: () => null,
      cell: ({ row }) => {
        return (
          <div css="display: flex;grid-gap: 8px">
            <GTTdm extraComponentLeft={<IncompleteStateMarker scenario={row.original} />} />
          </div>
        );
      },
      size: 1,
    },
  ];

  return (
    <GenericTable
      tableId={TableIds.rmpScenarios}
      data={data}
      columns={filter(columns, (el) => [...internalIds, ...selectedColumns].includes(el.id as string))}
      GTColumnSelectAnchorExported={
        <GTColumnSelectAnchor
          options={[
            { key: 'ux_id', title: 'Id' },
            { key: 'assessment_name', title: 'Assessment' },
            { key: 'name', title: 'Scenario' },
            { key: 'source', title: 'Source' },
            { key: 'event', title: 'Event' },
            { key: 'consequence', title: 'Consequence' },
            {
              key: 'risk_owner',
              title: 'Risk Owner',
            },
            {
              key: 'expected_loss',
              title: 'Expected Loss',
            },
            {
              key: '_control_count',
              title: 'Linked Controls',
            },
            {
              key: 'updated_at',
              title: 'Last Updated',
            },
            {
              key: 'edited_at',
              title: 'Last Edited Date',
            },
            {
              key: 'created_at',
              title: 'Created Date',
            },
          ]}
          defaultOptions={defaultColumns}
          value={selectedColumns}
          onChange={setSelectedColumns}
        />
      }
      darkMode
    />
  );
};
