import { mergeWith, isObject } from 'lodash';
import { FiltersType } from 'src/api/types/dashboard';
import _ from 'lodash';

const makeKey = (type: string) => `timeline-filters:${type}`;

type SaveToLocalStorage = (params: { filters: FiltersType; key: string }) => void;

export const saveToLocalStorage: SaveToLocalStorage = ({ filters, key }) => {
  localStorage.setItem(makeKey(key), JSON.stringify({ filters: filters }));
};

type LoadFromLocalStorage = (params: { freshFilters: FiltersType; key: string }) => FiltersType | null;

const pruneKeys = (target: any, reference: any) => {
  if (!isObject(target) || !isObject(reference)) return target;

  for (const key of Object.keys(target)) {
    if (!(key in reference)) {
      // @ts-ignore
      delete target[key];
    } else {
      // @ts-ignore
      pruneKeys(target[key], reference[key]);
    }
  }
  return target;
};

const forceNewEnabled = (target: any, loaded: any, fresh: any) => {
  if (!_.isObject(target) || !_.isObject(loaded) || !_.isObject(fresh)) return;

  _.forOwn(fresh, (value, key) => {
    if (!_.has(loaded, key) && _.get(value, 'tag') && _.isBoolean(_.get(value, 'enabled'))) {
      _.set(target, `${key}.enabled`, true);
      // @ts-ignore
    } else if (_.isObject(target[key])) {
      // @ts-ignore
      forceNewEnabled(target[key], _.get(loaded, key, {}), value);
    }
  });
};

export const loadFromLocalStorage: LoadFromLocalStorage = ({ key, freshFilters }) => {
  const data = localStorage.getItem(makeKey(key));
  if (!data) return null;

  let loaded: FiltersType;
  try {
    loaded = JSON.parse(data).filters;
  } catch {
    return null;
  }

  let merged = mergeWith({}, freshFilters, loaded, (objVal, srcVal) => {});
  pruneKeys(merged, freshFilters);
  forceNewEnabled(merged, loaded, freshFilters);

  return merged;
};

type SetSectionOpenState = (params: { id: string; isOpen: boolean; storageKey?: string }) => void;
export const setSectionOpenState: SetSectionOpenState = ({ id, isOpen, storageKey = 'section_open_states' }) => {
  try {
    const items = _.attempt(() => JSON.parse(window.localStorage.getItem(storageKey) || '{}'));
    const updatedItems = _.isObject(items) ? { ...items, [id]: isOpen } : { [id]: isOpen };
    window.localStorage.setItem(storageKey, JSON.stringify(updatedItems));
  } catch {
    window.localStorage.setItem(storageKey, JSON.stringify({ [id]: isOpen }));
  }
};

type GetSectionOpenState = (params: { id: string; storageKey?: string }) => boolean;
export const getSectionOpenState: GetSectionOpenState = ({ id, storageKey = 'section_open_states' }) => {
  try {
    const items = _.attempt(() => JSON.parse(window.localStorage.getItem(storageKey) || '{}'));
    return _.isObject(items) ? _.get(items, id, false) : false;
  } catch {
    window.localStorage.setItem(storageKey, '{}');
    return false;
  }
};

type ClearSectionOpenStates = (params?: { storageKey?: string }) => void;
export const clearSectionOpenStates: ClearSectionOpenStates = (params) => {
  const { storageKey = 'section_open_states' } = params || {};
  window.localStorage.removeItem(storageKey);
};
