import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styled, { keyframes } from 'styled-components';
import { Spacer } from './Spacer';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const CSkeleton = styled(Skeleton)`
  --base-color: ${({ theme }) => theme.colors.skeletonBase};
  --highlight-color: ${({ theme }) => theme.colors.skeletonHighlight};
  cursor: default;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  opacity: 0;
  animation: ${fadeIn} 0.5s forwards 0.3s;
`;

export const AssessmentCardSkeleton = styled(CSkeleton)`
  height: 252px;
`;

export const ButtonSkeleton = styled(CSkeleton)`
  height: 48px;
  width: 150px;
`;

export const TableSkeleton = () => (
  <div css="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap:3px; height: 170px">
    <CSkeleton height={48} count={3} />
    <CSkeleton height={48} count={3} />
    <CSkeleton height={48} count={3} />
  </div>
);
export const TimelineSkeleton = () => (
  <div css="max-width: 500px">
    <CSkeleton height={20} count={10} />
  </div>
);

export const ModifierModalSkeleton = () => (
  <>
    <CSkeleton height={30} />
    <Spacer $px={20} />
    <CSkeleton height={200} />
    <Spacer $px={20} />
    <div css="display: flex;justify-content:flex-end; grid-gap: 10px;">
      <ButtonSkeleton />
      <ButtonSkeleton />
    </div>
    <Spacer $px={20} />
    <TableSkeleton />
  </>
);

export const UserProfileModalSkeleton = () => (
  <>
    <CSkeleton height={30} width={400} />
    <Spacer $px={20} />
    <CSkeleton height={70} width={70} circle />
    <Spacer $px={20} />
    <div css="display: grid; grid-template-columns: 1fr 10px 1fr; grid-gap:10px">
      <CSkeleton height={48} count={2} />
      <Spacer $px={1} />
      <CSkeleton height={48} count={2} />
    </div>
    <Spacer $px={20} />
    <ButtonSkeleton />
    <Spacer $px={20} />
  </>
);

export const ScenarioModalSkeleton = () => (
  <>
    <CSkeleton height={48} />
    <Spacer $px={20} />
    <div css="display: grid; grid-template-columns: 1fr 10px 1fr; grid-gap:10px">
      <CSkeleton height={48} />
      <Spacer $px={1} />
      <CSkeleton height={48} />
    </div>
    <Spacer $px={20} />
    <CSkeleton height={150} />
    <Spacer $px={20} />
    <CSkeleton height={48} />
    <Spacer $px={20} />
    <CSkeleton height={48} />
  </>
);

export const PageChartSkeleton = () => (
  <>
    <CSkeleton height={48} />
    <Spacer $px={20} />
    <CSkeleton height={250} />
    <Spacer $px={20} />
    <TableSkeleton />
  </>
);
export const PageFrameworkLibSkeleton = () => (
  <>
    <CSkeleton height={48} />
    <Spacer $px={20} />
    <CSkeleton height={48} />
    <Spacer $px={20} />
    <CSkeleton height={48} />
    <Spacer $px={20} />
    <TableSkeleton />
  </>
);
export const PageControlSkeleton: React.FC<{ noTable?: boolean }> = ({ noTable }) => {
  return (
    <>
      <CSkeleton height={48} />
      <Spacer $px={20} />
      <CSkeleton height={20} />
      <Spacer $px={10} />
      <CSkeleton height={20} />
      <Spacer $px={10} />
      <CSkeleton height={20} />
      {!noTable && (
        <>
          <Spacer $px={20} />
          <TableSkeleton />
        </>
      )}
    </>
  );
};

export const PageOrgSkeleton = () => (
  <>
    <div css="display: flex; align-items:center; grid-gap: 30px">
      <CSkeleton height={45} width={45} circle />
      <CSkeleton height={30} width={300} />
    </div>
    <Spacer $px={20} />
    <CSkeleton height={30} width={400} />
    <Spacer $px={20} />
    <div css="display: grid; grid-template-columns: 1fr 10px 1fr; grid-gap:10px">
      <CSkeleton height={48} count={3} />
      <Spacer $px={1} />
      <CSkeleton height={48} count={3} />
    </div>
    <Spacer $px={20} />
    <ButtonSkeleton />
    <Spacer $px={20} />
  </>
);
