import { Page, Text, Link, View, Image } from '@react-pdf/renderer';
import React from 'react';
import { Incident, incidentTypeNames, Scenario } from 'src/api/types';
import { Header } from './Header';
import { pdfStyles } from '../pdfStyles';
import { Footer } from './Footer';
import { NoData } from './NoData';
import { TitleLink } from './TitleLink';
import { ScenariosTable } from './ScenariosTable';
import { extLinkImg } from '../imgData';
import { ColorSet } from 'src/redux/reducers/theme';
import { formatDate } from 'src/utils/misc';

interface IncidentPageProps {
  incident: Incident;
  onRender: (pageNumber: number) => void;
  endComponent?: React.ReactNode;
  footerComponent?: React.ReactNode;
  titleSmall?: boolean;
  colors: ColorSet;
}

export const IncidentPage: React.FC<IncidentPageProps> = ({
  incident,
  onRender,
  endComponent,
  footerComponent,
  titleSmall,
  colors,
}) => {
  return (
    <Page size={'A4'} style={pdfStyles.page}>
      <Header endComponent={endComponent} colors={colors} />

      {/* @ts-ignore */}
      <View render={(data) => onRender(data.pageNumber)} />
      <View style={pdfStyles.section}>
        <TitleLink
          title={incident.name}
          intLinkId={incident.name.toLowerCase()}
          extLink={`incidents/${incident.id}`}
          colors={colors}
        />

        <View style={pdfStyles.spacer8} />
        <View style={pdfStyles.spacer4} />

        <View style={{ flexDirection: 'row' }}>
          <View style={{ width: 220 }}>
            <Text style={pdfStyles.label}>REFERENCE ID</Text>
            <View style={pdfStyles.spacer1} />
            {incident.refId ? (
              incident.source ? (
                <Link src={incident.source} style={{ flexDirection: 'row', textDecoration: 'none' }}>
                  <Text style={[pdfStyles.value, { textDecoration: 'underline' }]}>{incident.refId}</Text>
                  <Image src={extLinkImg} style={{ marginLeft: 4, width: 8, height: 8, marginTop: 3 }} />
                </Link>
              ) : (
                <Text style={pdfStyles.value}>{incident.refId}</Text>
              )
            ) : (
              <NoData colors={colors} />
            )}
          </View>
        </View>

        <View style={pdfStyles.spacer8} />
        <View style={{ flexDirection: 'row' }}>
          <View style={{ width: 220 }}>
            <Text style={pdfStyles.label}>OWNER</Text>
            <View style={pdfStyles.spacer1} />
            {incident.owner?.text ? (
              <Text style={pdfStyles.value}>{incident.owner.text}</Text>
            ) : (
              <NoData colors={colors} />
            )}
          </View>
          <View>
            <Text style={pdfStyles.label}>DATE OF INCIDENT</Text>
            <View style={pdfStyles.spacer1} />
            {incident.date ? (
              <Text style={pdfStyles.value}>{formatDate(incident.date, { emptyValue: '-' })}</Text>
            ) : (
              <NoData colors={colors} />
            )}
          </View>
        </View>

        <View style={pdfStyles.spacer8} />
        <View style={{ width: 220 }}>
          <Text style={pdfStyles.label}>IMPACT</Text>
          <View style={pdfStyles.spacer1} />
          {incident.impact ? <Text style={pdfStyles.value}>{incident.impact}</Text> : <NoData colors={colors} />}
        </View>

        <View style={pdfStyles.spacer8} />
        <Text style={pdfStyles.label}>DESCRIPTION</Text>
        <View style={pdfStyles.spacer1} />
        {incident.description ? (
          <Text style={pdfStyles.value}>{incident.description}</Text>
        ) : (
          <NoData colors={colors} />
        )}

        <View style={pdfStyles.spacer8} />
        <View style={{ flexDirection: 'row' }}>
          <View style={{ width: 220 }}>
            <Text style={pdfStyles.label}>IMPACT - LOWER</Text>
            <View style={pdfStyles.spacer1} />
            {incident.financialImpactLower ? (
              <Text style={pdfStyles.value}>{incident.financialImpactLower}</Text>
            ) : (
              <NoData colors={colors} />
            )}
          </View>
          <View>
            <Text style={pdfStyles.label}>IMPACT - UPPER</Text>
            <View style={pdfStyles.spacer1} />
            {incident.financialImpactUpper ? (
              <Text style={pdfStyles.value}>{incident.financialImpactUpper}</Text>
            ) : (
              <NoData colors={colors} />
            )}
          </View>
        </View>
        <View style={pdfStyles.spacer8} />
        <View style={{ width: 220 }}>
          <Text style={pdfStyles.label}>TYPE</Text>
          <View style={pdfStyles.spacer1} />
          {incident.type ? (
            <Text style={pdfStyles.value}>{incidentTypeNames[incident.type]}</Text>
          ) : (
            <NoData colors={colors} />
          )}
        </View>

        <View style={pdfStyles.spacer8} />
        <View style={pdfStyles.spacer8} />
        {incident.scenarios?.length > 0 ? <ScenariosTable data={incident.scenarios} colors={colors} /> : null}
      </View>
      <Footer footerComponent={footerComponent} colors={colors} />
    </Page>
  );
};
