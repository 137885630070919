import React from 'react';
import { useStateSelector } from 'src/redux';

interface IconCopyProps {
  disabled?: boolean;
}

export const IconCopy: React.FC<IconCopyProps> = ({ disabled }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);

  const disabledColor = colors.cflowerBlue;

  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.7698 13.3657H10.2955V14.5886C10.2955 14.9629 10.1468 15.3219 9.88208 15.5866C9.61739 15.8513 9.25839 16 8.88405 16H1.42119C1.04686 16 0.687858 15.8513 0.423164 15.5866C0.158469 15.3219 0.00976563 14.9629 0.00976562 14.5886V4.04571C0.00976563 3.67138 0.158469 3.31238 0.423164 3.04768C0.687858 2.78299 1.04686 2.63429 1.42119 2.63429H3.08405V1.6C3.08405 1.38988 3.12544 1.18183 3.20584 0.987706C3.28625 0.793585 3.40411 0.617202 3.55268 0.468628C3.70125 0.320055 3.87764 0.202199 4.07176 0.121792C4.26588 0.0413837 4.47394 0 4.68405 0H11.7698C11.9799 0 12.1879 0.0413837 12.3821 0.121792C12.5762 0.202199 12.7526 0.320055 12.9011 0.468628C13.0497 0.617202 13.1676 0.793585 13.248 0.987706C13.3284 1.18183 13.3698 1.38988 13.3698 1.6V11.7657C13.3698 12.1901 13.2012 12.597 12.9011 12.8971C12.6011 13.1971 12.1941 13.3657 11.7698 13.3657ZM1.42119 3.70286C1.32973 3.70284 1.24192 3.73878 1.17672 3.80292C1.11151 3.86706 1.07412 3.95426 1.07262 4.04571V14.5886C1.07412 14.68 1.11151 14.7672 1.17672 14.8314C1.24192 14.8955 1.32973 14.9314 1.42119 14.9314H8.88405C8.97552 14.9314 9.06332 14.8955 9.12853 14.8314C9.19374 14.7672 9.23112 14.68 9.23262 14.5886V13.3657H4.66119C4.23685 13.3657 3.82988 13.1971 3.52982 12.8971C3.22977 12.597 3.06119 12.1901 3.06119 11.7657V3.70286H1.42119ZM12.2783 1.6C12.2768 1.45853 12.2196 1.32337 12.119 1.22387C12.0184 1.12437 11.8827 1.06856 11.7412 1.06857H4.68405C4.54258 1.06856 4.40681 1.12437 4.30624 1.22387C4.20567 1.32337 4.14841 1.45853 4.14691 1.6V11.7657C4.14841 11.9072 4.20567 12.0423 4.30624 12.1418C4.40681 12.2413 4.54258 12.2972 4.68405 12.2971H11.7698C11.9112 12.2972 12.047 12.2413 12.1476 12.1418C12.2481 12.0423 12.3054 11.9072 12.3069 11.7657L12.2783 1.6Z"
        fill={disabled ? disabledColor : 'url(#paint0_linear_9199_31371)'}
      />
      <path
        d="M10.9698 9.41121H5.48407C5.40927 9.41895 5.33367 9.4109 5.26217 9.38758C5.19067 9.36426 5.12487 9.32618 5.06902 9.27582C5.01317 9.22545 4.96852 9.16392 4.93796 9.09521C4.90739 9.02649 4.8916 8.95212 4.8916 8.87692C4.8916 8.80171 4.90739 8.72735 4.93796 8.65863C4.96852 8.58992 5.01317 8.52838 5.06902 8.47802C5.12487 8.42765 5.19067 8.38958 5.26217 8.36626C5.33367 8.34294 5.40927 8.33489 5.48407 8.34263H10.9698C11.0446 8.33489 11.1202 8.34294 11.1917 8.36626C11.2632 8.38958 11.329 8.42765 11.3848 8.47802C11.4407 8.52838 11.4853 8.58992 11.5159 8.65863C11.5465 8.72735 11.5623 8.80171 11.5623 8.87692C11.5623 8.95212 11.5465 9.02649 11.5159 9.09521C11.4853 9.16392 11.4407 9.22545 11.3848 9.27582C11.329 9.32618 11.2632 9.36426 11.1917 9.38758C11.1202 9.4109 11.0446 9.41895 10.9698 9.41121Z"
        fill={disabled ? disabledColor : 'url(#paint1_linear_9199_31371)'}
      />
      <path
        d="M10.9698 7.2198H5.48407C5.40927 7.22755 5.33367 7.2195 5.26217 7.19618C5.19067 7.17285 5.12487 7.13478 5.06902 7.08441C5.01317 7.03405 4.96852 6.97252 4.93796 6.9038C4.90739 6.83509 4.8916 6.76072 4.8916 6.68551C4.8916 6.61031 4.90739 6.53594 4.93796 6.46723C4.96852 6.39851 5.01317 6.33698 5.06902 6.28661C5.12487 6.23625 5.19067 6.19817 5.26217 6.17485C5.33367 6.15153 5.40927 6.14348 5.48407 6.15123H10.9698C11.0446 6.14348 11.1202 6.15153 11.1917 6.17485C11.2632 6.19817 11.329 6.23625 11.3848 6.28661C11.4407 6.33698 11.4853 6.39851 11.5159 6.46723C11.5465 6.53594 11.5623 6.61031 11.5623 6.68551C11.5623 6.76072 11.5465 6.83509 11.5159 6.9038C11.4853 6.97252 11.4407 7.03405 11.3848 7.08441C11.329 7.13478 11.2632 7.17285 11.1917 7.19618C11.1202 7.2195 11.0446 7.22755 10.9698 7.2198Z"
        fill={disabled ? disabledColor : 'url(#paint2_linear_9199_31371)'}
      />
      <path
        d="M10.9698 5.01953H5.48407C5.34312 5.01953 5.20795 4.96354 5.10829 4.86388C5.00863 4.76422 4.95264 4.62905 4.95264 4.4881C4.95264 4.34716 5.00863 4.21199 5.10829 4.11233C5.20795 4.01266 5.34312 3.95667 5.48407 3.95667H10.9698C11.1107 3.95667 11.2459 4.01266 11.3456 4.11233C11.4452 4.21199 11.5012 4.34716 11.5012 4.4881C11.5012 4.62905 11.4452 4.76422 11.3456 4.86388C11.2459 4.96354 11.1107 5.01953 10.9698 5.01953Z"
        fill={disabled ? disabledColor : 'url(#paint3_linear_9199_31371)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_9199_31371"
          x1="6.68977"
          y1="16"
          x2="6.68977"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={disabled ? disabledColor : colors.gp1} />
          <stop offset="1" stopColor={disabled ? disabledColor : colors.gp2} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9199_31371"
          x1="8.22693"
          y1="9.41406"
          x2="8.22693"
          y2="8.33978"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={disabled ? disabledColor : colors.gp1} />
          <stop offset="1" stopColor={disabled ? disabledColor : colors.gp2} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9199_31371"
          x1="8.22693"
          y1="7.22266"
          x2="8.22693"
          y2="6.14837"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={disabled ? disabledColor : colors.gp1} />
          <stop offset="1" stopColor={disabled ? disabledColor : colors.gp2} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9199_31371"
          x1="8.22692"
          y1="5.01953"
          x2="8.22692"
          y2="3.95667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={disabled ? disabledColor : colors.gp1} />
          <stop offset="1" stopColor={disabled ? disabledColor : colors.gp2} />
        </linearGradient>
      </defs>
    </svg>
  );
};
