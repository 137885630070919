import styled from 'styled-components';
import logoSvg from 'src/assets/cydea.svg';
import laptopBig from 'src/assets/laptopBig.png';

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  a {
    text-decoration: underline;
  }
`;

export const Logo = styled.img.attrs({
  src: logoSvg,
  alt: 'Cydea Logo',
})`
  width: 167.76px;
  height: 59px;
`;

export const Image = styled.img.attrs({
  src: laptopBig,
})`
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 120%;
`;

export const RightContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${({ theme }) => theme.colors.gradient};
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;
