import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { TreeTable, TreeTableExpandedKeysType, TreeTableSelectionKeysType } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { useEffect, useState } from 'react';
import { TreeNode } from 'primereact/treenode';
import { handleExpand } from './util';
import { Actions, OrgAvatar, OrgSettingsBtn, SortIcon } from './comps';
import genNodes from './genNodes';
import { Search } from '@mui/icons-material';
import { OrgTreeItem, OrganisationWorkspace, orgTreeItemTypeNames } from 'src/api/types';
import _ from 'lodash';
import { useAuth } from 'src/state/auth';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { useStateSelector } from 'src/redux';
import { TextOverflowTooltip } from 'src/common';

interface DivProps {
  $isLoading?: boolean;
}

const Div = styled.div<DivProps>`
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  min-width: 700px;

  * {
    font-weight: 500;
  }

  .p-treetable-header {
    display: flex;
    align-items: center;
    height: 59px;
    background-color: ${({ theme }) => theme.colors.white};
    padding-left: 18px;

    input {
      height: 59px;
      width: 100%;
      outline: none;
      border: none;
      padding: 0 15px;
      color: ${({ theme }) => theme.colors.prussianBlue};
      background-color: ${({ theme }) => theme.colors.white};
      font-size: 16px;
      ::placeholder {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.cflowerBlue};
        font-weight: 500;
      }
    }
  }

  table {
    background-color: ${({ theme }) => theme.colors.white};
    thead {
      tr {
        height: 59px;
        border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
        border-top: 1px solid ${({ theme }) => theme.colors.stroke};
        text-align: left;

        th {
          padding: 0 10px;
          font-size: 18px;
          font-weight: 500;
          color: ${({ theme }) => theme.colors.prussianBlue};

          &:first-child {
            padding: 0 60px;
          }

          .p-column-header-content {
            display: flex;
            align-items: center;
            .p-column-title {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
        position: relative;
        font-size: 14px;
        cursor: default;
        color: ${({ theme }) => theme.colors.prussianBlue};

        &:hover {
          background-color: ${({ theme }) => theme.colors.cultured};
        }

        &:last-child {
          border-bottom: none;
        }

        * {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        td {
          padding: 0 10px;
          height: 77px;

          &:first-child {
            padding: 0 10px 0 45px;
          }

          button {
            cursor: pointer;
            margin-right: 10px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            path {
              position: absolute;
            }
          }

          &.c-name {
            display: flex;
          }
        }
      }

      .p-highlight {
      }
    }
  }

  .p-column-header-content {
  }
  .p-sortable-column-badge {
    display: none;
  }

  .p-treetable-thead {
    .c-name {
      width: 30%;
    }
    .c-acc-type {
      width: 15%;
    }
  }

  .c-actions {
    position: relative;
    padding-right: 28px;
    width: 210px;
  }

  .c-tdm {
    width: 0px !important;
    position: relative;
    justify-content: center;
    transform: translateX(-50px);
    align-items: center;
    overflow: visible !important;
    &__content {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      overflow: visible;
    }
  }

  .pointer {
    cursor: pointer !important;
  }

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      * {
        cursor: wait !important;
      }
    `}

  .row-org {
    cursor: pointer;
  }
  .row-workspace {
  }

  [data-pc-section='emptymessagecell'] {
    color: ${({ theme }) => theme.colors.cflowerBlue};
    font-size: 14px;
    transform: translateX(15px);
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .p-treetable-header {
      height: 48px;
      input {
        height: 48px;
        font-size: 14px;
        ::placeholder {
          font-size: 14px;
        }
      }
    }
    table {
      thead {
        tr {
          height: 48px;
          th {
            padding: 0 4px;
            font-size: 16px;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 0 6px;
            height: 52px;
            &:first-child {
              padding: 0 10px 0 5px;
            }
          }
        }
      }
    }
    .c-actions {
      padding-right: 20px;
      width: 180px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .p-treetable-header {
      height: 40px;
      input {
        height: 40px;
        font-size: 12px;
        ::placeholder {
          font-size: 12px;
        }
      }
    }
    table {
      thead {
        tr {
          height: 40px;
          th {
            font-size: 14px;
          }
        }
      }
      tbody {
        tr {
          font-size: 12px;
          td {
            height: 46px;
            &:first-child {
              padding: 0 15px 0 5px;
            }
          }
        }
      }
    }
    .c-actions {
      padding-right: 20px;
      width: 150px;
    }
  }

  .p-treetable-toggler-icon {
    color: ${({ theme }) => theme.colors.prussianBlue};
  }
`;

const initKeys = Object.fromEntries(Array.from({ length: 10 }, (_, i) => [`${i}`, true]));

interface OrgRecursiveTableProps {
  orgData: {
    organisations: OrganisationWorkspace[];
    myOrganisations: OrganisationWorkspace[];
    myRequests: OrganisationWorkspace[];
  };
}

const OrgRecursiveTable: React.FC<OrgRecursiveTableProps> = memo(
  ({ orgData }) => {
    const colors = useStateSelector(({ theme }) => theme.colors);
    const [nodes, setNodes] = useState<TreeNode[]>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [selectedNodeKeys, setSelectedNodeKeys] = useState<TreeTableSelectionKeysType>({});
    const [expandedKeys, setExpandedKeys] = useState<TreeTableExpandedKeysType>(initKeys);
    const { hierarchy } = useAuth();

    useEffect(() => {
      const nodes = genNodes(hierarchy);
      setNodes(nodes);
    }, [hierarchy]);

    const rowClassName = (node: {
      key: string;
      data: OrgTreeItem;
      children?: { key: string; data: OrgTreeItem }[];
    }) => {
      return { 'row-workspace': node.data.type === 'W', 'row-org': node.data.type === 'O' };
    };

    const toggleApplications = (key?: string | number) => {
      if (!key) return;
      let _expandedKeys = { ...expandedKeys };

      if (_expandedKeys[key]) delete _expandedKeys[key];
      else _expandedKeys[key] = true;

      setExpandedKeys(_expandedKeys);
    };

    return (
      <Div>
        <TreeTable
          value={nodes}
          globalFilter={globalFilter}
          header={
            <>
              <Search
                style={{
                  color: colors.prussianBlue,
                }}
              />
              <input
                type="search"
                onInput={(e) => {
                  // @ts-ignore
                  setGlobalFilter(e.target.value);
                  mpEvent(MPEvents.TableSearch, {});
                }}
                placeholder="Search by keyword ..."
              />
            </>
          }
          filterMode="strict"
          selectionMode="multiple"
          sortMode="multiple"
          onExpand={({ node }) => handleExpand({ node, isExpand: true, selectedNodeKeys }, setSelectedNodeKeys)}
          onCollapse={({ node }) => handleExpand({ node, isExpand: false, selectedNodeKeys }, setSelectedNodeKeys)}
          selectionKeys={selectedNodeKeys}
          sortIcon={(e) => <SortIcon sortOrder={e.sortOrder} />}
          rowClassName={rowClassName}
          onToggle={(e) => setExpandedKeys(e.value)}
          multiSortMeta={[
            { field: 'type', order: -1 },
            { field: 'name', order: 1 },
          ]}
          onRowClick={({ node }) => {
            const data = node.data as OrgTreeItem;
            if (data.type === 'O') {
              toggleApplications(node.key);
            }
          }}
          expandedKeys={expandedKeys}
        >
          <Column
            field="name"
            header="Name"
            expander
            sortable
            className="c-name"
            body={({ data }) => <OrgAvatar name={data.name} imgUrl={data.imgUrl} gradientBlue={data.type === 'W'} />}
          />
          <Column
            field="type"
            header="Account Type"
            sortable
            className="c-acc-type"
            body={({ data }: { data: OrgTreeItem }) => orgTreeItemTypeNames[data.type]}
          />
          <Column
            field="primary_contact"
            header="Primary Contact"
            sortable
            body={({ data }: { data: OrgTreeItem }) => (
              <TextOverflowTooltip>{data.primary_contact}</TextOverflowTooltip>
            )}
          />
          <Column
            field="domain"
            header="Domain"
            sortable
            body={({ data }: { data: OrgTreeItem }) => (
              <TextOverflowTooltip>{data.domain?.replace(/^@/, '')}</TextOverflowTooltip>
            )}
          />
          <Column
            field=""
            header=""
            className="c-actions"
            body={({ data }: { data: OrgTreeItem }) => <Actions data={data} orgData={orgData} />}
          />
          <Column
            field=""
            header=""
            className="c-tdm"
            body={({ data }: { data: OrgTreeItem }) =>
              data.type === 'O' && (
                <div className="c-tdm__content">
                  <OrgSettingsBtn orgId={data.id} />
                </div>
              )
            }
          />
        </TreeTable>
      </Div>
    );
  },
  (prev, next) => _.isEqual(prev, next),
);

export default OrgRecursiveTable;
