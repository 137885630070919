import React, { useLayoutEffect, useRef } from 'react';
import GlobalStyles from './theme/GlobalStyles';
import { ThemeProvider } from 'styled-components';
import { ReactQueryConfig, ReactQueryConfigProvider } from 'react-query';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import AuthPage from 'src/pages/Auth/AuthPage';
import VerifyPage from 'src/pages/Auth/VerifyPage';
import BlockedPage from 'src/pages/Auth/BlockedPage';
import SignedInPage from 'src/pages/SignedInPage';
import { AuthProvider } from 'src/state/auth';
import { CallbackPage } from './pages/Auth/CallbackPage';
import { auth0Domain, auth0ClientID, auth0Audience } from './utils/auth';
import moment from 'moment-timezone';
import { Provider as StateProvider } from 'react-redux';
import { store, useStateSelector } from './redux';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import muiTheme from './theme';
import useMixpanel from './utils/mixpanel/useMixPanel';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './graphql';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import useActionsTheme from './redux/actionHooks/useActionsTheme';
moment.tz.setDefault('UTC');

const reactQueryConfig: ReactQueryConfig = {
  queries: {
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
    retry: false,
    onError: (err) => {},
  },
  mutations: {
    onError: (err) => {},
  },
};

const RootNavigation = () => {
  const theme = useStateSelector(({ theme }) => theme);
  useMixpanel();
  const { setScheme } = useActionsTheme();
  const isThemeSet = useRef(false);

  useLayoutEffect(() => {
    if (!isThemeSet.current) {
      const scheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      setScheme(scheme);
      isThemeSet.current = true;
    }
  }, []);

  return (
    <MUIThemeProvider theme={muiTheme}>
      <ThemeProvider theme={{ ...muiTheme, ...theme }}>
        <GlobalStyles />
        <CssBaseline />
        <Routes>
          <Route path="/callback" element={<CallbackPage />} />
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/verify" element={<VerifyPage />} />
          <Route path="/blocked" element={<BlockedPage />} />
          <Route path="*" element={<SignedInPage />} />
        </Routes>
      </ThemeProvider>
    </MUIThemeProvider>
  );
};

const App = () => {
  return (
    <StateProvider store={store}>
      <ApolloProvider client={apolloClient}>
        {/* @ts-ignore */}
        <ReactQueryConfigProvider config={reactQueryConfig}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Router>
              <Auth0Provider
                domain={auth0Domain}
                clientId={auth0ClientID}
                authorizationParams={{
                  audience: `${auth0Audience}`,
                  redirect_uri: `${window.location.protocol}//${window.location.host}/callback`,
                }}
              >
                <AuthProvider>
                  <RootNavigation />
                </AuthProvider>
              </Auth0Provider>
            </Router>
          </LocalizationProvider>
        </ReactQueryConfigProvider>
      </ApolloProvider>
    </StateProvider>
  );
};

export default App;
