import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
  display: flex;
  grid-gap: 4px;
  height: 26px;
  align-items: flex-end;
  .score {
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.cflowerBlue};
    margin-left: 4px;
    opacity: 0.7;
  }
`;

const Bar = styled.div<{ $height: number; $isActive: boolean }>`
  border-radius: 4px;
  width: 6px;
  position: relative;
  overflow: hidden;
  background: ${({ theme, $isActive }) => ($isActive ? theme.colors.gradientVertical : theme.colors.cflowerBlue)};
  height: ${({ $height }) => $height}%;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.7)};
`;

interface RankIndicatorProps {
  score: number;
  min: number;
  max: number;
}

export const RankIndicator: React.FC<RankIndicatorProps> = ({ score, min, max }) => {
  const normalizedScore = Math.max(0, Math.min(1, (score - min) / (max - min)));

  const getBarActiveState = (barIndex: number) => {
    const threshold = barIndex * 0.2;
    return normalizedScore >= threshold;
  };

  return (
    <Div>
      <Bar $height={20} $isActive={getBarActiveState(0)} />
      <Bar $height={40} $isActive={getBarActiveState(1)} />
      <Bar $height={60} $isActive={getBarActiveState(2)} />
      <Bar $height={80} $isActive={getBarActiveState(3)} />
      <Bar $height={100} $isActive={getBarActiveState(4)} />
      {/* <div className="score">{score}</div> */}
    </Div>
  );
};
