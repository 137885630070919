import styled from 'styled-components';

export const Div = styled.div`
  .vw-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__checks {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      grid-gap: 20px;
      margin-right: 30px;
    }
    &__avo-check {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      cursor: pointer;
      user-select: none;
    }
  }

  .divider {
    border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
  }

  .btn-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .rows-grid {
    display: grid;
    grid-gap: 20px;
  }

  .var {
    display: flex;
    &__chip {
      padding: 0 12px;
      min-height: 32px;
      display: flex;
      align-items: center;
      /* justify-content: center; */
      background-color: ${({ theme }) => theme.colors.aliceBlue};
      border: 1px solid ${({ theme }) => theme.colors.stroke};
      border-radius: 16px;
      margin-right: auto;
    }
  }
  .currency-wrap {
    max-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .error {
    color: ${({ theme }) => theme.colors.error};
    font-size: 14px;
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .vw-head {
      align-items: flex-start;
      flex-direction: column;
      &__checks {
        margin-left: auto;
        margin-right: 0px;
        flex-direction: column;
        grid-gap: 8px;
      }
    }
    .rows-grid {
      grid-gap: 10px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .var {
      display: flex;
      &__chip {
        padding: 0 8px;
        min-height: 24px;
        max-width: 130px;
        overflow: hidden;
      }
    }
  }
`;
