import React from 'react';
import { DataTitle } from 'src/common';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { Modifiers, NoteType } from 'src/api/types';
import MaterialInput from 'src/components/form/MaterialInput';

const Div = styled.div<{ $marginLeft: number }>`
  display: grid;
  margin-left: ${({ $marginLeft }) => $marginLeft}px;
`;

const Input = styled(MaterialInput)`
  & input,
  textarea {
    background: ${({ theme }) => theme.colors.white};
  }
`;

interface NoteProps {
  idx: number;
  namePrefix: string;
  disabled?: boolean;
}

export const Note: React.FC<NoteProps> = ({ idx, namePrefix, disabled }) => {
  const { values, setFieldValue, handleBlur } = useFormikContext<Modifiers>();

  const note = values[namePrefix as keyof Modifiers][idx] as NoteType;
  const noteValue = note?.data?.note;

  const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === '' ? null : e.target.value;
    setFieldValue(`${namePrefix}[${idx}].data.note`, value);
    setFieldValue(`${namePrefix}[${idx}].type`, 'NOTE_TYPE');
  };

  return (
    <Div $marginLeft={(values.treat.length || values.transfer.length) && !disabled ? 30 : 0}>
      <DataTitle $noMargin nowrap>
        NOTE
      </DataTitle>
      <Input
        id={`${namePrefix}[${idx}].data.note`}
        value={noteValue || ''}
        placeholder="Add a note"
        onChange={handleNoteChange}
        onBlur={handleBlur}
        disabled={disabled}
        fullWidth
        multiline
      />
    </Div>
  );
};
