import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { Assessment } from 'src/api/types';
import { GTColumnSelectAnchor } from 'src/components/GenericTable';
import { filter } from 'lodash';
import { GTInternalIds } from 'src/components/GenericTable/types';
import _ from 'lodash';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { TableIds } from 'src/api/types/misc';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TextOverflowTooltip } from 'src/common';

type UseColumns = (a: { data?: Assessment[] }) => {
  columns: ColumnDef<Assessment>[];
  GTColumnSelectAnchorExported: JSX.Element;
  selectedAssessment: string;
  setSelectedAssessment: (id: string) => void;
};

const defaultColumns = ['name', '_scenarios', 'description'];

export const useAssessmentColumns: UseColumns = ({ data = [] }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(
    `${TableIds.controlLinkScenarioAssessment}-columns`,
    defaultColumns,
  );
  const [selectedAssessment, setSelectedAssessment] = useState('');

  const columns = useMemo(() => {
    const internalIds = [GTInternalIds.tdm];

    const list: ColumnDef<Assessment>[] = [
      {
        id: 'name',
        header: 'Assessment',
        accessorFn: (row) => row.name,
        cell: (info) => {
          const isDisabled = info.row.original.scenarios_count === 0;
          return (
            <TextOverflowTooltip>
              <span
                className={!isDisabled ? 'table-link' : ''}
                onClick={() => {
                  setSelectedAssessment(info.row.original.id);
                  mpEvent(MPEvents.ButtonClick, {
                    button: '[select assessment]',
                    modal: 'Link scenarios to control modal',
                  });
                }}
              >
                {info.getValue() as string}
              </span>
            </TextOverflowTooltip>
          );
        },
        sortUndefined: 'last',
        sortingFn: sortingFns.text,
        maxSize: 300,
      },

      {
        id: 'scenarios_count',
        header: 'Scenarios',
        accessorFn: (row) => row.scenarios_count,
        cell: (info) => info.getValue(),
      },
      {
        id: 'description',
        header: 'Description',
        accessorFn: (row) => row.description,
        cell: (info) => (
          <TextOverflowTooltip>
            <span>{info.getValue() as string}</span>
          </TextOverflowTooltip>
        ),
        sortingFn: sortingFns.text,
      },

      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: () => null,
        size: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'name', title: 'Assessment' },
        { key: '_scenarios', title: 'Scenarios' },
        { key: 'description', title: 'Description' },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return { columns, GTColumnSelectAnchorExported, selectedAssessment, setSelectedAssessment };
};
