import React, { useState } from 'react';
import { GradientTextAction, InputLabel, Poppins, Spacer } from 'src/common';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { FormValues } from '../types';
import MaterialInput from 'src/components/form/MaterialInput';
import { GenericSelect } from 'src/components/select/GenericSelect';
import { WorkspaceUser } from 'src/api/types';
import { sendReviewNotification } from 'src/api/workspace';
import { useMutation } from 'react-query';
import { useStateSelector } from 'src/redux';

const Div = styled.div`
  .MuiInputAdornment-root {
    display: none;
  }

  .tdd {
    display: flex;
    justify-content: flex-end;

    &__action {
      user-select: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }
`;

interface DailyDigestProps {
  workspaceId: string;
  members: WorkspaceUser[];
}

export const DailyDigest: React.FC<DailyDigestProps> = ({ workspaceId, members }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState<string | null>(null);
  const [sentMessage, setSentMessage] = useState('');
  const { values, touched, errors, setFieldValue, setFieldTouched } = useFormikContext<FormValues>();
  const colors = useStateSelector(({ theme }) => theme.colors);

  const [mutate, { isLoading }] = useMutation(sendReviewNotification, {
    onSuccess: () => setSentMessage('Email Sent! The user will receive the email if they have turned on notifications'),
    onError: () => setSentMessage('Email Sent! The user will receive the email if they have turned on notifications'),
  });

  return (
    <Div>
      <Spacer $px={20} />
      <InputLabel>DAILY DIGEST</InputLabel>
      <TimePicker
        ampm={false}
        value={values.notification_schedule_time ? moment(values.notification_schedule_time, 'HH:mm') : null}
        onChange={(date) => setFieldValue('notification_schedule_time', date ? date.format('HH:mm') : null)}
        slots={{
          textField: MaterialInput,
        }}
        slotProps={{
          textField: {
            fullWidth: true,
            error: touched.notification_schedule_time && Boolean(errors.notification_schedule_time),
            helperText: touched.notification_schedule_time && errors.notification_schedule_time,
            onBlur: () => setFieldTouched('notification_schedule_time', true),
          },
        }}
      />
      <Spacer $px={18} />
      <div className="tdd">
        <div
          className="tdd__action"
          onClick={() => {
            setIsOpen(!isOpen);
            setEmail(null);
            setSentMessage('');
          }}
        >
          <GradientTextAction $underline px={14}>
            Test Daily Digest
          </GradientTextAction>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="7"
            viewBox="0 0 11 7"
            fill="none"
            style={{ transform: !isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
          >
            <path
              d="M9.88629 6.46582H0.659141C0.480523 6.46582 0.326108 6.40061 0.195643 6.27029C0.0651783 6.13982 0 5.98541 0 5.8069C0 5.62839 0.0651783 5.47386 0.195643 5.34347L4.80923 0.729949C4.93984 0.599484 5.09426 0.534163 5.27273 0.534163C5.45121 0.534163 5.60577 0.599484 5.73612 0.729949L10.3497 5.34351C10.48 5.47386 10.5455 5.62839 10.5455 5.80693C10.5455 5.98541 10.4801 6.13982 10.3497 6.27032C10.2194 6.40075 10.0648 6.46582 9.88629 6.46582Z"
              fill="url(#paint0_linear_9338_27515)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_9338_27515"
                x1="5.27273"
                y1="6.46582"
                x2="5.27273"
                y2="0.534163"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor={colors.gp1} />
                <stop offset="1" stopColor={colors.gp2} />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      {isOpen && (
        <>
          <Spacer $px={18} />
          <GenericSelect
            onChange={(val) => {
              setEmail(val.value as string);
              setSentMessage('');
            }}
            options={members.map((m) => ({ label: `${m.workspace_user_name} (${m.email})`, value: m.email }))}
            value={email}
            disabled={isLoading}
          />
          {email && (
            <>
              <Spacer $px={11} />
              {!sentMessage ? (
                <GradientTextAction
                  $underline
                  px={14}
                  $disabled={isLoading}
                  onClick={() =>
                    mutate({
                      email,
                      workspace_ux_id: workspaceId,
                    })
                  }
                >
                  Send Email
                </GradientTextAction>
              ) : (
                <Poppins color="cflowerBlue" px={14}>
                  {sentMessage}
                </Poppins>
              )}
            </>
          )}
        </>
      )}
    </Div>
  );
};
