import React, { useState } from 'react';
import { TableIds } from 'src/api/types/misc';
import Audit from 'src/components/Audit';
import ExpandSection from 'src/components/ExpandSection';

interface AuditSectionProps {}

export const AuditSection: React.FC<AuditSectionProps> = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ExpandSection title="AUDIT" defaultOpen={false} onChange={setIsOpen} sectionId="rmp_audit">
      {isOpen && (
        <div
          style={{
            maxHeight: '800px',
            position: 'relative',
          }}
        >
          <Audit rootPath="controls" initQuery={{}} tableId={TableIds.auditRmp} noHide topSpacer={0} maxHeight={800} />
        </div>
      )}
    </ExpandSection>
  );
};
