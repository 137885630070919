import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { Control, ControlLibraryShortRanked, PlanScenarios } from 'src/api/types';
import { GTCheckBox, GTColumnSelectAnchor, GTExpander, GTTdm } from 'src/components/GenericTable';
import { filter } from 'lodash';
import { GTInternalIds } from 'src/components/GenericTable/types';
import { gTCheckHandler } from 'src/components/GenericTable/util';
import { Link } from 'react-router-dom';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TableIds } from 'src/api/types/misc';
import { TextOverflowTooltip } from 'src/common';
import { RankIndicator, Rating } from './comps';
import _ from 'lodash';

type UseColumns = (a: {
  data: ControlLibraryShortRanked[];
  rmpControls: Control[];
  scenarioControls: PlanScenarios[];
}) => {
  columns: ColumnDef<ControlLibraryShortRanked>[];
  GTColumnSelectAnchorExported: JSX.Element;
  checked: string[];
  resetChecked: () => void;
};

const defaultColumns = ['frameworkShortname', 'controlLibraryId', 'name', 'FrameworkAuthor', 'final_score'];

export const useColumns: UseColumns = ({ data = [], rmpControls, scenarioControls }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(
    `${TableIds.scenarioLinkSuggestedControl}-columns`,
    defaultColumns,
  );
  const [checked, setChecked] = useState<string[]>([]);

  const min = _.min(data.map((item) => item.final_score || 0)) ?? 0;
  const max = _.max(data.map((item) => item.final_score || 0)) ?? 500;

  const columns = useMemo(() => {
    const internalIds = [GTInternalIds.expander, GTInternalIds.tdm, GTInternalIds.checker];

    const list: ColumnDef<ControlLibraryShortRanked>[] = [
      {
        id: GTInternalIds.checker,
        header: ({ table }) => {
          const { isAllChecked, checkAllHandler, visibleRows } = gTCheckHandler(
            {
              checked,
              selector: ['ux_id', 'id'],
              table,
            },
            setChecked,
          );

          const isAllLinked = visibleRows.every((el) =>
            scenarioControls.some((linked) => linked.control_data.id === el),
          );

          return (
            <GTCheckBox isChecked={isAllChecked || isAllLinked} disabled={isAllLinked} onClick={checkAllHandler} />
          );
        },
        cell: ({ row }) => {
          // @ts-ignore
          const id = row.original.id || row.original.ux_id;

          const isLinked = scenarioControls.some(
            (el) =>
              // @ts-ignore
              el.control_data.id === row.original.id ||
              // @ts-ignore
              el.control_data.controlLibrary_Id === row.original.controlLibrary_Id,
          );

          return (
            <GTCheckBox
              isChecked={checked.includes(id) || isLinked}
              disabled={isLinked}
              onClick={() => {
                if (checked.includes(id)) {
                  setChecked(checked.filter((el) => el !== id));
                } else {
                  setChecked([...checked, id]);
                }
              }}
            />
          );
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: GTInternalIds.expander,
        header: () => null,
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <GTExpander onClick={row.getToggleExpandedHandler()} isExpanded={row.getIsExpanded()} />
          ) : null;
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: 'ux_id',
        header: 'Id',
        // @ts-ignore
        accessorFn: (row) => row.id || row.ux_id,
        cell: (info) => info.getValue(),
      },
      {
        id: 'frameworkShortname',
        header: 'Framework',
        // @ts-ignore
        accessorFn: (row) => row.frameworkShortname || row.frameworkLibrary_shortname,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },
      {
        id: 'controlLibraryId',
        header: 'Framework ID',
        // @ts-ignore
        accessorFn: (row) => row.controlLibraryId || row.controlId,
        cell: (info) => info.getValue(),
      },
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => row.name || null,
        cell: (info) => {
          // @ts-ignore
          const isRmp = !!info.row.original.id;
          return (
            <TextOverflowTooltip>
              <Link
                className="table-link"
                to={
                  isRmp
                    ? // @ts-ignore
                      `/risk-management/control/${info.row.original.id}`
                    : `/control-frameworks/all-controls/${info.row.original.ux_id}`
                }
                target={'_blank'}
                onClick={(e) => {
                  e.stopPropagation();
                  mpEvent(MPEvents.Link, {
                    source: {
                      value: ['Framework'],
                    },
                    destination: {
                      value: ['Framework Controls all'],
                      params: {
                        // @ts-ignore
                        id: info.row.original.ux_id || info.row.original.controlLibrary_Id,
                      },
                    },
                    tags: ['FRAMEWORK'],
                  });
                }}
              >
                {info.getValue() as string}
              </Link>
            </TextOverflowTooltip>
          );
        },
        maxSize: 300,
        sortingFn: sortingFns.text,
      },
      {
        id: 'FrameworkAuthor',
        header: 'Author',
        accessorFn: (row) => row.FrameworkAuthor || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },
      {
        id: 'final_score',
        header: 'Score',
        accessorFn: (row) => row.final_score || 0,
        cell: (info) => {
          return <RankIndicator min={min} max={max} score={info.getValue() as number} />;
        },
        sortingFn: sortingFns.text,
      },
      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: ({ row }) => {
          return (
            <div css="display: flex;grid-gap: 8px">
              <GTTdm
                extraComponentLeft={
                  <Rating
                    value={row.original.user_vote}
                    // @ts-ignore
                    controlId={row.original.ux_id || row.original.controlLibrary_Id}
                  />
                }
              />
            </div>
          );
        },
        size: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns, rmpControls, checked, scenarioControls, min, max]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'ux_id', title: 'Id' },
        { key: 'frameworkShortname', title: 'Framework' },
        { key: 'controlLibraryId', title: 'Framework ID' },
        { key: 'name', title: 'Name' },
        { key: 'FrameworkAuthor', title: 'Author' },
        { key: 'final_score', title: 'Score' },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return {
    columns,
    GTColumnSelectAnchorExported,
    checked,
    resetChecked: () => setChecked([]),
  };
};
