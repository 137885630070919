import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectionVariant, QKeys, Scenario } from 'src/api/types';
import { DataTitle, DataValue, NoData, Poppins, Spacer } from 'src/common';
import ExpandCollapse from 'src/common/ExpandCollapse';
import ExpandSection from 'src/components/ExpandSection';
import Button from 'src/components/form/Button';
import { ManageRiskModal } from 'src/components/modals';
import useVersion from 'src/hooks/useVersion';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import styled from 'styled-components';
import { Controls } from './comps';
import { queryCache } from 'react-query';

const Div = styled.div`
  .content {
    display: flex;
    justify-content: space-between;
  }
`;

interface RiskManagementSectionProps {
  effectiveScenario: Scenario;
  scenarioBase: Scenario;
}

export const RiskManagementSection: React.FC<RiskManagementSectionProps> = ({ effectiveScenario, scenarioBase }) => {
  const { scenarioId } = useParams<{ scenarioId: string }>();
  const [isManageRiskModalOpen, setIsManageRiskModalOpen] = useState(false);
  const { version } = useVersion();

  return (
    <>
      {isManageRiskModalOpen && (
        <ManageRiskModal
          scenarioId={scenarioId as string}
          scenarioDetails={effectiveScenario}
          onClose={() => {
            setIsManageRiskModalOpen(false);
            queryCache.invalidateQueries([QKeys.Scenario, { scenarioId, version, variant: ProjectionVariant.live }]);
          }}
          subTitle={{
            title: 'SCENARIO',
            value: effectiveScenario.name,
          }}
        />
      )}
      <ExpandSection title="RISK MANAGEMENT" sectionId="scanario_rm">
        <Div>
          <div css="display: flex;justify-content: space-between">
            <Poppins weight={600}>RISK MANAGEMENT PROPOSAL</Poppins>
            {!version && (
              <Button
                primary
                onClick={() => {
                  setIsManageRiskModalOpen(true);
                  mpEvent(MPEvents.ButtonClick, {
                    button: 'Select strategy',
                    tags: ['SCENARIO'],
                  });
                }}
                data-cy="manage-risk-btn"
                $constWidth={200}
              >
                Select Strategy
              </Button>
            )}
          </div>
          <div className="content">
            <div>
              <DataTitle>MANAGEMENT STRATEGY</DataTitle>
              {effectiveScenario.management_strategy ? (
                <DataValue data-cy="rm-strategy-label">{effectiveScenario.management_strategy}</DataValue>
              ) : (
                <NoData />
              )}
            </div>
          </div>
          <Spacer $px={10} />
          <div className="content">
            <DataTitle>NOTE</DataTitle>
          </div>
          <div />
          {effectiveScenario.management_note ? <ExpandCollapse text={effectiveScenario.management_note} /> : <NoData />}
          <Spacer $px={30} />
          <Controls scenario={scenarioBase} />
        </Div>
      </ExpandSection>
    </>
  );
};
