import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, ModalSubTitle } from '../comps';
import { Poppins, Spacer } from 'src/common';
import { queryCache, useMutation } from 'react-query';
import { QKeys } from 'src/api/types';
import { Guard } from '../Guard';
import { addControllsFromLibrary, createControl } from 'src/api/controls';
import { createPlan } from 'src/api/plan';
import _ from 'lodash';
import TabsMenu from 'src/components/TabsMenu';
import { FromExisting } from './comps';
import { motion } from 'framer-motion';
import RmpForm from 'src/components/formComposed/RmpForm';
import { FormikProvider, useFormik } from 'formik';
import { rmpInitialValues, rmpValidationSchema } from 'src/components/formComposed/RmpForm/util';
import { selectUserOption } from 'src/utils/misc';
import { useAuth } from 'src/state/auth';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { AiSvg } from 'src/assets/dynamicSvg';
import { ActiveTab } from './types';
import { FromSuggested } from './comps/FromSuggested/FromSuggested';

const StyledModal = styled(Modal)`
  width: 1568px;
  min-height: 200px;
  padding: 0;
  max-height: calc(100% - 40px);
  overflow-y: auto;

  .content {
    overflow-y: auto;
  }

  .error {
    color: ${({ theme }) => theme.colors.error};
    font-size: 14px;
    text-align: center;
  }
`;

interface LinkControlsToScenarioModalProps {
  onClose: () => void;
  scenarioId: string;
  subTitle?: {
    title: string;
    value: string;
  };
  initialActiveTab?: ActiveTab;
}

export const LinkControlsToScenarioModal: React.FC<LinkControlsToScenarioModalProps> = ({
  onClose,
  scenarioId,
  subTitle,
  initialActiveTab = 'from_new',
}) => {
  const { usersOptios, userFeatures } = useAuth();
  const [activeTab, setActiveTab] = useState<ActiveTab>(initialActiveTab);
  const [err, setErr] = useState('');
  const [isCloseRequested, setIsCloseRequested] = useState(false);
  const [checked, setChecked] = useState<string[]>([]);
  const [responseErr, setResponseErr] = useState('');
  const [addRecommendedModifiers, setAddRecommendedModifiers] = useState(false);

  const onSuccess = () => {
    queryCache.invalidateQueries([QKeys.ScenarioControls, scenarioId]);
    queryCache.invalidateQueries([QKeys.Scenario, { scenarioId }]);
    onClose();
  };

  const onError = (err: any) => setResponseErr(err.message || 'Something went wrong');

  const [add, { isLoading }] = useMutation(createPlan, {
    onMutate: () => {
      setErr('');
    },
    onSuccess,
    onError,
  });

  const [addFromLib, { isLoading: isLoadingFromLib }] = useMutation(addControllsFromLibrary, {
    onSuccess,
    onError,
  });

  const formik = useFormik({
    initialValues: rmpInitialValues,
    validationSchema: rmpValidationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      setResponseErr('');

      const { userAdd } = selectUserOption({
        usersOptios,
        selectedUser: values.owner,
      });

      const control = await createControl({ ...values, owner: userAdd }).catch((err) =>
        setResponseErr(err.message || 'Something went wrong'),
      );
      if (!control) {
        return null;
      }
      return add({
        controlIds: [{ id: control.id }],
        scenarioIds: [{ id: scenarioId }],
      });
    },
  });

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={onClose}
      isOpen={isCloseRequested}
      isDirty={!!checked.length || formik.dirty}
      modalComponent={
        <StyledModal onRequestClose={() => setIsCloseRequested(true)} isOpen center={false}>
          <div className="content">
            <Spacer $px={40} />
            <div className="h-padding">
              <Poppins className="m-title" px={28}>
                Link Controls
              </Poppins>
              {subTitle && <ModalSubTitle title={subTitle.title} value={subTitle.value} />}
              <Spacer $px={20} />
              <TabsMenu
                tabs={
                  userFeatures.app.ai.suggestControls
                    ? [
                        { label: 'Enter Control Details', value: 'from_new', dataCy: 'from_new' },
                        { label: 'Link Exisiting Controls', value: 'from_existing', dataCy: 'from_existing' },
                        {
                          label: (
                            <>
                              <AiSvg style={{ marginRight: 8 }} />
                              <Poppins px={18} weight={600} color={'cflowerBlue'}>
                                Link Suggested Controls
                              </Poppins>
                            </>
                          ),
                          value: 'from_suggested',
                          dataCy: 'from_suggested',
                        },
                      ]
                    : [
                        { label: 'Enter Control Details', value: 'from_new', dataCy: 'from_new' },
                        { label: 'Link Exisiting Controls', value: 'from_existing', dataCy: 'from_existing' },
                      ]
                }
                activeTab={activeTab}
                setActiveTab={(tab) => {
                  setActiveTab(tab);
                  mpEvent(MPEvents.ButtonClick, {
                    button:
                      tab === 'from_new'
                        ? 'Tab:Enter Control Details'
                        : tab === 'from_existing'
                          ? 'Tab:Link Exisiting Controls'
                          : 'Tab:Link Suggested Controls',
                    modal: 'Link controls to scenario modal',
                    tags: ['SCENARIO', 'RMP'],
                  });
                }}
              />
              <Spacer $px={40} />
              {activeTab === 'from_existing' && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0, duration: 0.1 }}>
                  <FromExisting
                    scenarioId={scenarioId}
                    setChecked={setChecked}
                    isLoading={isLoading}
                    onSubmit={() =>
                      add({
                        controlIds: checked.map((el) => ({ id: el })),
                        scenarioIds: [{ id: scenarioId }],
                        // addRecommendedModifiers,
                      })
                    }
                    addRecommendedModifiers={addRecommendedModifiers}
                    setAddRecommendedModifiers={setAddRecommendedModifiers}
                  />
                </motion.div>
              )}
              {activeTab === 'from_suggested' && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0, duration: 0.1 }}>
                  <FromSuggested
                    setChecked={setChecked}
                    isLoading={isLoadingFromLib}
                    scenarioId={scenarioId}
                    onSubmit={() => {
                      const arr = checked.map((el) => ({ id: el, scenarioId }));
                      addFromLib({ controlLibraryIds: arr });
                    }}
                  />
                </motion.div>
              )}
            </div>
            {activeTab === 'from_new' && (
              <FormikProvider value={formik}>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0, duration: 0.1 }}>
                  <RmpForm
                    buttonTitle="CREATE"
                    onSubmitCb={() =>
                      mpEvent(MPEvents.ButtonClick, {
                        button: 'Create',
                        modal: 'Link controls to scenario modal',
                        tags: ['SCENARIO', 'RMP'],
                      })
                    }
                  />
                </motion.div>
              </FormikProvider>
            )}
          </div>
          <div className="h-padding">
            {(!!err || !!responseErr) && (
              <>
                <Spacer $px={15} />
                <div className="error">{err || responseErr}</div>
              </>
            )}
          </div>
          <Spacer $px={30} />
        </StyledModal>
      }
    />
  );
};
