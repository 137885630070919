import * as Yup from 'yup';
import { isValid } from 'date-fns';
import _ from 'lodash';
import { WizardFormValues } from 'src/api/types/wizard';
import { WizardIntent, WizzardWorkspaceType } from 'src/redux/reducers/app/types';
import { FormikErrors } from 'formik';
import { QKeys, Variable } from 'src/api/types';
import { updateWorkspaceVariables } from 'src/api/workspace';
import { queryCache } from 'react-query';

export const SLIDE_HEIGHT = 400;

const positiveError = 'Value must be greater than 0';

export const initialValues: WizardFormValues = {
  organisation: {
    sector: undefined,
    annualRevenue: undefined, //variable
    numberOfStaff: undefined, //variable
    annualWageBill: undefined, //variable
    avgWorkingDays: 260, //variable
    annualCyberSpend: undefined, //variable
    avgStaffHardwareCost: undefined, //variable
    usesManagedServiceProvider: false,
  },
  customers: {
    numberOfCustomers: undefined, //variable
    avgContractValue: undefined, //variable
    numberOfDataRecords: undefined, //variable
  },
  softwareDev: {
    developsSoftware: false,
    releaseCycleCost: undefined, //variable
    releaseCycleLength: undefined, //variable
    researchAndDevelopmentBudget: undefined, //variable
  },
  supplyChain: {
    avgCostToProcureSupplier: undefined, //variable
    avgInvoiceValue: undefined, //variable
    largestRegularInvoiceValue: undefined, //variable
  },
  cyberInsurance: {
    cyberInsurance: false,
    cyberInsuranceLimit: undefined, //variable
    cyberInsuranceExcess: undefined, //variable
    cyberInsurancePremium: undefined, //variable
  },
  regulations: {
    legislation: [],
    controlFrameworks: [],
    controlFrameworksOther: [],
    primaryControlFramework: undefined,
    primaryFrameworkControls: [],
  },
  scenarios: {
    B2B: false,
    B2C: false,
    publiclyAccessibleInternetBusiness: false,
    paymentVolumeOrValue: false,
    significantITContributionToRevenue: false,
    significantDataInCloud: false,
    softwareBusiness: false,
    cniOrDefenseInvolvement: false,
    significantRandD: false,
    regulated: false,
    operationalTechnology: false,
    supplyChainReliant: false,
  },
  assessment: {
    name: null,
    description: null,
    started_date: null,
    review_date: null,
  },
};

export const validationSchema = (requireAssessment: boolean) => {
  return Yup.object().shape({
    organisation: Yup.object().shape({
      sector: Yup.string().nullable(),
      annualRevenue: Yup.number().nullable().positive(positiveError), //variable
      numberOfStaff: Yup.number().nullable().positive(positiveError), //variable
      annualWageBill: Yup.number().nullable().positive(positiveError), //variable
      avgWorkingDays: Yup.number().nullable().positive(positiveError), //variable
      annualCyberSpend: Yup.number().nullable().positive(positiveError), //variable
      avgStaffHardwareCost: Yup.number().nullable().positive(positiveError), //variable
      usesManagedServiceProvider: Yup.boolean(),
    }),
    customers: Yup.object().shape({
      numberOfCustomers: Yup.number().nullable().positive(positiveError), //variable
      avgContractValue: Yup.number().nullable().positive(positiveError), //variable
      numberOfDataRecords: Yup.number().nullable().positive(positiveError), //variable
    }),
    softwareDev: Yup.object().shape({
      developsSoftware: Yup.boolean(),
      releaseCycleCost: Yup.number().nullable().positive(positiveError), //variable
      releaseCycleLength: Yup.number().nullable().positive(positiveError), //variable
      researchAndDevelopmentBudget: Yup.number().nullable().positive(positiveError), //variable
    }),
    supplyChain: Yup.object().shape({
      avgCostToProcureSupplier: Yup.number().nullable().positive(positiveError), //variable
      avgInvoiceValue: Yup.number().nullable().positive(positiveError), //variable
      largestRegularInvoiceValue: Yup.number().nullable().positive(positiveError), //variable
    }),
    cyberInsurance: Yup.object().shape({
      cyberInsurance: Yup.boolean(),
      cyberInsuranceLimit: Yup.number().nullable().positive(positiveError), //variable
      cyberInsuranceExcess: Yup.number().nullable().positive(positiveError), //variable
      cyberInsurancePremium: Yup.number().nullable().positive(positiveError), //variable
    }),
    regulations: Yup.object().shape({
      legislation: Yup.array().of(Yup.string()).nullable(),
      controlFrameworks: Yup.array().of(Yup.string()).nullable(),
      controlFrameworksOther: Yup.array().of(Yup.string()).nullable(),
      primaryControlFramework: Yup.string().nullable(),
      primaryFrameworkControls: Yup.array().of(Yup.string()).nullable(),
    }),
    assessment: requireAssessment
      ? Yup.object().shape({
          name: Yup.string().required('Required'),
          review_date: Yup.string()
            .nullable()
            .test('review_date', 'Invalid Date', (date) => {
              if (!date) return true;
              if (typeof date === 'string') return isValid(new Date(date));
              return false;
            })
            .nullable(),
          description: Yup.string().nullable(),
        })
      : Yup.object(),
  });
};

type IsButtonDisabled = (params: {
  wizardWorkspace: WizzardWorkspaceType | null;
  isLastSlide: boolean;
  errors: FormikErrors<WizardFormValues>;
  isValid: boolean;
}) => boolean;

export const isButtonDisabled: IsButtonDisabled = ({ wizardWorkspace, isLastSlide, errors, isValid }) => {
  if (!isLastSlide) return false;
  if (wizardWorkspace?.intent.type === WizardIntent.workspace && !wizardWorkspace.intent.params.createAssessment) {
    return !_.isEmpty(_.omit(errors, 'assessment'));
  }
  return !isValid;
};

type NextButtonLabel = (params: {
  isLastSlide: boolean;
  wizardWorkspace: WizzardWorkspaceType | null;
  isStdVarComplete: boolean;
}) => string;

export const nextButtonLabel: NextButtonLabel = ({ isLastSlide, wizardWorkspace, isStdVarComplete }) => {
  if (!isLastSlide) return 'Save & Next';

  if (wizardWorkspace?.intent.type === WizardIntent.scenario) return '+ ADD';

  if (
    (wizardWorkspace?.intent.type === WizardIntent.workspace &&
      wizardWorkspace.intent.params.createAssessment &&
      !isStdVarComplete) ||
    wizardWorkspace?.intent.type === WizardIntent.assessment
  )
    return 'CREATE ASSESSMENT';

  return 'CONFIRM';
};

export const updateVariablesWithFormData = async (
  formData: WizardFormValues,
  variables: Variable[] | null,
): Promise<void> => {
  if (!variables) return;
  const variableIDToFormDataPath: { [key: number]: string } = {
    500001: 'organisation.annualRevenue',
    500002: 'organisation.numberOfStaff',
    500003: 'organisation.annualWageBill',
    500004: 'organisation.avgWorkingDays',
    500005: 'organisation.annualCyberSpend',
    500006: 'organisation.avgStaffHardwareCost',
    500007: 'customers.numberOfCustomers',
    500008: 'customers.avgContractValue',
    500009: 'customers.numberOfDataRecords',
    500010: 'softwareDev.releaseCycleCost',
    500011: 'softwareDev.releaseCycleLength',
    500012: 'softwareDev.researchAndDevelopmentBudget',
    500013: 'supplyChain.avgCostToProcureSupplier',
    500014: 'supplyChain.avgInvoiceValue',
    500015: 'supplyChain.largestRegularInvoiceValue',
    500016: 'cyberInsurance.cyberInsuranceLimit',
    500017: 'cyberInsurance.cyberInsuranceExcess',
    500018: 'cyberInsurance.cyberInsurancePremium',
  };

  const developsSoftware = _.get(formData, 'softwareDev.developsSoftware');
  const hasCyberInsurance = _.get(formData, 'cyberInsurance.cyberInsurance');

  const softwareDevVariableIDs = [500010, 500011, 500012];
  const cyberInsuranceVariableIDs = [500016, 500017, 500018];

  const updatedVariables = variables.map((variable) => {
    const formDataPath = variableIDToFormDataPath[variable.id];
    if (formDataPath) {
      if (
        (!developsSoftware && softwareDevVariableIDs.includes(variable.id)) ||
        (!hasCyberInsurance && cyberInsuranceVariableIDs.includes(variable.id))
      ) {
        return { ...variable, value: null };
      } else {
        const newValue = _.get(formData, formDataPath);
        if (true) {
          return { ...variable, value: newValue || null };
        }
      }
    }
    return variable;
  });

  const changedVariables = updatedVariables.filter((el, idx) => el.value !== variables[idx].value);

  if (changedVariables.length) {
    await updateWorkspaceVariables(changedVariables);
    await queryCache.invalidateQueries([QKeys.WorkspaceVariables]);
  }
};
