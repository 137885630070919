import React from 'react';
import { ProjectionVariant, riskConsequencesNames, riskEventsNames, riskSourcesNames, Scenario } from 'src/api/types';
import { DataTitle, DataValue, NoData, Spacer, VariantValues } from 'src/common';
import ExpandCollapse from 'src/common/ExpandCollapse';
import ExpandSection from 'src/components/ExpandSection';
import { TLLink } from 'src/pages/DashboardPage/comps';
import { formatDate, numeralFormat } from 'src/utils/misc';
import styled from 'styled-components';
import { genFrequencyLabel } from '../util';

const Div = styled.div`
  .note-text {
    word-break: break-word;
  }

  .content {
    display: flex;
    justify-content: space-between;
  }

  .notes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    &__values {
      display: flex;
      flex-direction: column;
      grid-gap: 12px;
      align-items: flex-start;
    }
  }
  .consequences {
    display: flex;
    grid-gap: 30px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .risk-statement {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 10px;
    }
    .notes {
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
    .consequences {
      flex-direction: column;
      grid-gap: 15px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .risk-statement {
      grid-template-columns: 1fr 1fr;
    }
  }
`;

interface RiskStatementSectionProps {
  effectiveScenario: Scenario;
  scenarioBase: Scenario;
  scenarioComplete?: Scenario;
  scenario?: Scenario;
  lowerThanMinimum: any;
  showAllProjected: boolean;
}

export const RiskStatementSection: React.FC<RiskStatementSectionProps> = ({
  effectiveScenario,
  scenarioBase,
  scenario,
  lowerThanMinimum,
  showAllProjected,
  scenarioComplete,
}) => {
  return (
    <ExpandSection title="RISK STATEMENT" sectionId="scenario_rs">
      <Div>
        <div className="content risk-statement">
          <div>
            <DataTitle>SOURCES</DataTitle>
            {riskSourcesNames[effectiveScenario.source] ? (
              <DataValue data-cy="scenario-source">{riskSourcesNames[effectiveScenario.source]}</DataValue>
            ) : (
              <NoData />
            )}
          </div>
          <div>
            <DataTitle>EVENTS</DataTitle>
            {riskEventsNames[effectiveScenario.event] ? (
              <DataValue data-cy="scenario-event">{riskEventsNames[effectiveScenario.event]}</DataValue>
            ) : (
              <NoData />
            )}
          </div>
          <div>
            <DataTitle>CONSEQUENCES</DataTitle>
            {riskConsequencesNames[effectiveScenario.consequence] ? (
              <DataValue data-cy="scenario-consequence">
                {riskConsequencesNames[effectiveScenario.consequence]}
              </DataValue>
            ) : (
              <NoData />
            )}
          </div>
          <div>
            <DataTitle>IDENTIFIED DATE</DataTitle>
            {effectiveScenario.risk_identified_date ? (
              <DataValue data-cy="scenario-risk_identified_date">
                <TLLink date={effectiveScenario.risk_identified_date}>
                  {formatDate(effectiveScenario.risk_identified_date)}
                </TLLink>
              </DataValue>
            ) : (
              <NoData />
            )}
          </div>
          <div>
            <DataTitle>REVIEW DATE</DataTitle>
            {effectiveScenario.review_date ? (
              <DataValue data-cy="scenario-review_date">
                <TLLink date={effectiveScenario.review_date}>{formatDate(effectiveScenario.review_date)}</TLLink>
              </DataValue>
            ) : (
              <NoData />
            )}
          </div>
        </div>
        <Spacer $px={30} />
        <DataTitle>RISK NARRATIVE</DataTitle>
        <div />
        {effectiveScenario.risk_narrative ? <ExpandCollapse text={effectiveScenario.risk_narrative} /> : <NoData />}
        <Spacer $px={30} />
        <div className="notes">
          <div>
            <DataTitle>FREQUENCY</DataTitle>

            {lowerThanMinimum.includes('frequency') ? (
              <DataValue data-cy="scenario-frequency">Frequency lower than 1 time every 100 years</DataValue>
            ) : (
              <>
                <Spacer $px={4} />
                {showAllProjected ? (
                  <div className="notes__values">
                    {genFrequencyLabel({
                      scenario: scenarioBase,
                      tooltipText: 'Frequency without control modifiers applied',
                      variant: ProjectionVariant.base,
                    })}
                    {genFrequencyLabel({
                      scenario,
                      tooltipText: 'Frequency with control modifiers from live controls applied',
                      variant: ProjectionVariant.live,
                    })}
                    {genFrequencyLabel({
                      scenario: scenarioComplete,
                      tooltipText: 'Frequency with control modifiers from all controls applied',
                      variant: ProjectionVariant.complete,
                    })}
                  </div>
                ) : (
                  genFrequencyLabel({ scenario: effectiveScenario })
                )}
              </>
            )}
            <Spacer $px={30} />
            <DataTitle>NOTE</DataTitle>
            {effectiveScenario.frequency_note ? (
              <DataValue className="note-text" data-cy="scenario-frequency-note">
                {effectiveScenario.frequency_note}
              </DataValue>
            ) : (
              <NoData />
            )}
          </div>
          <div>
            <div className="consequences">
              <div>
                <DataTitle>CONSEQUENCE LOWER</DataTitle>
                {lowerThanMinimum.includes('lower') ? (
                  <DataValue data-cy="scenario-lower">{'<1'}</DataValue>
                ) : (
                  <>
                    {showAllProjected ? (
                      <>
                        <Spacer $px={4} />
                        <VariantValues
                          value={{
                            base: scenarioBase.lower,
                            live: scenario?.lower,
                            cpmplete: scenarioComplete?.lower,
                          }}
                          tooltipText={{
                            base: 'Consequence without control modifiers applied',
                            live: 'Consequence with control modifiers from live controls applied',
                            cpmplete: 'Consequence with control modifiers from all controls applied',
                          }}
                        />
                      </>
                    ) : (
                      <>
                        {effectiveScenario.lower === null ? (
                          <div style={{ marginLeft: '5px' }}>
                            <NoData techIncomplete />
                          </div>
                        ) : (
                          <DataValue data-cy="scenario-lower">{numeralFormat(effectiveScenario.lower)}</DataValue>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
              <div>
                <DataTitle>CONSEQUENCE UPPER</DataTitle>
                {lowerThanMinimum.includes('upper') ? (
                  <DataValue data-cy="scenario-upper">{'<1'}</DataValue>
                ) : (
                  <>
                    {effectiveScenario.upper === null ? (
                      <div style={{ marginLeft: '5px' }}>
                        <NoData techIncomplete />
                      </div>
                    ) : (
                      <>
                        {showAllProjected ? (
                          <>
                            <Spacer $px={4} />
                            <VariantValues
                              value={{
                                base: scenarioBase.upper,
                                live: scenario?.upper,
                                cpmplete: scenarioComplete?.upper,
                              }}
                              tooltipText={{
                                base: 'Consequence without control modifiers applied',
                                live: 'Consequence with control modifiers from live controls applied',
                                cpmplete: 'Consequence with control modifiers from all controls applied',
                              }}
                            />
                          </>
                        ) : (
                          <>
                            {effectiveScenario.upper === null ? (
                              <div style={{ marginLeft: '5px' }}>
                                <NoData techIncomplete />
                              </div>
                            ) : (
                              <DataValue data-cy="scenario-upper">{numeralFormat(effectiveScenario.upper)}</DataValue>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            <Spacer $px={30} />
            <div>
              <DataTitle>NOTE</DataTitle>
              {effectiveScenario.note ? (
                <DataValue className="note-text" data-cy="scenario-sonsequence-note">
                  {effectiveScenario.note}
                </DataValue>
              ) : (
                <NoData />
              )}
            </div>
          </div>
        </div>
      </Div>
    </ExpandSection>
  );
};
