import { AxiosArgs, ThenArgs, Assessment } from 'src/api/types';
import axios, { AxiosResponse } from 'axios';
import { encodeBody } from 'src/api/client';
import { WizardFormValues } from './types/wizard';
import { progressLoad, progressSave } from 'src/components/Wizzard/util';

type GetWizard = () => Promise<AxiosArgs<ThenArgs<AxiosResponse<WizardFormValues | null>>>>;

export const getWizard: GetWizard = () => {
  const path = `/api/public/api/workspaces/questions`;
  return axios({
    url: path,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type UpdateWizard = (params: {
  data: WizardFormValues;
  id: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const updateWizard: UpdateWizard = ({ data, id }) => {
  const path = `/api/public/api/workspaces/questions`;
  return axios({
    url: path,
    method: 'POST',
    data: encodeBody(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type WizCreateAssessment = (data: WizardFormValues) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Assessment>>>>;

export const wizCreateAssessment: WizCreateAssessment = (data) => {
  const path = `/api/public/api/wizard/assessment`;
  return axios({
    url: path,
    method: 'POST',
    data: encodeBody(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type WizCreateScenarios = (params: {
  data: WizardFormValues;
  id: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Assessment>>>>;

export const wizCreateScenarios: WizCreateScenarios = ({ data, id }) => {
  const path = `/api/public/api/wizard/assessment/scenarios`;
  return axios({
    url: path,
    method: 'POST',
    data: encodeBody({ ...data, assessment: { ...data.assessment, id } }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------
