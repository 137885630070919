import React, { useEffect, useState } from 'react';
import { GradientText, Spacer } from 'src/common';
import { useStateSelector } from 'src/redux';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { MOTION_TRANSITION } from 'src/config';
import { loadSection, saveSection } from './util';

const Div = styled.div`
  .head {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &__handle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      display: inline-flex;
      grid-gap: 12px;
      user-select: none;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: -10px;
        left: -10px;
        right: -20px;
        bottom: -10px;
      }
    }

    &__extra {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1px;
      cursor: default;
    }
  }
  .expand {
    overflow: hidden;
  }
`;

interface ExpandSectionProps {
  title: string;
  children: React.ReactNode;
  onChange?: (isOpen: boolean) => void;
  endComponent?: React.ReactNode;
  sectionId: string;
  defaultOpen?: boolean;
}

const ExpandSection: React.FC<ExpandSectionProps> = ({
  title,
  children,
  onChange,
  endComponent,
  sectionId = 'default',
  defaultOpen = true,
}) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  const init = !!(loadSection(sectionId) === null ? defaultOpen : loadSection(sectionId));
  const [isOpen, setIsOpen] = useState(init);

  useEffect(() => {
    onChange?.(isOpen);
  }, [isOpen]);

  return (
    <Div data-cy={`es:${sectionId}`}>
      <div
        className="head"
        onClick={() => {
          const newValue = !isOpen;
          setIsOpen(newValue);
          saveSection({ sectionId, isOpen: newValue });
        }}
        data-cy={`es:${sectionId}:handle`}
      >
        <div className="head__handle">
          {isOpen ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="2" viewBox="0 0 14 2" fill="none">
              <path
                d="M13.4298 1.83982L13.4162 0.416223L7.77232 0.362483L6.34872 0.348928L0.704811 0.295189L0.718366 1.71879L6.36227 1.77253L7.78587 1.78608L13.4298 1.83982Z"
                fill={colors.cflowerBlue}
              />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
              <path
                d="M13.4298 7.59275L13.4162 6.16915L7.77232 6.11541L7.71858 0.471506L6.29498 0.457951L6.34872 6.10186L0.704811 6.04812L0.718366 7.47172L6.36227 7.52546L6.41601 13.1694L7.83961 13.1829L7.78587 7.53901L13.4298 7.59275Z"
                fill={colors.cflowerBlue}
              />
            </svg>
          )}

          <GradientText>{title}</GradientText>
        </div>
        <div className="head__extra" onClick={(e) => e.stopPropagation()}>
          <AnimatePresence>
            {endComponent && (
              <motion.div
                initial={{ opacity: 0, y: -5 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.0 }}
                exit={{ opacity: 0, y: -5 }}
              >
                {endComponent}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <motion.div
        className="expand"
        initial={false}
        animate={{ height: isOpen ? 'auto' : 0, y: isOpen ? 0 : -10 }}
        transition={{ ...MOTION_TRANSITION, delay: 0.1 }}
      >
        <Spacer $px={2} />
        {children}
        <Spacer $px={30} />
      </motion.div>
    </Div>
  );
};

export default ExpandSection;
