import React from 'react';
import { DataTitle, Poppins } from 'src/common';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { ModifiersTreat, TreatReduceFrequencyType } from 'src/api/types';
import { SCurrencyInput, Symbol, SymbolSwitch } from '../../../../comps';

const Div = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 100px 1.6fr;

  .rc--by {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
  .rc--close {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid ${({ theme }) => theme.colors.stroke};
    padding-right: 20px;
  }
  .rc--stats {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    span {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.cflowerBlue};
    }

    &__value {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.prussianBlue} !important;
    }
  }
`;

interface ReduceFrequencyProps {
  idx: number;
  disabled?: boolean;
}

export const ReduceFrequency: React.FC<ReduceFrequencyProps> = ({ idx, disabled }) => {
  const { values, setFieldValue, handleBlur } = useFormikContext<ModifiersTreat<TreatReduceFrequencyType>>();

  const isPercentage = values.treat[idx].data.isPercentage;
  const symbol = isPercentage ? '%' : '#';

  return (
    <Div>
      <div className="slot">
        <div className="slot__top"></div>
        <div className="slot__content rc--by">
          <Poppins color="cflowerBlue">by</Poppins>
          <SymbolSwitch
            symbolA={'%'}
            symbolB={'#'}
            selectedSymbol={symbol}
            setSymbol={(s) => {
              setFieldValue(`treat[${idx}].data.isPercentage`, s === '%');
            }}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="slot">
        <div className="slot__top">
          <DataTitle $noMargin>VALUE</DataTitle>
        </div>
        <div className="slot__content">
          <SCurrencyInput
            id={`treat[${idx}].data.value`}
            InputProps={{ startAdornment: <Symbol>{symbol}</Symbol> }}
            value={values.treat[idx].data.value}
            onValueChange={({ floatValue }) => setFieldValue(`treat[${idx}].data.value`, floatValue)}
            onBlur={handleBlur}
            disabled={disabled}
          />
        </div>
      </div>
    </Div>
  );
};
