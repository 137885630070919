import React, { memo, useEffect, useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { getControls } from 'src/api/controls';
import { getScenarioControls } from 'src/api/plan';
import { QKeys } from 'src/api/types';
import { useColumns } from './useColumns';
import GenericTable, { genEmtyRowTextComponent, GTRowEmpty, TableSheetRmp } from 'src/components/GenericTable';
import { Spacer, TableSkeleton } from 'src/common';
import { isEqual } from 'lodash';
import Button from 'src/components/form/Button';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { TableIds } from 'src/api/types/misc';
import { AddRmpModal } from '../../../AddRmpModal';
import { useAuth } from 'src/state/auth';
import { checkIsModifierStrategy } from 'src/components/Modifier/util';
import { SuggestModifiersChecker } from 'src/components/modals/comps';

interface FromExistingProps {
  scenarioId: string;
  setChecked: (a: string[]) => void;
  isLoading: boolean;
  onSubmit: () => void;
  addRecommendedModifiers: boolean;
  setAddRecommendedModifiers: (a: boolean) => void;
}

export const FromExisting: React.FC<FromExistingProps> = memo(
  ({ scenarioId, setChecked, isLoading, onSubmit, addRecommendedModifiers, setAddRecommendedModifiers }) => {
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);

    const { userFeatures } = useAuth();

    const { data: controls = [], isLoading: isControlsLoading } = useQuery(QKeys.Controls, getControls);
    const { data: scenarioControls = [], isLoading: isScenarioControlsLoading } = useQuery(
      [QKeys.ScenarioControls, scenarioId],
      () => getScenarioControls(scenarioId),
    );

    const { columns, GTColumnSelectAnchorExported, checked } = useColumns({
      data: controls,
      linkedControls: scenarioControls,
      scenarioId,
    });

    useEffect(() => {
      setChecked(checked);
    }, [checked]);

    const isDisabledModSuggest = useMemo(() => {
      if (!checked.length) return true;
      return !controls.filter((el) => checked.includes(el.id)).some((el) => checkIsModifierStrategy(el.strategy));
    }, [controls, checked]);

    useEffect(() => {
      if (isDisabledModSuggest && addRecommendedModifiers) {
        setAddRecommendedModifiers(false);
      }
    }, [isDisabledModSuggest, addRecommendedModifiers, setAddRecommendedModifiers]);

    return (
      <>
        {isAddModalOpen && <AddRmpModal onClose={() => setIsAddModalOpen(false)} />}
        {!isControlsLoading && !isScenarioControlsLoading ? (
          <>
            <GenericTable
              tableId={TableIds.scenarioLinkExistingControl}
              data={controls}
              columns={columns}
              GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
              searchable={['strategy', 'name', 'owner', 'frameworkLibrary_shortname', 'controlId', 'status']}
              expandContent={(data) => <TableSheetRmp data={data} />}
              itemHeight={50}
              rowDisabled={(row) => scenarioControls.some((el) => el.control_data.id === row.id)}
              emptyRow={
                <GTRowEmpty
                  itemHeight={50}
                  text={genEmtyRowTextComponent({
                    onClick: () => setIsAddModalOpen(true),
                  })}
                />
              }
            />
            {userFeatures.app.ai.suggestModifiers && (
              <SuggestModifiersChecker
                isDisabledModSuggest={isDisabledModSuggest}
                addRecommendedModifiers={addRecommendedModifiers}
                setAddRecommendedModifiers={setAddRecommendedModifiers}
              />
            )}
            <Spacer $px={30} />
            <Button
              disabled={isLoading || !checked.length}
              onClick={() => {
                onSubmit();
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Add',
                  modal: 'Link controls to scenario modal',
                  tags: ['SCENARIO', 'RMP'],
                });
              }}
              primary
              css="width: 100%;"
              data-cy="submit-btn"
            >
              + ADD
            </Button>
          </>
        ) : (
          <TableSkeleton />
        )}
      </>
    );
  },
  isEqual,
);
