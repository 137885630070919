import { useFormikContext } from 'formik';
import React from 'react';
import { InputLabel, Spacer } from 'src/common';
import styled from 'styled-components';
import MaterialInput from '../../form/MaterialInput';
import MaterialDatePicker from '../../form/MaterialDatePicker';
import { ControllStatus, Strategy, controllStatusOptions, strategyOptions } from 'src/api/types';
import Button from '../../form/Button';
import CurrencyInput, { Symbol } from 'src/components/form/CurrencyInput';
import CSelect from 'src/components/form/Select';
import UserInput from 'src/components/UserInput';
import { RmpFormValues } from './util';
import { getCurrencySymbol } from 'src/utils/misc';

const Div = styled.div`
  .grid {
    display: grid;
    grid-gap: 21px;

    &--2 {
      grid-template-columns: 1fr 1fr;
    }
    &--3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
  margin-top: 20px;
`;

interface RmpFormProps {
  buttonTitle: string;
  onSubmitCb?: () => void;
}

const RmpForm: React.FC<RmpFormProps> = ({ buttonTitle, onSubmitCb }) => {
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    touched,
    setFieldTouched,
    handleSubmit,
    isValid,
    isSubmitting,
    dirty,
  } = useFormikContext<RmpFormValues>();

  return (
    <Div>
      <div className="h-padding">
        <div className="grid grid--3">
          <div>
            <InputLabel>STRATEGY</InputLabel>
            <CSelect
              name="strategy"
              onChange={(o: { value: Strategy }) => {
                setFieldValue('strategy', o.value);
              }}
              onBlur={() => setFieldTouched('strategy', true)}
              value={strategyOptions.find((o) => o.value === values.strategy)}
              placeholder="Select strategy"
              css="width: 100%;"
              options={strategyOptions}
              error={touched.strategy && Boolean(errors.strategy)}
              inputId="strategy-select"
            />
            {touched.strategy && errors.strategy && <span className="helper-text-err">{errors.strategy}</span>}
          </div>
          <div>
            <InputLabel>NAME</InputLabel>
            <MaterialInput
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter a name"
              css="width: 100%;"
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              data-cy="name-input"
            />
          </div>
          <div>
            <InputLabel>STATUS</InputLabel>
            <CSelect
              name="status"
              onChange={(o: { value: ControllStatus }) => {
                setFieldValue('status', o.value);
              }}
              onBlur={() => setFieldTouched('status', true)}
              value={controllStatusOptions.find((o) => o.value === values.status)}
              placeholder="Select status"
              css="width: 100%;"
              options={controllStatusOptions}
              error={touched.status && Boolean(errors.status)}
              inputId="status-select"
            />
            {touched.status && errors.status && <span className="helper-text-err">{errors.status}</span>}
          </div>
        </div>
      </div>
      <Spacer $px={5} />
      <Divider />
      <Spacer $px={20} />
      <div className="h-padding">
        <div className="grid grid--3">
          <div>
            <InputLabel>OWNER</InputLabel>
            <UserInput
              value={values.owner || { text: '' }}
              onChange={(usr) => {
                setFieldValue('owner', usr);
              }}
              onSelectUser={(usr) => setFieldValue('owner', usr)}
            />
          </div>
          <div>
            <InputLabel>ANNUAL COST</InputLabel>
            <CurrencyInput
              id="annual_cost"
              InputProps={{ startAdornment: <Symbol>{getCurrencySymbol()}</Symbol> }}
              value={values.annual_cost}
              onValueChange={({ floatValue }) => setFieldValue(`annual_cost`, floatValue || '')}
              onBlur={handleBlur}
              error={touched.annual_cost && Boolean(errors.annual_cost)}
              helperText={touched.annual_cost && errors.annual_cost}
              placeholder="Annual cost"
              data-cy="annual_cost-input"
            />
          </div>
          <div>
            <InputLabel>VENDOR</InputLabel>
            <MaterialInput
              name="vendor"
              value={values.vendor}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Vendor"
              css="width: 100%;"
              error={touched.vendor && Boolean(errors.vendor)}
              helperText={touched.vendor && errors.vendor}
              data-cy="vendor-input"
            />
          </div>
        </div>
      </div>
      <Spacer $px={5} />
      <Divider />
      <Spacer $px={20} />
      <div className="h-padding">
        <div className="grid grid--2">
          <div>
            <InputLabel>LINK URL</InputLabel>
            <MaterialInput
              name="source"
              value={values.source || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter URL"
              css="width: 100%;"
              error={touched.source && Boolean(errors.source)}
              helperText={touched.source && errors.source}
              data-cy="url-input"
            />
          </div>
          <div>
            <InputLabel>LINK TEXT</InputLabel>
            <MaterialInput
              name="url_name"
              value={values.url_name || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter a name for the URL"
              css="width: 100%;"
              error={touched.url_name && Boolean(errors.url_name)}
              helperText={touched.url_name && errors.url_name}
              data-cy="url_name-input"
            />
          </div>
        </div>
      </div>
      <Spacer $px={5} />
      <Divider />
      <Spacer $px={20} />
      <div className="h-padding">
        <div className="grid grid--3">
          <div>
            <InputLabel>EXPECTED LIVE DATE</InputLabel>
            <MaterialDatePicker
              value={values.expected_active_date}
              onChange={(date) => setFieldValue('expected_active_date', date)}
              inputProps={{
                placeholder: 'Expected live date',
                onBlur: handleBlur('expected_active_date'),
                fullWidth: true,
                error: touched.expected_active_date && Boolean(errors.expected_active_date),
                helperText: touched.expected_active_date && errors.expected_active_date,
                ['data-cy']: 'expected_active_date-input',
              }}
            />
          </div>
          <div>
            <InputLabel>ACTUAL LIVE DATE</InputLabel>
            <MaterialDatePicker
              value={values.actual_active_date}
              onChange={(date) => setFieldValue('actual_active_date', date)}
              inputProps={{
                placeholder: 'Actual live date',
                onBlur: handleBlur('actual_active_date'),
                error: touched.actual_active_date && Boolean(errors.actual_active_date),
                helperText: touched.actual_active_date && errors.actual_active_date,
                fullWidth: true,
                ['data-cy']: 'actual_active_date-input',
              }}
            />
          </div>
          <div>
            <InputLabel>REVIEW DATE</InputLabel>
            <MaterialDatePicker
              value={values.review_date}
              onChange={(date) => setFieldValue('review_date', date)}
              inputProps={{
                placeholder: 'Review date',
                onBlur: handleBlur('review_date'),
                error: touched.review_date && Boolean(errors.review_date),
                helperText: touched.review_date && errors.review_date,
                fullWidth: true,
                ['data-cy']: 'review_date-input',
              }}
            />
          </div>
        </div>
      </div>
      <Spacer $px={5} />
      <Divider />
      <Spacer $px={20} />
      <div className="h-padding">
        <InputLabel>DESCRIPTION</InputLabel>
        <MaterialInput
          name="description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter your text"
          css="width: 100%;"
          error={touched.description && Boolean(errors.description)}
          helperText={touched.description && errors.description}
          multiline
          maxRows={6}
          minRows={4}
          data-cy="description-input"
        />
      </div>
      <Spacer $px={30} />
      <div className="h-padding">
        <Button
          disabled={!isValid || isSubmitting || !dirty}
          onClick={() => {
            handleSubmit();
            onSubmitCb && onSubmitCb();
          }}
          primary
          css="width: 100%;"
          data-cy="submit"
        >
          {buttonTitle}
        </Button>
      </div>
    </Div>
  );
};

export default RmpForm;
