import React from 'react';
import Suggester from 'src/components/Suggester';
import styled from 'styled-components';
import { useSuggestions } from 'src/hooks/useSuggestions';
import { Scenario } from 'src/api/types';
import { MPEvents } from 'src/utils/mixpanel/types';
import { mpEvent } from 'src/utils/mixpanel';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';

const Div = styled.div``;

interface AiSuggestProps {
  wrapperStyle?: React.CSSProperties;
  scenario: Scenario;
}

export const AiSuggest: React.FC<AiSuggestProps> = ({ wrapperStyle, scenario }) => {
  const { saveKeys, hiddenSuggestionKeys } = useSuggestions();
  const { setLinkControlsToScenarioModal } = useActionsApp();
  const scenarioId = scenario.id;

  const isHidden = !!scenarioId && hiddenSuggestionKeys.includes(scenarioId);

  if (isHidden) return null;

  return (
    <Div style={wrapperStyle}>
      <Suggester
        key={scenarioId}
        onClose={() => {
          const newKeys = [...hiddenSuggestionKeys, scenarioId!];
          saveKeys(newKeys);
        }}
        title={
          <span>
            See{' '}
            <span
              className="sg-action"
              onClick={() => {
                setLinkControlsToScenarioModal({ activeTab: 'from_suggested' });
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Suggested controls',
                  tags: ['SCENARIO', 'RMP', 'AI'],
                });
              }}
            >
              suggested controls
            </span>{' '}
            for this scenario.
          </span>
        }
      />
    </Div>
  );
};
