import React from 'react';
import { useStateSelector } from 'src/redux';

interface IconPasteProps {
  disabled?: boolean;
}

export const IconPaste: React.FC<IconPasteProps> = ({ disabled }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);

  const disabledColor = colors.cflowerBlue;

  const id1 = disabled ? 'paint0_linear_9199_31364_disabled' : 'paint0_linear_9199_31364';
  const id2 = disabled ? 'paint1_linear_9199_31364_disabled' : 'paint1_linear_9199_31364';
  const id3 = disabled ? 'paint2_linear_9199_31364_disabled' : 'paint2_linear_9199_31364';
  const id4 = disabled ? 'paint3_linear_9199_31364_disabled' : 'paint3_linear_9199_31364';
  const id5 = disabled ? 'paint4_linear_9199_31364_disabled' : 'paint4_linear_9199_31364';

  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6915 8.47931H6.41508C6.34313 8.47186 6.27042 8.4796 6.20165 8.50203C6.13288 8.52447 6.06958 8.56109 6.01586 8.60953C5.96215 8.65798 5.9192 8.71716 5.8898 8.78325C5.8604 8.84935 5.84521 8.92088 5.84521 8.99321C5.84521 9.06555 5.8604 9.13708 5.8898 9.20317C5.9192 9.26927 5.96215 9.32845 6.01586 9.37689C6.06958 9.42534 6.13288 9.46196 6.20165 9.48439C6.27042 9.50682 6.34313 9.51457 6.41508 9.50711H11.6915C11.7635 9.51457 11.8362 9.50682 11.9049 9.48439C11.9737 9.46196 12.037 9.42534 12.0907 9.37689C12.1444 9.32845 12.1874 9.26927 12.2168 9.20317C12.2462 9.13708 12.2614 9.06555 12.2614 8.99321C12.2614 8.92088 12.2462 8.84935 12.2168 8.78325C12.1874 8.71716 12.1444 8.65798 12.0907 8.60953C12.037 8.56109 11.9737 8.52447 11.9049 8.50203C11.8362 8.4796 11.7635 8.47186 11.6915 8.47931Z"
        fill={disabled ? disabledColor : `url(#${id1})`}
      />
      <path
        d="M11.6915 10.5926H6.41508C6.34313 10.5851 6.27042 10.5929 6.20165 10.6153C6.13288 10.6377 6.06958 10.6744 6.01586 10.7228C5.96215 10.7713 5.9192 10.8304 5.8898 10.8965C5.8604 10.9626 5.84521 11.0342 5.84521 11.1065C5.84521 11.1788 5.8604 11.2504 5.8898 11.3165C5.9192 11.3825 5.96215 11.4417 6.01586 11.4902C6.06958 11.5386 6.13288 11.5752 6.20165 11.5977C6.27042 11.6201 6.34313 11.6278 6.41508 11.6204H11.6915C11.7635 11.6278 11.8362 11.6201 11.9049 11.5977C11.9737 11.5752 12.037 11.5386 12.0907 11.4902C12.1444 11.4417 12.1874 11.3825 12.2168 11.3165C12.2462 11.2504 12.2614 11.1788 12.2614 11.1065C12.2614 11.0342 12.2462 10.9626 12.2168 10.8965C12.1874 10.8304 12.1444 10.7713 12.0907 10.7228C12.037 10.6744 11.9737 10.6377 11.9049 10.6153C11.8362 10.5929 11.7635 10.5851 11.6915 10.5926Z"
        fill={disabled ? disabledColor : `url(#${id2})`}
      />
      <path
        d="M11.6914 12.707H6.41496C6.2794 12.707 6.14938 12.7609 6.05352 12.8567C5.95766 12.9526 5.90381 13.0826 5.90381 13.2182C5.90381 13.3538 5.95766 13.4838 6.05352 13.5796C6.14938 13.6755 6.2794 13.7293 6.41496 13.7293H11.6914C11.827 13.7293 11.957 13.6755 12.0528 13.5796C12.1487 13.4838 12.2025 13.3538 12.2025 13.2182C12.2025 13.0826 12.1487 12.9526 12.0528 12.8567C11.957 12.7609 11.827 12.707 11.6914 12.707Z"
        fill={disabled ? disabledColor : `url(#${id3})`}
      />
      <path
        d="M12.461 4.68048H11.0429V3.50427C11.0429 3.14422 10.8999 2.79892 10.6453 2.54432C10.3907 2.28972 10.0454 2.14669 9.68534 2.14669H1.44401C1.08395 2.14669 0.738648 2.28972 0.484052 2.54432C0.229456 2.79892 0.0864258 3.14422 0.0864258 3.50427V15.7713C0.0864258 16.1313 0.229456 16.4767 0.484052 16.7312C0.738648 16.9858 1.08395 17.1289 1.44401 17.1289H5.16981L4.10662 15.9973C4.10662 16.1994 4.14642 16.3995 4.22376 16.5862C4.3011 16.773 4.41446 16.9426 4.55737 17.0855C4.70027 17.2284 4.86993 17.3418 5.05664 17.4191C5.24336 17.4965 5.44347 17.5363 5.64557 17.5363H12.461C12.6631 17.5363 12.8632 17.4965 13.0499 17.4191C13.2366 17.3418 13.4063 17.2284 13.5492 17.0855C13.6921 16.9426 13.8054 16.773 13.8828 16.5862C13.9601 16.3995 13.9999 16.1994 13.9999 15.9973V6.21944C13.9999 5.81128 13.8378 5.41984 13.5492 5.13123C13.2606 4.84262 12.8691 4.68048 12.461 4.68048ZM1.44401 16.1011C1.35603 16.1011 1.27158 16.0665 1.20885 16.0048C1.14613 15.9431 1.11018 15.8593 1.10873 15.7713V3.50427C1.11018 3.41631 1.14613 3.33244 1.20885 3.27075C1.27157 3.20905 1.35603 3.17449 1.44401 3.1745H9.68534C9.77331 3.17449 9.85777 3.20905 9.92049 3.27075C9.98321 3.33244 10.0192 3.41631 10.0206 3.50427V4.68048H5.62359C5.21543 4.68048 4.82399 4.84262 4.53538 5.13123C4.24677 5.41984 4.08463 5.81128 4.08463 6.21944V16.1011H1.44401ZM12.9501 15.9973C12.9487 16.1334 12.8936 16.2634 12.7969 16.3591C12.7001 16.4548 12.5696 16.5085 12.4335 16.5085H5.64557C5.5095 16.5085 5.37891 16.4548 5.28218 16.3591C5.18544 16.2634 5.13037 16.1334 5.12892 15.9973V6.21944C5.13037 6.08337 5.18544 5.95336 5.28218 5.85766C5.37891 5.76195 5.5095 5.70827 5.64557 5.70828H12.461C12.597 5.70827 12.7276 5.76195 12.8244 5.85766C12.9211 5.95336 12.9762 6.08337 12.9776 6.21944L12.9501 15.9973Z"
        fill={disabled ? disabledColor : `url(#${id4})`}
      />
      <path
        d="M3.27601 1.04572C3.27601 0.742171 3.52208 0.496094 3.82563 0.496094H7.62266C7.92622 0.496094 8.17229 0.74217 8.17229 1.04572V2.14498C8.17229 2.44853 7.92622 2.6946 7.62267 2.6946H3.82563C3.52208 2.6946 3.27601 2.44853 3.27601 2.14498V1.04572Z"
        fill={disabled ? disabledColor : `url(#${id5})`}
      />
      <path
        d="M4.33936 1.50528C4.33936 1.35351 4.46239 1.23047 4.61417 1.23047H6.90428C7.05606 1.23047 7.1791 1.35351 7.1791 1.50528V2.19598H4.33936V1.50528Z"
        fill={colors.white}
      />
      <defs>
        <linearGradient id={id1} x1="9.0533" y1="8.47656" x2="9.0533" y2="9.50986" gradientUnits="userSpaceOnUse">
          <stop stopColor={disabled ? disabledColor : '#E62E61'} />
          <stop offset="1" stopColor={disabled ? disabledColor : '#FD5630'} />
        </linearGradient>
        <linearGradient id={id2} x1="9.0533" y1="10.5898" x2="9.0533" y2="11.6231" gradientUnits="userSpaceOnUse">
          <stop stopColor={disabled ? disabledColor : '#E62E61'} />
          <stop offset="1" stopColor={disabled ? disabledColor : '#FD5630'} />
        </linearGradient>
        <linearGradient id={id3} x1="9.05318" y1="12.707" x2="9.05318" y2="13.7293" gradientUnits="userSpaceOnUse">
          <stop stopColor={disabled ? disabledColor : '#E62E61'} />
          <stop offset="1" stopColor={disabled ? disabledColor : '#FD5630'} />
        </linearGradient>
        <linearGradient id={id4} x1="7.04317" y1="0.496094" x2="7.04317" y2="17.5363" gradientUnits="userSpaceOnUse">
          <stop stopColor={disabled ? disabledColor : '#E62E61'} />
          <stop offset="1" stopColor={disabled ? disabledColor : '#FD5630'} />
        </linearGradient>
        <linearGradient id={id5} x1="7.04317" y1="0.496094" x2="7.04317" y2="17.5363" gradientUnits="userSpaceOnUse">
          <stop stopColor={disabled ? disabledColor : '#E62E61'} />
          <stop offset="1" stopColor={disabled ? disabledColor : '#FD5630'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
