import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TableIds } from 'src/api/types/misc';
import Audit from 'src/components/Audit';
import ExpandSection from 'src/components/ExpandSection';

interface AuditSectionProps {
  version?: string;
}

export const AuditSection: React.FC<AuditSectionProps> = ({ version }) => {
  const { incidentId } = useParams<{ incidentId: string }>();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ExpandSection title="AUDIT" defaultOpen={false} onChange={setIsOpen} sectionId="incident_audit">
      {isOpen && (
        <div
          style={{
            maxHeight: '800px',
            position: 'relative',
          }}
        >
          <Audit
            rootPath="incidents"
            initQuery={{
              incident_id: incidentId,
              version,
            }}
            tableId={TableIds.auditIncident}
            noHide
            topSpacer={0}
            maxHeight={800}
          />
        </div>
      )}
    </ExpandSection>
  );
};
