import React, { useEffect, useState } from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import { Divider, PageContainer, PageControlSkeleton, Spacer } from 'src/common';
import { queryCache, useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { ConfirmDeleteModal, RiskManagementModal } from 'src/components/modals';
import { deleteControls, getControl } from 'src/api/controls';
import { ControllStatus, QKeys } from 'src/api/types';
import { getControlScenarios } from 'src/api/plan';
import { MetaSheet } from './comps';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import { useAuth } from 'src/state/auth';
import { Alert } from '@mui/material';
import useVersion from 'src/hooks/useVersion';
import { AuditSection, LinkedScenariosSection } from './sections';

const ControlPage = () => {
  const { controlId } = useParams<{ controlId: string }>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { userFeatures } = useAuth();
  const { setLocationPaths } = useActionsApp();
  const { version } = useVersion();
  useGaEventPageView('Cydea | Risk Management Plan | Control');

  const navigate = useNavigate();

  const { data: control, isLoading: isControlLoading } = useQuery([QKeys.Control, controlId, { version }], () =>
    getControl(controlId as string, { version }),
  );

  const {
    data: controlScenarios = [],
    isLoading,
    error,
  } = useQuery([QKeys.ControlScenarios, controlId, { version }], () =>
    getControlScenarios(controlId as string, { version }),
  );

  useEffect(() => {
    if (control) {
      setLocationPaths({ rmpControl: { id: control.id, name: control.name } });
    }
    return () => {
      setLocationPaths({ rmpControl: undefined });
    };
  }, [control]);

  const [remove] = useMutation(deleteControls, {
    onSuccess: () => {
      queryCache.invalidateQueries();
      navigate('/risk-management');
    },
  });

  const isPageLoading = isControlLoading || isLoading;

  return (
    <>
      <Meta title={`Cydea | Risk Management Plan | Control`} feedbackScript />

      {isDeleteModalOpen && (
        <ConfirmDeleteModal
          onConfirm={async () => remove([controlId as string])}
          onClose={() => setIsDeleteModalOpen(false)}
        >
          {controlScenarios.length > 0 && (
            <Alert severity="warning">
              {controlScenarios.length > 1
                ? 'This control is linked to scenarios.'
                : 'This control is linked to a scenario.'}
            </Alert>
          )}
          {control?.status === ControllStatus.Live && <Alert severity="warning">This control is already live.</Alert>}
        </ConfirmDeleteModal>
      )}
      {isEditModalOpen && <RiskManagementModal onClose={() => setIsEditModalOpen(false)} initialValues={control} />}
      {control && (
        <PageContainer data-cy="control-page">
          {!isPageLoading ? (
            <>
              <MetaSheet
                data={control}
                editable={{
                  onDeleteSelect: () => setIsDeleteModalOpen(true),
                  onEditSelect: () => setIsEditModalOpen(true),
                }}
              >
                <Spacer $px={20} />
                <Divider />
                <LinkedScenariosSection
                  version={version}
                  control={control}
                  controlScenarios={controlScenarios}
                  isLoading={isLoading}
                  error={error}
                />
                {userFeatures.app.audit.report && userFeatures.app.audit.controls.report && (
                  <>
                    <Divider />
                    <AuditSection version={version} />
                  </>
                )}
              </MetaSheet>
            </>
          ) : (
            <PageControlSkeleton />
          )}
        </PageContainer>
      )}
    </>
  );
};

export default ControlPage;
