import React from 'react';
import CSelect, { Error } from 'src/components/form/Select';
import { ActionMeta, Props } from 'react-select';
import _ from 'lodash';
import styled, { css } from 'styled-components';

const StyledSelect = styled(CSelect)<{ $white?: boolean; $width?: number }>`
  ${({ $white }) =>
    $white &&
    css`
      .cydea-select__control {
        background: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.prussianBlue};
        &--is-disabled {
          color: ${({ theme }) => theme.colors.cflowerBlue};
        }
      }
    `};

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width}px;
      min-width: ${$width}px;
    `};
`;

type Value = string | number | boolean;

export type Option = { label: string; value: Value };

interface GenericSelectProps extends Omit<Props, 'value'> {
  value?: Value | null;
  onChange: (value: Option, meta: ActionMeta<any>) => void;
  error?: boolean;
  helperText?: string | false;
  disabled?: boolean;
  placeholder?: string;
  options: Option[];
  white?: boolean;
  width?: number;
}

export const GenericSelect: React.FC<GenericSelectProps> = ({
  value,
  onChange,
  error,
  helperText,
  disabled,
  placeholder = 'Select',
  options,
  white,
  width,
}) => {
  return (
    <>
      <StyledSelect
        isDisabled={disabled}
        value={_.find(options, (o) => o.value === value)}
        placeholder={placeholder}
        options={options}
        onChange={onChange}
        $white={white}
        // @ts-ignore
        isOptionDisabled={(o) => o.disabled}
        $width={width}
      />
      {error && helperText && <Error>{helperText}</Error>}
    </>
  );
};
