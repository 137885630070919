import React, { useEffect, useState } from 'react';
import { Card } from '../../styled';
import { Poppins, Spacer, TimelineSkeleton } from 'src/common';
import { FiltersProvider, useFiltersState } from '../../Filters/state';
import { Filters } from '../../Filters';
import { FModal } from '../../Filters/comps';
import { queryCache, useQuery } from 'react-query';
import { QKeys } from 'src/api/types';
import { getTimelineActions } from 'src/api/dashboards';
import { SelectionChips } from '../SelectionChips';
import { TimelineEvent } from 'src/api/types/dashboard';
import { splitEventsByDateRange } from '../../util';
import { motion } from 'framer-motion';
import { ShowMyActivitySwitch } from '../ShowMyActivitySwitch';
import { Section } from './comps';

const RootComponent = () => {
  const [ranges, setRanges] = useState<{
    range1: TimelineEvent[];
    range2: TimelineEvent[];
    range3: TimelineEvent[];
  }>({ range1: [], range2: [], range3: [] });
  const [canRender, setCanRender] = useState(false);
  const { filters, setFilters, setIsInit, isInit } = useFiltersState();

  useEffect(() => {
    getTimelineActions().then((res) => {
      setFilters(res.filters);
      queryCache.setQueryData([QKeys.DashboardTimelineActions, { filters: res.filters }], res);
      setIsInit(true);
    });
  }, []);

  const {
    data: res,
    isLoading,
    error,
  } = useQuery([QKeys.DashboardTimelineActions, { filters }], () => getTimelineActions({ filters }), {
    enabled: isInit,
    onSuccess: () => setCanRender(true),
  });

  useEffect(() => {
    if (res) {
      setRanges(splitEventsByDateRange(res.data));
    }
  }, [res]);

  return (
    <>
      <FModal initialFilters={res?.filters} owners hideIncidents={true} />
      <Card className="styled-scroll styled-scroll--rounded">
        <div className="controls">
          <div className="controls__left">
            <Poppins weight={600} px={22}>
              Upcoming actions
            </Poppins>
          </div>
          <div className="controls__right">
            <ShowMyActivitySwitch label="Show my actions" />
            <Filters owners />
          </div>
        </div>
        <SelectionChips owners hideIncidents />
        <Spacer $px={10} />
        <div className="container">
          {(isLoading && !res?.data) || !canRender ? (
            <TimelineSkeleton />
          ) : (
            <>
              {res && (
                <motion.div
                  className="container___upcoming"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.1 }}
                >
                  <Section title="NEXT 7 DAYS" ranges={ranges.range1} id="range1" />
                  <Section title="NEXT 30 DAYS" ranges={ranges.range2} id="range2" />
                  <Section title="NEXT 90 DAYS" ranges={ranges.range3} id="range3" />
                </motion.div>
              )}
            </>
          )}

          {!!error && !isLoading && (
            <Poppins px={14} color="error">
              {/* @ts-ignore */}
              {error.message || 'Something went wrong'}
            </Poppins>
          )}
          <Spacer $px={15} />
        </div>
      </Card>
    </>
  );
};

const UpcomingActions = () => {
  return (
    <FiltersProvider type="actions">
      <RootComponent />
    </FiltersProvider>
  );
};

export default UpcomingActions;
