import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { GTCheckBox, GTColumnSelectAnchor, GTTdm } from 'src/components/GenericTable';
import { formatDate } from 'src/utils/misc';
import { filter } from 'lodash';
import { GTInternalIds } from 'src/components/GenericTable/types';
import { Link } from 'react-router-dom';
import { encodeQueryParams } from 'src/api/client';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TableIds } from 'src/api/types/misc';
import { TLLink } from 'src/pages/DashboardPage/comps';
import { TextOverflowTooltip } from 'src/common/TextOverflowTooltip';

interface AssessmentVersionOptional {
  id: string | null;
  editable?: 0 | 1;
  name: string;
  userName?: string;
  profilePhotoPath?: string | null;
  created_at?: string;
}

type UseColumns = (a: {
  data?: AssessmentVersionOptional[];
  assessmentId: string;
  onViewClick: (a: AssessmentVersionOptional) => void;
  onCopyClick: (a: AssessmentVersionOptional) => void;
  isCheckerEnabled?: boolean;
}) => {
  columns: ColumnDef<AssessmentVersionOptional>[];
  GTColumnSelectAnchorExported: JSX.Element;
  checked: (string | null)[];
  setChecked: (a: (string | null)[]) => void;
};

const defaultColumns = ['name', 'userName', 'created_at'];

export const useColumns: UseColumns = ({ data = [], assessmentId, onCopyClick, onViewClick, isCheckerEnabled }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(
    `${TableIds.assessmentVersions}-columns`,
    defaultColumns,
  );
  const [checked, setChecked] = useState<(string | null)[]>([]);

  const columns = useMemo(() => {
    const internalIds = [GTInternalIds.tdm];

    if (isCheckerEnabled) {
      internalIds.push(GTInternalIds.checker);
    } else {
      internalIds.filter((el) => el !== GTInternalIds.checker);
      if (checked.length > 0) setChecked([]);
    }

    const list: ColumnDef<AssessmentVersionOptional>[] = [
      {
        id: GTInternalIds.checker,
        header: () => null,
        cell: ({ row }) => {
          const id = row.original.id;
          const isChecked = checked.includes(id);

          return (
            <GTCheckBox
              isChecked={isChecked}
              onClick={() => {
                if (isChecked) {
                  setChecked(checked.filter((el) => el !== id));
                } else {
                  setChecked([...checked, id]);
                }
              }}
              disabled={!isChecked && checked.length >= 2}
            />
          );
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: 'name',
        header: 'Version Name',
        accessorFn: (row) => row.name,
        cell: (info) => {
          const id = info.row.original.id;
          const isChecked = checked.includes(id);

          return (
            <TextOverflowTooltip>
              <Link
                replace
                className="table-link"
                to={`/risk-assessments/${assessmentId}${encodeQueryParams({ version: id })}`}
                onClick={(e) => {
                  e.stopPropagation();
                  onViewClick(info.row.original);
                  mpEvent(MPEvents.VersionSwitch, {
                    tags: ['ASSESSMENT'],
                  });
                }}
                style={{ opacity: !isChecked && checked.length >= 2 ? 0.5 : 1 }}
              >
                {info.getValue() as string}
              </Link>
            </TextOverflowTooltip>
          );
        },
        sortUndefined: 'last',
        sortingFn: sortingFns.text,
        maxSize: 300,
      },
      {
        id: 'userName',
        header: 'Published by',
        accessorFn: (row) => row.userName || null,
        cell: ({ row }) => {
          const id = row.original.id;
          const isChecked = checked.includes(id);
          return <span style={{ opacity: !isChecked && checked.length >= 2 ? 0.5 : 1 }}>{row.original.userName}</span>;
        },
        sortingFn: sortingFns.text,
      },
      {
        id: 'created_at',
        header: 'Date',
        accessorFn: (row) => row.created_at || null,
        cell: ({ row }) => {
          const id = row.original.id;
          const isChecked = checked.includes(id);
          const date = row.original.created_at;
          if (!date || !row.original.id) return null;
          return (
            <TLLink date={date}>
              <span style={{ opacity: !isChecked && checked.length >= 2 ? 0.5 : 1 }}>
                {formatDate(date, { formatType: 'datetime' })}
              </span>
            </TLLink>
          );
        },
      },
      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: ({ row }) => {
          if (!row.original.id) return null;
          return (
            <div css="display: flex;grid-gap: 8px">
              <GTTdm onCopyClick={() => onCopyClick(row.original)} />
            </div>
          );
        },
        size: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns, checked, isCheckerEnabled]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'name', title: 'Version Name' },
        { key: 'userName', title: 'Published by' },
        { key: 'created_at', title: 'Date' },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return { columns, GTColumnSelectAnchorExported, checked, setChecked };
};
