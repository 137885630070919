import React, { useState } from 'react';
import { Assessment } from 'src/api/types';
import ExpandSection from 'src/components/ExpandSection';
import styled from 'styled-components';
import { LogSwitch } from 'src/components/charts/comps';
import { DataTitle, NoData, Spacer } from 'src/common';
import ExpandCollapse from 'src/common/ExpandCollapse';
import ChartBlank from 'src/components/charts/ChartBlank';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { LegendComposed, VersionedChart } from './comps';

const Div = styled.div`
  .exceedance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__end {
      display: flex;
      align-items: center;
      grid-gap: 30px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .exceedance {
      &__end {
        grid-gap: 10px;
      }
    }
  }
`;

interface ExceedanceSectionProps {
  data: Assessment;
  dataBase?: Assessment;
  dataComplete?: Assessment;
  showAllProjected: boolean;
  isLogarithmic: boolean;
  setIsLogarithmic: (isLogarithmic: boolean) => void;
  version?: string;
}

export const ExceedanceSection: React.FC<ExceedanceSectionProps> = ({
  data,
  showAllProjected,
  version,
  dataBase,
  dataComplete,
  isLogarithmic,
  setIsLogarithmic,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMd = useBreakpoints('md');
  const isEmptyChart = data?.chart.every((el) => el.y === 1 && el.x === 0);
  const allScenariosIncomplete = data?.scenarios.every((el) => el.is_incomplete === 1 || el.is_incomplete === 3);

  return (
    <ExpandSection
      title="LOSS EXCEEDANCE CURVE"
      sectionId="assessment_exceedance"
      onChange={setIsOpen}
      endComponent={
        <Div
          style={{
            display: !version && isOpen ? 'block' : 'none',
          }}
        >
          <div className="exceedance">
            <div className="exceedance__end">
              {!!data?.scenarios.length && !isMd && (
                <LegendComposed isLegacyVersion={data.legacy_version} showAllProjected={showAllProjected} />
              )}
              <LogSwitch skey="log:assessment" isLogarithmic={isLogarithmic} setIsLogarithmic={setIsLogarithmic} />
            </div>
          </div>
        </Div>
      }
    >
      <Div>
        {!!data?.scenarios.length && isMd && (
          <LegendComposed isLegacyVersion={data.legacy_version} showAllProjected={showAllProjected} />
        )}
        <Spacer $px={20} />
        {!isEmptyChart && !allScenariosIncomplete ? (
          <VersionedChart
            isLogarithmic={isLogarithmic}
            isLegacyVersion={data.legacy_version}
            showAllProjected={showAllProjected}
            base={dataBase}
            live={data}
            complete={dataComplete}
          />
        ) : (
          <ChartBlank techIncomplete />
        )}
        <DataTitle>SCOPE</DataTitle>
        {data.description ? <ExpandCollapse text={data.description} /> : <NoData />}
      </Div>
    </ExpandSection>
  );
};
