import React, { useEffect, useRef, useState } from 'react';
import { GradientTextAction, Poppins, Spacer, TimelineSkeleton } from 'src/common';
import moment from 'moment';
import { Timeline, FetchMore, HeadTitle } from './comps';
import _ from 'lodash';
import { queryCache, useQuery } from 'react-query';
import { getTimeline } from 'src/api/dashboards';
import { QKeys } from 'src/api/types';
import { parseEvents } from '../../Events/util';
// import { res } from './temp';
import { FiltersProvider, useFiltersState } from '../../Filters/state';
import { Card } from '../../styled';
import { Filters } from '../../Filters';
import { FModal } from '../../Filters/comps';
import { SelectionChips } from '../SelectionChips';
import { motion } from 'framer-motion';
import { NoAction } from '../../Events';
import { ShowMyActivitySwitch } from '../ShowMyActivitySwitch';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { ActivityData } from 'src/api/types/dashboard';

type FindActivity = (a: { date: string; data: ActivityData[] }) => ActivityData | null;

const findActivity: FindActivity = ({ date, data }) => {
  const targetDate = moment(date).startOf('day');

  let idx = data.findIndex((el) => {
    const elDate = moment(el.event_date).startOf('day');
    return elDate.format('YYYY-MM-DD') === targetDate.format('YYYY-MM-DD');
  });

  if (idx === -1) {
    const datedEvents = data.map((el, i) => ({
      date: moment(el.event_date).startOf('day'),
      idx: i,
    }));

    const previousDates = datedEvents.filter((el) => el.date.isBefore(targetDate));
    previousDates.sort((a, b) => b.date.diff(a.date));
    idx = previousDates.length > 0 ? previousDates[0].idx : -1;
  }

  const block = idx !== -1 ? data[idx] : null;

  return block;
};

const RootComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState({ idx: 0, focus: true });
  const todayRef = useRef<{ centerToday: () => void }>(null);
  const linkedRef = useRef<{ centerToday: () => void }>(null);
  const [canRender, setCanRender] = useState(false);
  const { search } = useLocation();
  const [linkedTarget, setLinkedTarget] = useState<{ date: string } | null>(null);
  const [linkedActivity, setLinkedActivity] = useState<ActivityData | null>(null);

  const { filters, setFilters, isInit, setIsInit } = useFiltersState();

  useEffect(() => {
    getTimeline().then((res) => {
      setFilters(res.filters);
      queryCache.setQueryData([QKeys.DashboardTimeline, { filters: res.filters }], res);
      setIsInit(true);
    });
  }, []);

  const {
    data: res,
    isLoading: isInitialLoading,
    error,
  } = useQuery([QKeys.DashboardTimeline, { filters }], () => getTimeline({ filters }), {
    enabled: isInit,
    onSuccess: () => {
      setCanRender(true);
      setTimeout(() => {
        linkedRef.current?.centerToday();
      }, 100);
    },
  });

  useEffect(() => {
    const parsedQuery = qs.parse(search, {
      ignoreQueryPrefix: true,
    }) as { date: string };

    if (parsedQuery.date) {
      setLinkedTarget(parsedQuery);
    }
  }, [search, res]);

  // const isInitialLoading = false;
  // const error = null;
  const fetchMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const today = moment().startOf('day');

  useEffect(() => {
    if (res?.data) {
      const todaysIdx =
        res?.data.findIndex((el) => moment(el.event_date).startOf('day').diff(today, 'days') === 0) || -1;
      setSelected({ idx: todaysIdx, focus: true });
    }
  }, [res]);

  useEffect(() => {
    if (linkedTarget && res?.data && res.data.length > 0) {
      const activity = findActivity({
        date: linkedTarget.date,
        data: res.data,
      });

      setLinkedActivity(activity);
    }
  }, [res, linkedTarget]);

  return (
    <>
      <FModal initialFilters={res?.filters} owners />
      <Card className="styled-scroll styled-scroll--rounded">
        <div className="controls">
          <div className="controls__left">
            <Poppins weight={600} px={22}>
              Activity
            </Poppins>
          </div>
          <div className="controls__right">
            <GradientTextAction px={14} weight={500} $underline onClick={() => todayRef.current?.centerToday()}>
              Return to today
            </GradientTextAction>
            <ShowMyActivitySwitch borderLeft label="Show my activity" />
            <Filters owners />
          </div>
        </div>
        <SelectionChips owners />
        <Spacer $px={18} />
        <div className="container">
          {(isInitialLoading && !res?.data) || !canRender ? (
            <TimelineSkeleton />
          ) : (
            <>
              {res?.data.length ? (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.1 }}>
                  {res.data[0].index !== 1 && (
                    <Timeline
                      isActive
                      completeness={100}
                      headComponent={<FetchMore onClick={() => fetchMore()} isLoading={isLoading} />}
                    />
                  )}

                  {res.data.map((el, idx) => {
                    const date = moment(el.event_date).startOf('day');
                    const isPast = date.diff(today, 'days') < 0;
                    const isToday = date.diff(today, 'days') === 0;

                    const isLinked = linkedActivity?.index === el.index;

                    const isLast = idx === res.data.length - 1 && _.last(res.data)?.index === res.total;
                    const contentMarginBottom = isLast ? 0 : idx + 1 === selected.idx ? 35 : isToday ? 30 : 20;

                    const shouldAttachTodayRef = isToday || isPast;
                    const shouldAttachLinkedRef = isLinked;

                    return (
                      <Timeline
                        dateKey={moment(el.event_date).format('DD-MMM-YYYY')}
                        key={el.index}
                        isActive={isPast || isToday}
                        completeness={isPast ? 100 : 0}
                        headComponent={
                          <HeadTitle px={14} $highlightLinked={isLinked}>
                            {isToday ? 'TODAY' : moment(el.event_date).format('DD MMM YYYY')}
                          </HeadTitle>
                        }
                        content={
                          <>
                            {parseEvents({ events: el.events, isToday }) || (
                              <Poppins px={14}>{isToday ? 'No activities or events today' : ''}</Poppins>
                            )}
                          </>
                        }
                        isLast={isLast}
                        highlight={isToday}
                        // highlight={!!linkedTarget ? isLinked : isToday}
                        contentMarginBottom={contentMarginBottom}
                        ref={shouldAttachLinkedRef ? linkedRef : shouldAttachTodayRef ? todayRef : null}
                        // onSelect={() => setSelected({ idx, focus: false })}
                      />
                    );
                  })}

                  {_.last(res.data)?.index !== res.total && (
                    <Timeline
                      isActive={false}
                      completeness={0}
                      headComponent={<FetchMore onClick={() => fetchMore()} isLoading={isLoading} isNext />}
                      isLast
                    />
                  )}
                </motion.div>
              ) : (
                <>{!error && isInit && <NoAction text="No results" />}</>
              )}
            </>
          )}

          {!!error && !isInitialLoading && (
            <Poppins px={14} color="error">
              {/* @ts-ignore */}
              {error.message || 'Something went wrong'}
            </Poppins>
          )}
          <Spacer $px={15} />
        </div>
      </Card>
    </>
  );
};

const Activity = () => {
  return (
    <FiltersProvider type="activity">
      <RootComponent />
    </FiltersProvider>
  );
};

export default Activity;
