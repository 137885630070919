import React, { useRef, useState, useEffect, CSSProperties, ReactNode } from 'react';
import { Tooltip } from './Tooltip';

interface TextOverflowTooltipProps {
  children: ReactNode;
  tooltipText?: string;
  style?: CSSProperties;
  [key: string]: any;
}

export const TextOverflowTooltip: React.FC<TextOverflowTooltipProps> = ({ children, tooltipText, style }) => {
  const textRef = useRef<HTMLDivElement | null>(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const tooltipId = useRef(`tooltip-${Math.random().toString(36).substr(2, 9)}`);

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      setIsTruncated(element.scrollWidth > element.clientWidth);
    }
  }, [children, tooltipText]);

  let contentForTooltip = '';
  if (typeof children === 'string') {
    contentForTooltip = children;
  } else if (tooltipText) {
    contentForTooltip = tooltipText;
  } else if (React.isValidElement(children)) {
    contentForTooltip = typeof children.props.children === 'string' ? children.props.children : '';
  }

  return (
    <>
      <div
        ref={textRef}
        data-tooltip-id={tooltipId.current}
        data-tooltip-content={isTruncated ? contentForTooltip : ''}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...style,
        }}
      >
        {children}
      </div>
      {isTruncated && (
        <Tooltip
          dataId={tooltipId.current}
          place="top"
          delayShow={200}
          noIcon
          dataHtml={contentForTooltip}
          positionStrategy="fixed"
        />
      )}
    </>
  );
};
