import React, { memo } from 'react';
import { PdfScenario } from 'src/api/types';
import { TocItem } from '../../types';
import { HeaderEndComponent, ScenarioPage } from '../../comps';
import _ from 'lodash';
import { ColorSet } from 'src/redux/reducers/theme';

interface ScenScenRenderProps {
  scenarios: PdfScenario[];
  onDone: (data: TocItem[]) => void;
  assessmentData: {
    reviewDate?: string;
    name: string;
    id: string;
  };
  colors: ColorSet;
}

export const ScenRender: React.FC<ScenScenRenderProps> = memo(
  ({ scenarios, onDone, assessmentData, colors }) => {
    if (!scenarios.length) {
      onDone([]);
      return null;
    }
    const list: { [key: string]: TocItem } = {};

    const onRender = (pageNumber: number, idx: number, title: string) => {
      list[idx] = { pageNumber: pageNumber, title };

      if (idx === scenarios.length - 1) {
        const outputArray = Object.keys(list).map((key) => list[key]);
        onDone(outputArray);
      }
    };

    return (
      <>
        {scenarios.map((el, idx) => {
          return (
            <>
              <ScenarioPage
                scenario={el}
                onRender={(pageNumber) => onRender(pageNumber, idx, el.name)}
                endComponent={<HeaderEndComponent idx={idx + 1} title="SCENARIO" />}
                assessmentData={assessmentData}
                footerTitle={`${assessmentData.name} / `}
                colors={colors}
              />
            </>
          );
        })}
      </>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps),
);
