import React, { useState } from 'react';
import { Spacer } from 'src/common';
import { LogSwitch } from 'src/components/charts/comps';
import ExpandSection from 'src/components/ExpandSection';
import styled from 'styled-components';
import useBreakpoints from 'src/hooks/useBreakpoints';
import ChartBlank from 'src/components/charts/ChartBlank';
import { Scenario } from 'src/api/types';
import { LegendComposed } from './comps/LegendComposed';
import { VersionedChart } from './comps/VersionedChart';

const Div = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 20px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-gap: 10px;
  }
`;

interface ExceedanceSectionProps {
  isLogarithmic: boolean;
  setIsLogarithmic: (isLogarithmic: boolean) => void;
  showAllProjected: boolean;
  lowerThanMinimum: any;
  effectiveScenario: Scenario;
}

export const ExceedanceSection: React.FC<ExceedanceSectionProps> = ({
  isLogarithmic,
  showAllProjected,
  setIsLogarithmic,
  lowerThanMinimum,
  effectiveScenario,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const isMd = useBreakpoints('md');

  const canRenderChart = !(
    lowerThanMinimum.length > 0 ||
    effectiveScenario?.expected_loss === null ||
    effectiveScenario?.is_incomplete === 3
  );

  return (
    <ExpandSection
      title="LOSS EXCEEDANCE CURVE"
      sectionId="scenario_exceedance"
      onChange={setIsOpen}
      endComponent={
        <Div
          style={{
            display: isOpen ? 'flex' : 'none',
          }}
        >
          {!effectiveScenario.legacy_version && canRenderChart && !isMd && (
            <LegendComposed showAllProjected={showAllProjected} />
          )}
          {effectiveScenario.expected_loss != null && (
            <LogSwitch skey="log:scenario" isLogarithmic={isLogarithmic} setIsLogarithmic={setIsLogarithmic} />
          )}
        </Div>
      }
    >
      {!effectiveScenario.legacy_version && canRenderChart && isMd && (
        <div style={{ display: 'flex' }}>
          <LegendComposed showAllProjected={showAllProjected} />
        </div>
      )}
      <Spacer $px={20} />
      {!canRenderChart ? (
        <ChartBlank lowerThanMinimum={lowerThanMinimum || []} techIncomplete />
      ) : (
        <VersionedChart
          isLogarithmic={isLogarithmic}
          isLegacyVersion={effectiveScenario.legacy_version}
          showAllProjected={showAllProjected}
        />
      )}
    </ExpandSection>
  );
};
