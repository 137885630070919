import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from 'src/assets/cross.svg';
import { AiSvg } from 'src/assets/dynamicSvg';

const CloseIcon = styled(CrossIcon)`
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.prussianBlue};

  &:hover {
    fill: ${({ theme }) => theme.colors.gp1};
  }
`;

const Div = styled.div`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.cultured};
  padding: 15px 26px;
  .sg-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.prussianBlue};
    }
  }
  .sg-action {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.gp1};
    text-decoration: underline;
  }
`;

interface SuggesterProps {
  title: React.ReactNode;
  onClose: () => void;
}

const Suggester: React.FC<SuggesterProps> = ({ title, onClose }) => {
  return (
    <Div>
      <div className="sg-head">
        <div className="sg-head__title">
          <AiSvg isActive /> {title}
        </div>{' '}
        <CloseIcon onClick={onClose} />
      </div>
    </Div>
  );
};

export default Suggester;
