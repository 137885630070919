import { gaEventUSER } from './gaEvent';
import { MPEvents } from './mixpanel/types';
import { mpEvent } from './mixpanel/useMixPanel';

export const LSKEY_AUTH_TOKEN = '__cydea_auth__';
export const LSKEY_INITAIL_URL = '__cydea_initial_url__';
export const auth0Domain: string = process.env.AUTH0_DOMAIN!;
export const auth0ClientID: string = process.env.AUTH0_CLIENT_ID!;
export const auth0Audience: string = process.env.AUTH0_AUDIENCE!;

export const makeLogout = () => {
  gaEventUSER('LOGOUT');
  mpEvent(MPEvents.Logout, { tags: ['USER'] });
  window.localStorage.removeItem(LSKEY_AUTH_TOKEN);
  window.location.href = '/';
};

export const getToken = () => {
  const data = window.localStorage.getItem(LSKEY_AUTH_TOKEN);
  if (!data) {
    return false;
  }
  const parsed = JSON.parse(data);
  return parsed.token;
};

export const getIsSignedIn = (): boolean => {
  const data = window.localStorage.getItem(LSKEY_AUTH_TOKEN);
  if (!data) {
    return false;
  }
  const parsed = JSON.parse(data);
  return !!parsed.token;
};

export const setToken = (token: string) => {
  window.localStorage.setItem(
    LSKEY_AUTH_TOKEN,
    JSON.stringify({
      token,
    }),
  );
};

export const getProfileCredentials = (name: string | undefined): string => {
  let credentials = '';
  if (name) {
    const cleanName = name.replace(/\s*\(You\)$/, '');
    const splits = cleanName.split(' ');
    splits.forEach((s) => {
      if (credentials.length < 2 && s.length > 0) {
        credentials += s[0];
      }
    });
  }
  return credentials;
};
