import React, { useEffect, useState } from 'react';
import ExpandSection from 'src/components/ExpandSection';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { Assessment } from 'src/api/types';
import GenericTable from 'src/components/GenericTable';
import { TableIds } from 'src/api/types/misc';
import { NoData } from 'src/common';
import { useColumns } from '../../useColumns';
import { ConfirmDeleteModal } from 'src/components/modals';
import { queryCache, useMutation } from 'react-query';
import { deleteScenario } from 'src/api/scenario';
import { mergeScenarios, VariantsScenario } from '../../util';
import { ScenarioTableExpandContent, AddRiskScenario } from './comps';

interface RiskRegisterSectionProps {
  data: Assessment;
  showAllProjected: boolean;
  dataBase?: Assessment;
  dataComplete?: Assessment;
  version?: string;
}

export const RiskRegisterSection: React.FC<RiskRegisterSectionProps> = ({
  data,
  version,
  showAllProjected,
  dataBase,
  dataComplete,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [deleteScenarioId, setDeleteScenarioId] = useState<string | undefined>(undefined);
  const [tableData, setTableData] = useState<VariantsScenario[]>([]);

  useEffect(() => {
    if (!!dataBase && !!data && !!dataComplete) {
      const merged = mergeScenarios({
        baseData: dataBase?.scenarios || [],
        liveData: data?.scenarios || [],
        completeData: dataComplete?.scenarios || [],
      });
      setTableData(merged);
    }
  }, [data, dataBase, dataComplete]);

  const [remove] = useMutation(deleteScenario, {
    onSuccess: () => {
      mpEvent(MPEvents.ScenarioDelete, { id: deleteScenarioId as string, tags: ['SCENARIO'] });
      queryCache.invalidateQueries();
      setDeleteScenarioId(undefined);
    },
  });

  const { columns, GTColumnSelectAnchorExported } = useColumns({
    data: tableData,
    version,
    setDeleteScenarioId,
    showAllProjected,
  });

  return (
    <>
      {deleteScenarioId && (
        <ConfirmDeleteModal
          onConfirm={() => remove({ id: deleteScenarioId })}
          onClose={() => setDeleteScenarioId(undefined)}
        />
      )}
      <ExpandSection
        title="RISK REGISTER"
        sectionId="assessment_risk-register"
        onChange={setIsOpen}
        endComponent={
          isOpen && !version ? <AddRiskScenario assessmentId={data.id} assessmentName={data.name} /> : undefined
        }
      >
        {data.scenarios.length ? (
          <GenericTable
            tableId={TableIds.assessmentScenarios}
            data={tableData}
            columns={columns}
            GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
            expandContent={(scen) => {
              const data =
                scen.implementationPlans
                  ?.map((el) => {
                    return {
                      control: el.control,
                      modifiers: el.modifiers,
                      starting_frequency: el.starting_frequency,
                      starting_lower: el.starting_lower,
                      starting_upper: el.starting_upper,
                    };
                  })
                  .filter((el) => el.control && el.control.name) || [];
              if (!data.length) return null;
              return <ScenarioTableExpandContent data={data} scenario={scen} />;
            }}
            searchable={['name', 'source', 'event', 'consequence', 'expected_loss', 'updated_at']}
            initialSort={[{ id: 'updated_at', desc: true }]}
          />
        ) : (
          <NoData title="No scenarios added" />
        )}
      </ExpandSection>
    </>
  );
};
